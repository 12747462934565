.no-em {
	em {
		background-color: transparent!important;
		padding: 0!important;
	}
}

.search-filter {
  background: white;
  padding: 0;
  border-bottom: 1px solid $color-blue-dark;

  &:last-child {
    border-bottom: none;
  }

  .content {
    display: none;
    width: 100%;
    margin: 0;
    padding: 0;

    @media #{$medium-up} {
      display: block;
    }

    &.active {
      display: block;
    }
  }

  &-container {
    list-style: none;
    margin: 0;
    margin-top: $spacing-large + $spacing-medium;
    margin-bottom: $spacing-large + $spacing-medium;
    border: 1px solid $color-blue-dark;
  }

  &-heading {
    position: relative;
    display: block;
    margin: 0;
    padding: 15px 25px;
    padding-right: 60px;
    background-color: $color-blue-dark;
    text-decoration: none;

    h2 {
      color: white;
      font-size: $font-size-large-mob;
      line-height: 1.56;
      @media #{$large-up} {
        font-size: $font-size-large;
        line-height: 1.58;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 25px;
      width: 26px;
      height: 26px;
      background: url("../assets/images/chevron-up-white.svg") no-repeat center
        center;
      background-size: 26px;
      transform: translateY(-50%) rotate(180deg);
      transition: trasform 0.2s;
    }

    @media #{$medium-up} {
      padding: 30px 25px;

      &:after {
        display: none;
        // transform: translateY(-50%);
      }
    }

    .search-filter.active & {
      &:after {
        transform: translateY(-50%);
      }
    }

    @media #{$medium-up} and (max-width: 1140px) {
      padding-left: 15px;
    }
  }

  &-subheading {
    @extend .h2;
    margin: 10px 30px;
    margin-left: 60px;
    font-size: $font-size-large-mob;
    line-height: 1.56;
    @media #{$large-up} {
      font-size: $font-size-large;
      line-height: 1.58;
    }

    @media #{$medium-up} and (max-width: 1140px) {
      margin-left: 35px;
    }
  }

  &-content {
    list-style: none;
    padding: 0;
    margin: 10px 20px 20px;

    @media #{$medium-up} and (max-width: 1140px) {
      margin: 10px 10px 20px;
    }

    > li {
      padding-left: 40px;

      > a {
        margin: 0;
        padding: 0;
        position: relative;
        text-decoration: none;

        &:before,
        &:after {
          position: absolute;
          content: "";
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          border-radius: 50%;
          display: block;
          margin: 0;
          overflow: hidden;
        }

        &:before {
          height: 18px;
          width: 18px;
          left: -40px;
          border: 2px solid black;
          background: white;
        }

        &:after {
          height: 8px;
          width: 8px;
          left: -35px;
          background: $color-blue-dark;
          display: none;
        }

        &.active:after {
          display: block;
        }
      }

      @media #{$medium-up} and (max-width: 1140px) {
        padding-left: 25px;

        > a {
          &:before {
            left: -25px;
          }

          &::after {
            left: -20px;
          }
        }
      }
    }

    &-all > a {
      @extend .h2;
      font-size: $font-size-large-mob;
      line-height: 1.56;

      @media #{$large-up} {
        font-size: $font-size-large;
        line-height: 1.58;
      }
    }
  }

  &-intro {
    margin: 0 0 15px;
    font-size: 16px;
  }
}

.search-filter2 {
	padding: 0 0 1px 0;
	margin: 0;
	color: #323232;
	list-style: none;

	@media #{$medium-up} {
		padding-bottom: 26px;
	}

	&:last-child {
		border-bottom: none;
	}

	.content {
		display: flex;
		width: 100%;
		margin: 0;
		padding: 0;
		padding: 0;
		overflow-y: hidden;
	}

	&-container {
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		margin-bottom: 48px;

		@media #{$small-only} {
			gap: 8px;
			margin-bottom: 32px;
		}
    
		ul {
			list-style: none;
			display: contents;
		}

		&:not(.active) {
			@media #{$medium-up} {
				li:nth-child(n + 6){
					display: none;
				}
			}

			@media #{$small-only} {
				li:nth-child(n + 4){
					display: none;
				}
			}
		}

		&.active {
			.search-filter2-more {
				display: none;
			}
		}
  	}

	&-omit {
		display: block;
		margin-top: 0;
		margin-bottom: 66px;
		font-size: 16px;
		line-height: 22px;
		color: $color-black;

		@media #{$small-only} {
			margin-bottom: 48px;
		}

		a {
			font-size: inherit;
			line-height: inherit;
			color: inherit;

		}
	}

	&-item {
		color: #323232;
		display: block;
		margin: 0;
		padding: 5px 16px 3px;
		position: relative;
		text-decoration: none;
		border: 1px solid $color-blue-dark;
		font-weight: 500;
		min-height: 42px;
		display: flex;
		align-items:  center;

		&--blue,
		&--green {
			border-color: transparent;
			font-weight: 400;
		}

		&--blue {
			background-color: $color-sea-blue-50;

			&:hover,
			&:focus-visible {
				background-color: $color-sea-blue-100!important;
				color: $color-sea-blue-900!important;
			}
		}

		&--green {
			background-color: $color-green-50;

			&:hover,
			&:focus-visible {
				background-color: $color-green-100!important;
				color: $color-sea-blue-900!important;
			}
		}
		
		&:hover {
			color: white;
			background-color: $color-blue-dark;
		}

		&.active {
			font-weight: 600;
			background-color: $color-blue-dark;
			color: white;
			
			&:hover,
			&:focus-visible {
				color: $color-blue-dark;
				background-color: #fff;
			}
		}
  	}

	&-more {
		color: #323232;
		display: block;
		padding: 5px 16px 3px;
		border: 1px solid transparent;
		text-decoration: none;

		&:hover {
			color: white;
			background-color: $color-blue-dark;
		}
	}
}

.search-page {
	color: #323232;
	
	&__header {
		margin-top: 60px;

		@media #{$small-only} {
			margin-top: 44px;
		}

		.word-highlight {
			display: flex;
			align-items: center;
			gap: 18px;
			margin-top: -22px;
			margin-bottom: 30px;

			@media #{$medium-up} {
				padding-top: 18px;
				justify-content: flex-end;
				margin-top: 0;
				margin-bottom: 0;
			}

			@media #{$large-up} {
				float: none!important;
			}

			&-label {
				margin: 0;
				color: $color-sea-blue-900;
				font-size: 16px;
				line-height: 22px;
			}

			&-switch {
				position: relative;
				width: 50px;
				height: 27px;

				input {
					display: none;

					&:checked + .word-highlight-switch-label {
						background-color: $color-orange-100;

						.word-highlight-switch-label-toggle {
							left: calc(100% - 23px);
						}
					}
				}

				&-label {
					position: relative;
					display: block;
					overflow: hidden;
					width: 100%;
					height: 100%;
					box-shadow: inset 0 0 0 2px $color-sea-blue-900;
					border-radius: 50px;
					background-color: transparent;
					transition: .2s ease-in-out;

					&-toggle {
						display: block;
						position: absolute;
						z-index: 2;
						top: 50%;
						left: 5px;
						transform: translateY(-48%);
						width: auto;
						height: 18px;
						aspect-ratio: 1;
						border-radius: 50%;
						background-color: $color-sea-blue-900;
						transition: .2s ease-in-out;

						@media #{$large-up} {
							transform: translateY(-52%);
						}
					}
				}
			}
		}
	}

	&__form {
		display: flex;
		position: relative;
		margin-bottom: 57px;

		@media #{$small-only} {
			margin-bottom: 37px;
		}
	}

	input[type="text"] {
		color: $color-blue-dark;
		background-color: transparent;
		border: none;
		border-bottom: 2px solid $color-blue-dark;
		box-shadow: none;
		flex: 1;
		padding: 10px 36px 10px 0;
		@extend .h1;
	}

	&__input {

	}

	&__button {
		background-color: transparent;
		position: absolute;
		width: 34px;
		height: 50px;
		right: 0;
		top: 0;
		padding: 0;
		margin: 0;
		border: none;
	}
}