.topic-header-container{
	background-color: $color-gray-warm-3;
	padding-left: $global-padding;
	// padding-right: $global-padding;
	margin-left: -$global-padding;
	margin-right: -$global-padding;
	// margin-bottom: $spacing-medium;
	//@include cf();
	position: relative;
	@media #{$medium-up} {
		height: 200px;	
	}
}

.topic-header-left{
	@media #{$medium-up} {
		width: 60%;
		float: left;
	}
}

.topic-header-right{
	display: none;
	margin-right: 1.25rem;
	@media #{$medium-up} {
		display: block;
		width: 40%;
		float: right;
	}
}

.topic-header-text{
	padding-top: $spacing-large;
	padding-bottom: $spacing-large;
	@media #{$medium-up} {
		position: absolute;
		bottom: 0;
	}
}

.topic-header-image{
    width: 100%;
    @media #{$medium-up} {
		height: 200px;	
	}
    background-position: center center;
    background-size: cover;
}