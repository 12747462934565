.article-image-container{
	@include clearfix();
	margin-bottom: $spacing-large;
}

.article-image{
	margin-bottom: $spacing-small;
	> img{
		width: 100%;
	}

	@media print {
		span,
		p{
			font-style: italic;
		}
	}
}

.article-image-caption{
	@extend .paragraph-image-caption;
	margin-top: $spacing-medium;
	// .credit{
	// 	font-weight: bold;
	// }
}

article.article > img.article-image-big,
article.article > p > img.article-image-big,
.article-image-big{
	width: 100%;
	height: auto;
	margin-bottom: $spacing-medium;
	margin-top: $spacing-medium;

}

.article-image-small,
article.article > img,
article.article > p > img{
	width: 100%;
	padding-top: $spacing-medium;
	padding-bottom: $spacing-medium;
	@media #{$medium-big-up} {
		width: auto;
		float: left;
		padding-right: $spacing-large;
		padding-bottom: $spacing-medium;
	}
	@media print {
		width: auto;
		float: left;
		padding-right: $spacing-large;
		padding-bottom: $spacing-medium;
	}

}

.article{
	figure{
		&.editor-align-right, &.editor-align-left, &.editor-align-center{
			margin-right: 0;
			margin-left: 0;
		}

		&.editor-align-right{
			margin-left: 20px;
			margin-bottom: 20px;
		}

		&.editor-align-left{
			margin-right: 20px;
			margin-bottom: 20px;
		}
	}
}