.boxed-contact {
    width: 100%;
    &.medium {
        @media #{$medium-up} {
            width: 50%;
            float: left;
        }
    }
    .article & {
        &.large {
            margin-bottom: $spacing-large + $spacing-large;
        }
    }
}

.boxed-contact-content {
    height: 100%;
    text-decoration: none;
    @extend .block-padding;
    @media #{$medium-up} {
        padding: $spacing-large + $spacing-medium;
    }
    background-color: $color-orange-light;
}

.boxed-contact-title {
    @extend .block-header;
    @extend .header-margin-large;
}
