.swatch {
  display: inline-block;
  margin-bottom: $spacing-xlarge;
  margin-right: $spacing-medium;
  width: 100px;
  vertical-align: top;
}

.swatch-color {
  border-radius: 50%;
  height: 100px;
  margin-bottom: $spacing-medium;
  &[data-color="#fff"] {
    border: 1px solid $color-black;
  }
}

.swatch-code {
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  > div {
    white-space: pre-line;
  }
}
