.accordion-tertiary{
  &.accordion-search-filters{
      background: $color-gray-warm-3;

      .accordion-navigation {
        border-top: none;

        & > a{
            padding: 20px 30px;

            &:after{
                right: 15px;
                top: 50%;
            }            
        }
        .content{
            margin-left: 0 !important;
        }
        .search-filter{
            padding-top: 0;
            padding-bottom: 0;
        }
        .search-filter-heading{
            display: none;
        }
      }
  }
}
