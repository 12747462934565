.EPiServerForms {
  .Form__Status {
    float: left;
    width: 100%;

    .Form__Status__Message.Form__Success__Message {
      min-height: 166px;
      background-color: #f2f1f0;
    }
  }

  .Form__Status .Form__Status__Message.hide {
    margin: 0;
    padding: 10px 0 0;
  }

  .Form__MainBody {
    clear: both;

    .Form__Element + .FormParagraphText {
      padding-top: 55px;
    }

    .ValidationRequired {
      .Form__Element__Caption {
        &:after {
          content: "*";
        }
      }

      &.FormFileUpload {
        .file-uploader-label {
          &:after {
            content: "*";
          }
        }
        .Form__Element__Caption {
          .js &:after {
            content: " ";
          }
        }
      }
    }

    .ValidationRequired.ValidationFail {
      .FormTextbox__Input {
        border-color: $color-error;
      }
    }

    .Form__Element .Form__Element__ValidationError {
      @extend label;
      color: $color-error;
    }

    .FormFileUpload {
      .js & {
        position: relative;
        display: block;
        width: 100%;
        clear: both;
        cursor: pointer;
      }

      .file-uploader-label {
        @extend label;
        margin-bottom: 2px;
      }

      .FormFileUpload__Input {
        .js & {
          margin: 0;
          height: 100%;
          width: 100%;
          overflow: hidden;
          background: red;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }

      .Form__Element__Caption {
        .js & {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          display: inline-block;
          overflow: hidden;
          background-color: #fff;
          border: 1px solid $color-gray-input-border;
          color: $color-sea-blue-900;
          padding: 13px 20px;
          font-family:  'PPMori', sans-serif;
          font-size: 18px;
          line-height: 28px;
          font-weight: normal;
          cursor: pointer;
          position: relative;
          z-index: 0;

          &.has-focus {
            color: $color-white;
            background-color: $color-sea-blue-900;
            border-color: $color-sea-blue-900;
          }
        }
      }

      &:hover {
        .Form__Element__Caption {
          .js & {
            color: $color-white;
            background-color: $color-sea-blue-900;
            border-color: $color-sea-blue-900;
          }
        }
      }

      .FormFileUpload__File-name-icon {
        height: 20px;
        width: 15px;
        background: url(../assets/images/arrow-dark-black.svg) no-repeat 0
          center;
        background-size: 10px 10px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .Form__NavigationBar {
      /*@include flexbox;
        @include flex-wrap(nowrap);
		@include align-items(center);
		@include justify-content(space-between);
        margin: 80px 0 25px;*/
      position: relative;
      padding-top: 30px;
      margin: 75px 0 100px;

      .Form__NavigationBar__ProgressBar {
        height: 15px;
        border: none;
        background: #f2f1f0;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 7px;
        overflow: hidden;
      }
      .Form__NavigationBar__ProgressBar--Progress {
        background-color: $color-blue-dark !important;
        border-radius: 7px;
        height: 15px !important;
      }

      .Form__NavigationBar__Action {
        &.btnPrev,
        &.btnNext {
          padding: 15px;
          height: auto;
          min-height: 56px;
          border-radius: 0;
          background: $color-white;
          border: 1px solid $color-gray-warm-2;
          color: $color-blue-dark;
          cursor: pointer;
          margin: 0;
          white-space: nowrap;
          min-width: 166px;
          width: auto;
          max-width: 50%;
          overflow: hidden;
          background-size: contain;
          line-height: 22px;
          position: relative;

          &:hover {
            color: white;
            background: $color-blue-dark;
            border-color: $color-blue-dark;
          }

          &:after {
            background: transparent none no-repeat scroll 50% 50%;
            background-image: url(../assets/images/arrow-dark-black.svg);
            background-size: 10px;
            bottom: 0;
            content: " ";
            display: block;
            position: absolute;
            top: 0;
            width: 45px;
          }

          &:hover::after {
            background-image: url(../assets/images/arrow-sea-white.svg);
          }

          &:disabled {
            opacity: 0.6;
            color: $color-gray-input-border;
            cursor: default;

            &:after {
              background-image: url(../assets/images/arrow-grey.svg);
            }
            &:hover {
              background: none;
            }
          }
        }

        &.btnPrev {
          margin-right: 0px;
          float: left;
          padding-left: 52px;
          text-align: right;
          &:after {
            left: 0;
            transform: rotate(180deg);
          }
        }

        &.btnNext {
          margin-left: 0px;
          float: right;
          padding-right: 52px;
          text-align: left;

          &:after {
            right: 0;
          }
        }
      }
    }

    .FormSubmitButton {
      @extend .button;
      padding: 8px 20px;
      height: auto;
      height: 40px;
    }

    .Form__Element {
      margin-bottom: 25px;

      &:after {
        display: block;
        clear: both;
        width: 100%;
        content: "";
      }
    }

    .FormChoice {
      .Form__Element__Caption {
        float: left;
        clear: both;
        margin-bottom: 2px;
      }
      label {
        margin: 0 0 5px;
        position: relative;

        .js & {
          float: left;
          clear: both;
          padding: 0 0 0 30px;
        }
      }

      .FormChoice__Input {
        .js & {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          opacity: 0;
        }
      }

      .FormChoice__Input--Radio + .FormChoice__Input--Text {
        &:before,
        &:after {
          border-radius: 50%;
          content: " ";
          display: block;
          position: absolute;
        }

        &:before {
          height: 10px;
          left: 5px;
          // top: 5px;
          width: 10px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
        }

        &:after {
          border: 1px solid $color-gray-input-border;
          background: #fff;
          height: 20px;
          left: 0;
          // top: 0;
          width: 20px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
        }
      }

      .FormChoice__Input--Radio:checked + .FormChoice__Input--Text {
        &:before {
          background-color: $color-sea-blue-900;
        }
      }

      .FormChoice__Input--Radio:disabled + .FormChoice__Input--Text {
        &:after {
          opacity: 0.5;
        }
      }

      .FormChoice__Input--Checkbox + .FormChoice__Input--Text {
        &:before {
          border: 1px solid $color-gray-input-border;
          border-radius: 2px;
          content: " ";
          display: block;
          height: 20px;
          left: 0;
          position: absolute;
          // top: 0;
          width: 20px;
          top: 50%;
          transform: translateY(-50%);
          background: #fff;
        }
      }

      .FormChoice__Input--Checkbox:checked + .FormChoice__Input--Text {
        &:before {
          background: #fff url("../assets/images/check-blue-dark.svg") no-repeat
            scroll 50% 50%;
          background-size: contain;
        }
      }

      .FormChoice__Input--Checkbox:focus + .FormChoice__Input--Text:before {
        @include inputFocus;
      }

      .FormChoice__Input--Radio:focus + .FormChoice__Input--Text:after {
        @include inputFocus;
      }
    }

    input,
    textarea {
      &::placeholder {
        color: #9d9a98;
        font-weight: 300;
      }
    }

    .FormTextbox--Textarea {
      textarea.FormTextbox__Input {
        height: 166px;
      }
    }

    .Form__Element__Caption {
      margin-bottom: 2px;
    }
  }
}
