.main-header {
  background-color: $color-white;

  .main-header-bg {
    display: none;
  }
  @media #{$large-up} {
    position: relative;
    overflow: hidden;
    //background-color: lighten($color-blue-light, 7%);
    background: #83b4c6; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #83b4c6 0%,
      #cbd6da 180px,
      #cbd6da 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #83b4c6 0%,
      #cbd6da 180px,
      #cbd6da 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #83b4c6 0%,
      #cbd6da 180px,
      #cbd6da 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#83b4c6', endColorstr='#cbd6da',GradientType=0 ); /* IE6 */

    &.innsiden {
      background: #8bb76f; /* Old browsers */
      background: -webkit-linear-gradient(
        #8bb76f 0%,
        #e2e9dc 180px,
        #e2e9dc 100%
      );
      background: -o-linear-gradient(#8bb76f 0%, #e2e9dc 180px, #e2e9dc 100%);
      background: linear-gradient(
        #8bb76f 0%,
        #e2e9dc 180px,
        #e2e9dc 100%
      ); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bb76f', endColorstr='#e2e9dc',GradientType=0 ); /* IE6 */
    }

    .main-header-bg {
      background: url(../assets/images/outline_logo_bg.svg) no-repeat;
      background-size: 720px;
      position: absolute;
      right: -70px;
      top: -80px;
      width: 720px;
      height: 600px;
      display: block;
      opacity: 0.15;
    }
  }

  @media #{$medium-only} {
    transition: background 0.1s ease-in;
    .body-overlay-active & {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @media #{$large-up} {
    // margin-top: $spacing-medium;
    padding-top: $spacing-large;
  }

  @media print {
    position: relative;
  }
}

.top {
  @include cf();
  margin-left: -$spacing-large;
  margin-right: -$spacing-large;
  background-color: $color-blue-dark;

  @media #{$medium-up} {
    // margin-bottom: $spacing-large + $spacing-medium;
  }
  @media #{$large-up} {
    margin-left: 0;
    margin-right: 0;
    margin-top: $spacing-medium + $spacing-small;
    background-color: transparent;
    position: relative;
  }

  @media print {
    background-color: $color-white;
  }
}

.logo-container {
  z-index: 1001;
  width: 100px;
  height: auto;

  @media #{$large-up} {
    width: 125px;
    margin-bottom: -$spacing-large;
  }

  @media print {
    width: 100px;
    height: 120px;
    float: right;
    margin: 0;
    position: absolute;
    top: 0px;
    right: 0px;

    img {
      max-height: 120px;
    }
  }

  > a {
    display: block;
  }

  /*************
  ** INNSIDEN **
  **************/
  .innsiden & {
    width: 135px;
    height: 54px;

    @media #{$medium-up} {
      width: 182px;
      height: 73px;
    }

    @media #{$large-up} {
      position: absolute;
      left: -27px;
      width: 281px;
      height: auto;
      bottom: -109px;
      z-index: 2;
    }

    @media screen and (min-width: 1150px) {
      width: 281px;
    }

    @media print {
      width: 150px;
      height: 150px;
    }
  }
}

// ** needed for Innsiden header **
.logo-mobile {
  display: block;
  width: 200px;
  height: 54px;

  @media #{$medium-up} {
    width: 270px;
    height: 73px;
  }
  @media #{$large-up} {
    display: none;
  }
}
.logo-desktop {
  display: none;
  @media #{$large-up} {
    display: block;
    outline: none;
    border: none;
    width: 100%;
    height: auto;
  }
  @media print {
    display: block;
  }
}
// ******************************

ul.desktop-support-nav {
  @extend .show-for-large-up;
  text-align: right;
  margin-bottom: $spacing-large - $spacing-small;
  margin-right: $spacing-large;
  > li {
    display: inline-block;
    margin-left: 12px;
    &:after {
      content: "|";
      font-size: 14px;
      color: #fff;
      position: absolute;
      top: 4px;
      right: -13px;
    }
    &:last-child:after {
      display: none;
    }
    > a {
      font-size: 14px;
      font-weight: normal;

      color: $color-black;
      // font-weight: 400;
      text-decoration: none;
      &:hover {
        // color: $color-sea-green;
        text-decoration: underline;
      }
    }
    @media #{$medium-up} {
      float: right;
      position: relative;
    }
    @media #{$large-up} {
      float: none;
    }
  }
}

ul.main-nav {
  // display: none;
  &.active {
    display: block;
  }

  margin: 0;
  width: 100%;
  @media #{$medium-up} {
    width: 300px;
    position: absolute;
    right: 0;
  }
  @media #{$large-up} {
    width: auto;
    position: initial;
  }

  li {
    display: block;

    a {
      color: $color-white;
      padding: $spacing-medium;
      display: block;
      background-color: $color-blue-dark;
      border-bottom: 1px solid $color-sea-green;
      font-size: 14px;

      &.main-nav-arrow {
        padding-right: $spacing-large;
        &:after {
          content: "";
          background-size: 16px 16px;
          background-image: url(../assets/images/arrow-sea-green.svg);
          width: 16px;
          height: 16px;
          position: absolute;
          right: $spacing-medium;
          transform: rotate(-270deg);
        }
        &.active:after {
          transform: rotate(-90deg);
        }
      }
    }
    &:last-child a.main-nav-arrow:after {
      border-bottom: none;
    }
  }
}

ul.main-nav-first {
  @include cf();
  > li {
    @media #{$large-up} {
      float: left;
    }
    // @media #{$medium-up} {
    // 	float: left;
    // }
  }
}

ul.main-nav-second {
  margin: 0;
  display: none;
  &.active {
    display: block;
  }

  @media #{$large-up} {
    position: absolute;
    left: 0;
  }

  > li {
    padding-left: $spacing-large;
  }
}

@media print {
  .desktop-support-nav {
    display: none;
  }
  .search-global-container {
    display: none;
  }
  .logo-desktop {
    display: block;
  }
  .logo-mobile {
    display: none;
  }
}

/*************
** Mobile view only **
**************/
.mobile-view-only {
  .main-header {
    background-color: $color-blue-dark;

    .main-header-bg {
      display: none;
    }
    @media #{$large-up} {
      position: relative;
      overflow: visible;
      background: $color-blue-dark; /* Old browsers */

      .main-header-bg {
        display: none;
      }
    }

    @media #{$medium-only} {
      transition: background 0.1s ease-in;
      .body-overlay-active & {
        background: rgba(0, 0, 0, 0.5);
      }
    }

    @media #{$large-up} {
      padding-top: 0;
    }
  }
  .top {
    margin-left: -$spacing-large;
    margin-right: -$spacing-large;
    background-color: $color-blue-dark;
    @media #{$large-up} {
      margin-left: -$spacing-large;
      margin-right: -$spacing-large;
      margin-top: 0;
      background-color: $color-blue-dark;
    }
  }

  ul.desktop-support-nav {
    display: none !important;
  }

  @media print {
    .logo-desktop {
      display: block;
    }
    .logo-mobile {
      display: none;
    }
  }
}
