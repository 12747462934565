.colored-button{
	@extend .h4;
	color: $color-blue-dark;
	padding-left: 50px;
	text-align: left;
	width: 100%;
	@media #{$medium-up} {
		width: auto;
		min-width: 400px;
	}
	&:before{
		content: "";
		background-position: left 10px center;
		background-repeat: no-repeat;	
		height: 34px;
	    width: 50px;
	    display: inline-block;
	    position: absolute;
	    left: 0;
	    top: 50%;
	    transform: translateY(-50%);
	}
	

	&.blue{
		background-color: lighten($color-blue-light, 4%);
		&:hover{
			background-color: darken( $color-blue-green, 5% );
		}
		&:before{
			background-image: url(../assets/images/icon-search.svg);	
		}
		
	}
	&.yellow{
		background-color: $color-lime-green;
		&:hover{
			background-color: darken( $color-lime-green, 5% );
		}
		&:before{
			background-image: url(../assets/images/icon-search.svg);	
		}
	}
	&.red{
		background-color: $color-orange-light;
		&:hover{
			background-color: darken( $color-orange-light, 5% );
		}
		&:before{
			background-image: url(../assets/images/icon-question.svg);	
		}
	}
	&:hover{
		color: $color-blue-dark;
	}
}