.calendar-events-container {
  margin: 0;
  padding: 0;

  + .link-single {
    margin-top: $spacing-medium;
  }
}

.calendar-event {
  @include cf();
  list-style: none;
  margin: 0;
  padding: $spacing-large + $spacing-medium 0;
  border-bottom: 1px solid $color-warm-300;
  margin-bottom: $spacing-medium;

  &:first-child {
    border-top: 1px solid $color-warm-300;
  }

  > a {
    display: block;
    font-weight: 400;
    
    &:hover,
    &:focus {
      .calendar-event-text {
        text-decoration: none;
      }
    }
  }

  &-container {
    float: left;
    display: table;
    height: 65px;
  }

  &-date {
    display: table-cell;
    vertical-align: middle;
    width: 120px;
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    font-family: $button-font-family;
    color: $color-text-gray;
  }

  &-text {
    display: table-cell;
    vertical-align: middle;
    padding-left: $spacing-large;
    font-family: $body-font-family;
    font-size: $font-size-small;
    font-weight: 400;
    font-style: normal;
    text-decoration: underline;
    color: $color-text-gray;

    @media #{$large-up} {
      font-size: $font-size-base;
    }
  }

  &-day,
  &-month {
    @extend .h2;
    font-size: 22px;
    color: $color-black;
  }
}
