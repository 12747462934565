.block{
	position: relative;
	padding: 30px 33px 36px;
	margin: 20px 0;
	width: 100%;
	background: white;
	box-shadow: 0 2px 3px rgba(0,0,0,0.18);

	&-heading{
		font-size: 24px;
		margin: 0 0 10px;
		line-height: 1.3;
	}

	p{
		margin-top: 0;
	}

	&--text{
		margin: 0;
		padding: 0;
		width: 100%;
		box-shadow: none;

		p{
			margin: 1em 0;
			line-height: 1.6;
			max-width: 600px;
		}
	}

	&--in-group{
		position: relative;
		padding: 20px 24px 20px;
		margin: 20px 0;
		width: 100%;
		background: $color-blue-light-2;
		box-shadow: none;
	}
	
	&--in-simple-group{
		position: relative;
		padding: 20px 24px 20px;
		margin: 20px 0;
		width: 100%;
		background: none;
		box-shadow: none;
		margin-top: 36px;
		padding: 0;
	}

	&--in-group &-heading,
	&--in-simple-group  &-heading{
		font-size: 18px;
		margin-bottom: 4px;
	}
}