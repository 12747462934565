.boxed-top-task {
  @media #{$medium-up} {
    margin-bottom: $spacing-xlarge;
  }

  &-title {
    @extend .block-header;
    display: flex;
    justify-content: center;
    width: max-content;
    max-width: 100%;
    transform: translateY(-54px);
    margin: auto;
    border: none;
    padding: 0 $spacing-large;
    background-color: $white;

    img {
      height: 35px;
      max-height: 60px;
      width: auto;
      min-width: 60px;
      max-width: 100px;
      object-fit: contain;
      margin-right: $spacing-medium;
    }

    .block-header {
      margin: auto;
      margin-bottom: 2px;
      padding: 0;
      line-height: 1;
    }

    @media #{$large-up} {
      transform: translateY(-65px);

      img {
        height: 50px;
        margin-right: $spacing-large;
      }

      .block-header {
        margin-bottom: 5px;
      }
    }
  }

  &-content {
    @include boxed-content-wysiwyg();
    margin-top: $spacing-xlarge;
    height: 100%;
    width: 100%;
    border: 1px solid $color-black;
    padding: $spacing-large;
    padding-top: $spacing-large + $spacing-medium;
    background-color: $white;
    
    > ul {
      transform: translateY(-40px);
      list-style: none;
      margin: 0;
      
      li {
        margin: 0;
        margin-bottom: $spacing-large;
        overflow-wrap: break-word;
        
        p {
          font-size: 19px;
          margin-top: 0;
          color: $color-blue-dark;
        }
      }
    }

    @media #{$medium-up} {
      padding: $spacing-large + $spacing-medium;
    }
    .h1-link {
      font-size: 26PX;
    }
  }

  &-link {
    max-width: 680px;

    &:last-of-type {
      margin-bottom:  $spacing-large * 2;

      > a {
        border-bottom: 1px solid $color-gray-warm;
      }
    }

    @media #{$medium-up} {
      &:last-of-type > a {
        margin-bottom: $spacing-xxlarge + $spacing-large;
      }

      &.small:nth-last-of-type(2) > a,
      &.medium:nth-last-of-type(2) > a {
        border-bottom: 1px solid $color-gray-warm;
      }

      &.small:nth-last-of-type(3) > a,
      &.small:nth-last-of-type(4) > a {
        border-bottom: 1px solid $color-gray-warm;
      }
    }

    &--link {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
      margin: -$spacing-medium 0;
      border-top: 1px solid $color-gray-warm;
      padding: $spacing-large 0;
      font-family: $header-font-family;
      text-decoration: none;
      color: $color-blue-dark;
      font-size: $font-size-large;
      font-weight: 400;

      > span {
        flex-grow: 1;
        margin: auto 0;
      }

      &:hover {
        text-decoration: underline;
      }

      @media #{$medium-up} {
        padding: $spacing-large 0;
        padding-right: $spacing-large * 2;
        font-size: 25px;
        line-height: 40px;
        font-weight: 600;

        &:after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          top: 50%;
          right: 2px;
          transform: translateY(-50%);
          background: url("../assets/images/arrow-blue-dark-redesign2020.svg") center no-repeat;
          background-size: cover;
        }
      }
    }

    &--image {
      display: inline-block;
      width: 35px;
      min-width: 35px;
      max-width: 35px;
      height: 35px;
      margin: auto $spacing-small;
      margin-top: 0;
      margin-right: $spacing-medium;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      @media #{$medium-up} {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        margin-top: auto;
        margin-right: $spacing-large;
      }
    }
  }
}
