.feedback-container {
  margin-bottom: -$global-padding;

  @media #{$large-up} {
    min-height: 90px;
  }

  @media print {
    display: none;
  }

  &.rules {
    @media #{$medium-big-up} {
      min-height: 110px;
    }

    .feedback-content {
      @media #{$medium-big-up} {
        width: 66.66667%;
        margin-left: 33.33333%;
        min-height: 110px;
      }
    }
  }
  &.content {
    margin-top: -40px;
  }

  &.hidden {
    display: none;
  }

  .feedback-content {
    text-align: center;
    margin-bottom: $global-padding;
  }

  .feedback-actions-textarea {
    font-size: 18px;
  }

  .feedback {
    padding-top: $global-padding;
    padding-bottom: $global-padding;
    border-top: 1px solid $color-gray-warm-2;

    &.feedback_step-1 {
      padding-bottom: 10px;
    }

    &.feedback_step-2 {
      border: none;
      padding-top: 0;
    }

    &.feedback_step-2,
    &.feedback_step-3 {
      display: none;
    }

    .input-container {
      display: none;

      &.active {
        display: block;
      }
    }

    .btn-ga-send {
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  .feedback-text,
  .feedback-actions {
    .btn-ga-send {
      vertical-align: bottom;
      margin: 0;
    }

    @media #{$large-up} {
      display: inline-block;
      vertical-align: middle;

      p {
        margin: 0px;
        margin-right: 8px;
      }
    }

    label {
      width: 70%;
      @media #{$large-up} {
        width: auto;
      }
    }
    textarea {
      width: 100%;
      display: inline-block;
      vertical-align: bottom;
      margin: 0 0 10px;
      height: 110px;
      min-height: 110px;

      &::placeholder {
        color: #9d9a98;
        font-style: italic;
        font-weight: 300;
      }
    }
  }

  .feedback_step-2 .feedback-actions {
    width: 100%;
    margin: 0 auto;

    @media #{$large-up} {
      width: 66.66667%;
    }

    label {
      width: 100%;
      clear: both;
    }
  }
}
