input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
.ck-editor__editable,
textarea {
  border: 1px solid $color-gray-input-border;
  margin-bottom: $spacing-small;
  font-size: 16px;
  font-weight: normal;
  @media #{$large-up} {
    font-size: 18px;
  }

  &:focus {
    @include inputFocus;
  }

  &.error,
  &.input-validation-error {
    border-color: $color-error;

    &:focus {
      @include inputFocus;
    }
  }

  &.alternate {
    border-color: $color-blue-light-2;
    background-color: $color-blue-light-2;
    color: $color-blue-dark;

    &::placeholder {
      color: $color-blue-dark;
      font-style: italic;
      font-weight: 300;
    }

    &:focus {
      background-color: darken($color-blue-light-2, 4%);
    }
  }
}

.field-validation-error {
  @extend .input-error;
}

// pureservice style
.Form__MainBody{
  .article{
    margin-bottom: 30px;
    padding-bottom: 0;
  }
}

.ck-editor__editable{
  min-height: 260px;
  
  @media #{$medium-up} {
    min-height: 360px;
  }

  &.ck-focused{
    outline: none !important;
    border-color: $color-sea-blue-900 !important;
    box-shadow: 0px 0px 2px 0px $color-sea-blue-900 !important;
  }
}

:root{
  --ck-color-base-border: $color-warm-300 !important;
}
// end pureservice style