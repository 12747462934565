.front-page-intro {
	&__wrapper {
        display: block;

        @media #{$large-up} {
            width: 70%;
        }

        p,
        h1,
        h2 {
            font-size: 28px;
            line-height: 42px;
            color: $color-sea-blue-900;
            font-weight: 400;

            @media #{$medium-up} {
                font-size: 32px;
                line-height: 44px;
            }
        }
    }
}