.boxed-image {
  @include cf();
  width: 100%;

  &-image {
    @include image-link-hover();
    background-color: white;
    overflow: hidden;

    .centred {
      text-align: center;
    }

    .small & {
      height: 195px;

      .centred {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
      }
    }
  }

  &-title {
    font-size: 21px;
    line-height: 34px;
    font-weight: 600;
    color: $color-blue-dark;
    margin-bottom: $spacing-large;

    .columns-layout & {
      font-size: 20px;
      line-height: 1.5;
      font-weight: 500;
    }
  }

  &-content {
    max-width: 100%;
    height: auto;

    .medium & {
      padding: 30px;
      max-width: 100%;
      height: auto;
    }
  }

  &-text {
    @extend .block-padding;
    font-size: 15px;
    @media #{$large-up} {
      font-size: 19px;
    }
  }

  a {
    height: 100%;
    display: block;
    border: 1px solid $color-gray-warm-3;
    background-color: $color-gray-warm-3;
    color: $color-black;
    text-decoration: none;

    &[href=""] {
      pointer-events: none;
    }

    &.green {
      background-color: $color-green-redesign2020;
    }

    &.light-green {
      background-color: $color-green-50;
    }

    &.blue {
      background-color: $color-blue-dark;

      h1,
      h2,
      h3,
      h4,
      h5,
      p,
      div,
      span {
        color: $color-white;
      }
    }

    &.light-blue {
      background-color: $color-sea-blue-50;
    }

    &.yellow{
      background-color: $color-orange-50;
    }

    .boxed-image-title {
      text-wrap-style: pretty;
      
      &:after {
        content: '';
        background: transparent url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.6856 9.99942L6.43408 4.75666L7.94603 3.24219L14.7145 9.99942L7.94603 16.7567L6.43408 15.2422L11.6856 9.99942Z" fill="%23003045"/></svg>') center center / contain no-repeat;
        width: 20px;
        height: 20px;
        min-width: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        transform: translateY(-2px);
      }
    }

    &:hover,
    &:focus {
      transition: all 0.15s;

      .boxed-image-title {
        text-decoration: underline;
      }
    }
  }
}
