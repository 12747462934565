ul.list-big {
  margin: 0;
  padding: 0;
  margin-bottom: $spacing-large;
  list-style: none;

  > li {
    @include boxed-content-wysiwyg();
    padding: $spacing-large 0;
    border-bottom: 0.4px solid $color-text-gray;

    &:first-child {
      padding-top: 0;
    }
  }

  &.list-big-news {
    @include cf();

    > li {
      > a {
        display: block;
        text-decoration: none;
      }
    }
  }

  .list-big-item {
    &-info {
      @extend .meta;
      margin-top: 10px;
    }

    &-deadline {
      display: inline;
    }

    &-status {
      margin-left: 8px;
      padding-left: 12px;
      border-left: 1px solid #999;
    }
  }
}

.list-big-title {
  @extend .block-header;
}

// list-big-news
.list-big-news-container {
  display: block;
  text-decoration: none;
  color: $color-black;
  @include cf();
  a:hover &,
  a:focus & {
    color: $color-black;
  }
  .list-big-news-title {
    @include block-header();
    font-weight: 600;
    color: $color-blue-dark;
    a:hover &,
    a:focus & {
      text-decoration: underline;
    }
  }
  .list-big-news-text{
    font-size: 19px
  }
  .list-big-news-image {
    @extend .image-container;
    @include image-link-hover();

    @media #{$medium-up} {
      float: left;
      width: 30%;
    }
  }
  .list-big-news-image + .list-big-news-content {
    padding-top: $spacing-large;
    @media #{$medium-up} {
      float: left;
      width: 70%;
      padding-left: $spacing-large;
      padding-top: 0;
    }
  }
}
