.validation-summary{
	&-errors{
		ul{
			list-style: none;
			li{
				@extend .announcement-container;
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}
}