.hearings-header{
	

	&-title{
		@media #{$large-up} {
			display: inline-block;
			vertical-align: middle;
		}


	}

	&-main-info{
		background: #fff;
		margin-bottom: $spacing-large*2;
		position: relative;
		padding: 30px 0;

    	.h1{
			line-height: 50px;
		}

    	@media #{$large-up} {
    		padding: 30px 20px 0px 60px;
    		margin-left: 70px;
    		height: 110px;
    		overflow: hidden;
    	}
    	@media screen and (max-width: 1150px){
			height: 107px;
			.h1{
				line-height: 47px;
			}
		}
		@media print {
			display: none;
		}
	}

	&-login{
		@media #{$medium-up} {
			float: right;
		}
	}

	&-registration-link{
		font-size: $font-size-base;
		margin-right: 20px;
	}

	&-support-nav{
		float: right;
		height: 33px;
		
		li{
			position: inherit !important;
			float: left !important;

			a{
				line-height: 2.4;
				float: left;
			}
		}
	}

}