/***************
## Breadcrumb ##
***************/
.breadcrumb {
  @extend .main-content;
  float: none;
  margin-top: $spacing-medium;
  margin-bottom: $spacing-medium;

  @media #{$medium-up} {
    margin-bottom: $spacing-large;
  }

  &.error-page {
    display: none;

    @media #{$medium-up} {
      display: block;
    }
  }

  > span:first-child {
    display: none;
  }

  ul.breadcrumb-list {
    margin-left: 0;
    display: inline;
    white-space: nowrap;

    > li {
      display: none;

      &.hidden {
        @extend .paragraph-small;
        visibility: hidden;
        max-width: 100%;
        overflow: hidden;
      }

      &:after {
        content: "/";
        margin-left: 12px;
        margin-right: 12px;
      }

      > a {
        @extend .paragraph-small;
        text-decoration: none;
      }

      &:first-child,
      &:nth-last-child(2) {
        display: inline-block;
      }

      &:first-child {
        &:before {
          content: "...";
        }

        > a {
          display: none;
        }
      }

      &:nth-last-child(2) {
        display: inline-block;

        &::after,
        &::before {
          content: none;
        }

        > a {
          display: inline-block;
        }
      }

      &:last-child {
        &:after {
          content: none;
        }

        > a {
          font-weight: 700;
          text-decoration: none;
          display: inline-block;
          color: $color-hyperlink;
        }
      }

      @media #{$medium-up} {
        &:last-child,
        &:nth-last-child(3) {
          display: inline-block;

          > a {
            display: inline-block;
          }
        }

        &:nth-last-child(2)::after {
          content: "/";
        }

        &:nth-last-child(3)::before {
          content: none;
        }

      }

      @media #{$large-up} {
        display: inline-block;

        &:first-child {
          &::before {
            content: none;
          }

          > a {
            display: inline-block;
          }

        }
      }
    }
  }
}

@media print {
  .breadcrumb {
    display: none;
  }
}
