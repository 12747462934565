.search-component {
	position: relative;
	display: block;
	width: 100%;
	max-width: 100%;
	box-shadow: inset 0 0 0 3px transparent;
	transition: .2s ease-in-out;

	@media #{$medium-big-up} {
		width: 680px;
		margin-left: -20px;
		max-width: calc(100% + 40px);
	}

	&.focus,
	&:hover,
	&:focus,
	&:focus-visible,
	&:has(*:focus-visible),
	&.visible {
		background-color: $color-white;
		box-shadow: inset 0 0 0 3px #4899E0;
	}

	.search-wrapper {
		padding: 15px;

		@media #{$medium-up} {
			padding: 20px;
		}

		.search-top {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 20px;
			border-bottom: 3px solid $color-sea-blue-900;
			padding-bottom: 8px;

			.search-keywords {
				display: none;
			}

			.search-autocomplete {
				pointer-events: none;
				z-index: 9;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				font-size: 18px;
				line-height: 27px;
				font-weight: 600;
				color: $color-sea-blue-900;
				opacity: .3;

				@media #{$medium-up} {
					font-size: 24px;
					line-height: 36px;
				}

				span {
					color: transparent;
				}
			}

			.search-input {
				background-color: transparent;
				border: none;
				height: auto;
				color: $color-sea-blue-900;
				font-size: 18px;
				line-height: 27px;
				font-weight: 600;
				margin: 0;
				padding: 0;
				box-shadow: none;

				@media #{$medium-up} {
					font-size: 24px;
					line-height: 36px;
				}

				&::placeholder {
					color: $color-sea-blue-900;
				}

				&:focus-visible {
					&::placeholder {
						opacity: .2;
					}
				}
			}

			.search-submit {
				margin: 0;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 22px;
				min-width: 22px;
				height: 22px;
				background: transparent url(../assets/images/search.svg) center center / contain no-repeat;
			}
		}

		.search-bottom {
			.search-suggestions {
				p {
					color: $color-sea-blue-900;
					margin-bottom: 0;

					a {
						font-weight: 700;
					}
				}
			}

			.search-loading {
				display: none;

				p {
					font-weight: 500;
					color: $color-sea-blue-900;
					margin-bottom: 0;

					.search-loading__icon {
						display: inline-block;
						vertical-align: text-bottom;
						width: 20px;
						min-width: 20px;
						height: 20px;
						margin-left: 10px;
						background: url(../assets/images/spinner.svg) center center / contain no-repeat;
						animation: rotation .3s linear infinite;
					}
				}
			}

			.search-list {
				display: none;

				&__wrapper {
					display: flex;
					flex-direction: column;
					gap: 16px;
					padding-top: 32px;

					.search-item {
						display: block;
						width: 100%;
						padding: 8px;
						text-decoration: none;
						transition: .1s ease-in-out;
						background-color: #f4f4f4;

						&--blue {
							background-color: $color-sea-blue-50;

							&:hover,
							&:focus-visible {
								background-color: $color-sea-blue-100;
							}
						}

						&--green {
							background-color: $color-green-50;

							&:hover,
							&:focus-visible {
								background-color: $color-green-100;
							}
						}

						&__top {
							display: flex;
							align-items: center;

							.search-icon {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 40px;
								min-width: 40px;
								height: 40px;
								
								img,
								svg {
									display: block;
									width: 100%;
									height: 100%;
									object-fit: contain;
									margin: 0;
									padding: 0;
								}
							}

							.search-item-infos {
								padding-left: 8px;

								&__top {
									display: block;
									font-size: 14px;
									line-height: 22px;
									color: $color-sea-blue-900;
									font-weight: 600;
									padding: 0;
									margin: 0;

									@media #{$medium-up} {
										font-size: 16px;
									}

									em {
										font-style: normal;
									}
								}

								&__bottom {
									display: block;
									font-size: 14px;
									line-height: 22px;
									color: $color-sea-blue-900;
									font-weight: 400;
									padding: 0;
									margin: 0;

									@media #{$medium-up} {
										font-size: 16px;
									}

									em {
										font-style: normal;
									}
								}
							}
						}

						&__bottom {
							padding: 8px 0 0 8px;

							p {
								display: block;
								font-size: 18px;
								line-height: 27px;
								font-weight: 600;
								color: $color-sea-blue-900;
								padding: 0;
								margin: 0;

								@media #{$medium-up} {
									font-size: 24px;
									line-height: 36px;
								}

								em {
									font-style: normal;
								}
							}
						}
					}

					.search-all {
						margin: 8px 0 10px 0;
						font-weight: 600;
						width: fit-content;
					}

				}
			}
		}
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}