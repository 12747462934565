/****
***** Mobile main nav
****/

.mobile-main-nav-container{
	padding-bottom: $spacing-large;
	background-color: $color-blue-dark;
    margin-left: -$spacing-large;
    margin-right: -$spacing-large;
	display: none;

	&.active{
		display: block;
	}

	// width: 100%;

	@media #{$medium-up} {
		margin-left: 0;
		margin-right: 0;
		width: 300px;
		position: absolute;
		right: 0;
		z-index: 99;
	}
	// @media #{$large-up} {
	// 	width: auto;
	// 	position: initial;
	// }

	// @media #{$medium-up} {
	// 	float: right;
	// 	position: relative;
	// }
	@media #{$large-up} {
		float: none;
		display: none !important;
		&.active{
			display: none;
		}
	}

	@media print{
		display: none;
	}
}

.hamburger{
	position: absolute;
	right: $spacing-large;

	background-color: transparent;
	background: url(../assets/images/hamburger.svg) 50% 50% no-repeat;
	width: 30px;
    height: 30px;
    top: 20px;
    margin: 0;
    display: block;
    padding: 0;
	
	@media #{$medium-up} {
		top: 30px;
	}


	&:hover,
	&:focus{
		background-color: transparent;
	}
	&.active{
		background: url(../assets/images/hamburger-hover.svg) 50% 50% no-repeat;
	}
	@media #{$large-up} {
		display: none;
	}

	@media print{
		display: none;
	}
}




ul.mobile-main-nav{
	margin: 0;

	li{
		display: block;
		
		padding: 0 $spacing-large;
		a{
			color: $color-white;
			padding: $spacing-medium 0;
			display: block;
			
			border-bottom: 1px solid $color-sea-green;
			font-size: 14px;

			font-family: $body-font-family;
			
			text-decoration: none;
			font-size: 14px;
			// line-height: 1.5;
			// padding: $spacing-medium 35px $spacing-medium $spacing-medium;
			


			&.main-nav-arrow{
				padding-right: $spacing-large;
				&:after{
					content: "";
					background-size: 16px 16px;
					background-image: url(../assets/images/arrow-blue-green.svg);
					width: 16px;
					height: 16px;
					position: absolute;
					right: $spacing-large;
					transform: rotate(-270deg);
				}
				&.active:after{
					transform: rotate(-90deg);	
				}
			}

		}
		&:last-child a.main-nav-arrow:after{
			border-bottom: none;
		}

	}
}

ul.mobile-main-nav-first{
	@include cf();
	margin-bottom: $spacing-large;
	> li{
		@media #{$large-up} {
			float: left;
		}

		> a{
			text-transform: uppercase;	
		}
		// @media #{$medium-up} {
		// 	float: left;
		// }
	}
}

ul.mobile-main-nav-second{
	margin: 0;

	display: none;
	&.active{
		display: block;
	}

	@media #{$large-up} {
		position: absolute;
    	left: 0;
	}
	

	> li{
		padding-right: 0;
		> a{
			// padding-left: $spacing-large;
			text-transform: none;
			border-bottom: 1px solid #1b3a48;	
		}
		
	}
}

ul.mobile-main-nav.mobile-support-nav{
	// @media #{$large-up} {
	// 	display: none;
	// }

	> li{
		line-height: 1;
		> a{
			// background-color: $color-gray-warm-2;

			color: $color-white;
			// border-color: $color-black;
			border: none;
			text-decoration: underline;
			font-family: "Arial";
		}
	}
}

/*****************
## Body overlay ##
*****************/

.body-overlay{
	content: '';
    position: absolute;
    top: 73px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgba(0,0,0,0);
    visibility: hidden;
    opacity: 0;
    transition: all .1s ease-out;
    background: rgba(0,0,0,0);

    @media #{$medium-only} {
    	body.body-overlay-active &{
	    	z-index: 1;
	    	opacity: 1;
	    	background: rgba(0,0,0,.5);
	    	visibility: visible;
	    }
	}
	
	@media #{$medium-up} {
		body.mobile-view-only.body-overlay-active &{
			z-index: 1;
			opacity: 1;
			background: rgba(0,0,0,.5);
			visibility: visible;
		}
	}
}

.body-overlay-active{
	.content-container,
	footer{
		overflow: hidden;
	}
}

/*************
** Mobile view only **
**************/
.mobile-view-only{
	.mobile-main-nav-container{
		@media #{$large-up} {
			float: none;
			display: none;
			&.active{
				display: block !important;
			}
		}
		ul.mobile-main-nav-first{
			> li{
				@media #{$large-up} {
					float: none;
				}
			}
		}
		ul.mobile-main-nav-second{
			@media #{$large-up} {
				position: relative;
			}
		}
	}

	.hamburger{
		@media #{$large-up} {
			display: block !important;
		}
	}	
}