.button {
  display: inline-block;
  margin-right: $spacing-small;
  margin-bottom: $spacing-small;
  padding: 11px $spacing-large 11px;
  border: 1px solid $color-blue-dark;
  background-color: $color-blue-dark;
  color: $color-white;
  font-family: $button-font-family;
  font-size: $button-font-size-base;
  font-weight: 400;
  line-height: 1.1;

  &:focus,
  &:active,
  &:hover {
    background-color: $color-white;
    color: $color-blue-dark;
  }

  &.button-small {
    font-size: $button-font-size-small;
    line-height: 1.38;
    padding: 5px $spacing-large;
  }

  &.button-full-width {
    margin: 0;
    width: 100%;
  }

  &.button-minimum {
    min-width: 80px;
  }

  @at-root {
    a#{&} {
      span {
        position: relative;
        top: 3px;
      }
    }
  }

  &-alternate,
  &-secondary {
    background-color: $color-white;
    color: $color-blue-dark;

    &:focus,
    &:active,
    &:hover {
      color: $color-white;
      background-color: $color-blue-dark;
    }
  }

  &-tertiary {
    border: 1px solid $color-gray-warm-2;
    background-color: $color-white;
    color: $color-blue-dark;
    font-weight: normal;

    &[disabled],
    &[disbaled]:hover,
    &[disbaled]:focus {
      background-color: $color-gray-warm-3;
      border-color: $color-gray-warm-3;
      color: $color-gray-warm;
    }
  }

  &-clean {
    @extend .link-single;
    border-color: transparent;
    margin-left: $spacing-large;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: $color-black;
    font-weight: normal;
    font-size: $font-size-mini;
  }

  &-arrow {
    &-left,
    &-right,
    &-down {
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        width: 45px;
        background: transparent none no-repeat scroll 50% 50%;
        background-size: 10px;
      }

      // Arrows
      &:after {
        background-image: url("../assets/images/arrow-sea-white.svg");
      }

      &.button-secondary:after,
      &.button-tertiary:after,
      &.button-alternate:after {
        background-image: url("../assets/images/arrow-dark-blue.svg");
      }

      &.colored-button:after {
        background-image: url("../assets/images/arrow-dark-blue.svg");
      }

      &:hover {
        &:after {
          background-image: url("../assets/images/arrow-dark-blue.svg");
        }

        &.button-secondary:after,
        &.button-alternate:after {
          background-image: url("../assets/images/arrow-sea-white.svg");
        }
      }
    }

    &-left,
    &-down {
      padding-left: 46px;

      &:after {
        left: 0;
        transform: rotate(180deg);
      }

      &.button-small {
        line-height: 1;
        font-size: $button-font-size-small;
        padding-left: 38px;
      }
    }

    &-right {
      padding-right: 46px;
      &:after {
        right: 0;
      }

      &.button-small {
        line-height: 1;
        font-size: $button-font-size-small;
        padding-right: 38px;
      }
    }

    &-down {
      &:after {
        transform: rotate(-90deg);
      }
    }
  }
}
