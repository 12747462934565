.subscription-block {
	background-color: $color-gray-warm-3;
	padding: $spacing-large;

	@media #{$medium-up} {
		padding: $spacing-large + $spacing-large;
	}

	.button {
		margin: $spacing-small 0;
		padding-left: $spacing-large + $spacing-medium;
		padding-right: $spacing-large + $spacing-medium;
		font-weight: 400;

		span {
			top: 0;
		}

		&:hover {
			background-color: white;
		}
	}
}