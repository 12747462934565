$label-left-padding: 30px;

.radio-button {
  @include hide();

  &:focus + .radio-button-label::after {
    border-color: black;
  }

  &:checked + .radio-button-label:before {
    background-color: $color-blue-dark;
  }

  &:disabled + .radio-button-label:after {
    opacity: 0.5;
  }

  &-wrapper {
    @include clearfix;
    margin-bottom: $spacing-small;
  }

  &-label {
    cursor: pointer;
    display: inline-block;
    padding-left: $label-left-padding;
    position: relative;
    margin-left: 0;
    margin-right: $spacing-large;
    font-size: 18px;

    .hearings-send-answer-form & {
      @extend .h2;
    }

    &:before,
    &:after {
      border-radius: 50%;
      content: " ";
      display: block;
      position: absolute;
    }

    &:before {
      height: 10px;
      left: 5px;
      // top: 5px;
      width: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }

    &:after {
      border: 1px solid $color-warm-300;
      background: #fff;
      height: 20px;
      left: 0;
      // top: 0;
      width: 20px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  &-info-text {
    padding-left: $label-left-padding;
    margin: 0 !important;
  }
}
