.link-list {
  list-style: none;
  margin: 0;
  margin-bottom: $spacing-medium;
  padding: $spacing-medium 0;

  li {
    position: relative;
    padding: $spacing-small 0 $spacing-medium;
    padding-left: calc(#{$spacing-large} + #{$spacing-medium});

    &::before {
      content: "";
      position: absolute;
      left: 5px;
      top: 14px;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid $color-text-gray;
      border-right: 2px solid $color-text-gray;
      transform: translate(-50%, 0) rotate(-45deg);
    }

    a {
      color: $color-text-gray;
      font-size: 20px;
      line-height: 22px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

    }
  }
}
