.slider-header-container{
	margin-bottom: $spacing-large;
}

.slider-container{
	position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

.slider-title{
    @extend .header-underline;
    @include block-header();

}

.slick:not(.slick-initialized) > div{
    display: none;

    &:first-child{
        display: block;
    }
}

.slick-prev,
.slick-next{
	position: absolute;
    z-index: 1;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
    height: 40px;
    width: 40px;
    display: block;
    padding: 0;
    top: 24vw;
    transform: translateY(-50%);

    @media #{$medium-up} {
        top: 19vw;
        background-size: 20px;
        height: 60px;
        width: 60px;
    }

    @media #{$xlarge-up} {
        top: 230px;
    }
    
    &:hover,
    &:focus{
    	background-color: transparent;
    	outline: transparent;
    }
}
 
.slick-prev{
    background-image: url(../assets/images/carousel-left.svg);
    background-position: left center;
    transform: translateY(-50%);
    left: $spacing-medium;
    @media #{$medium-up} {
        left: 0;
    }


}

.slick-next{
    background-image: url(../assets/images/carousel-right.svg);
    background-position: right center;
    right: $spacing-medium;
    @media #{$medium-up} {
        right: 0;
    }
}

.slick .image-container{
    @media #{$medium-up} {
        width: 80%;
        margin: 0 auto;
    }
}

.slick-slide,
.slick-track{
    &:focus{
        outline: none;
    }
}

.slider-image-caption{
    @extend .article-image-caption;

    @media #{$medium-up} {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

// Dots

ul.slick-dots{
    position: absolute;
    transform: translateX(-50%);
    top: $spacing-large;
    right: 0;
    @media #{$medium-up} {
        right: 10%;    
    }

    > li{
        list-style: none;
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        // margin: 0 5px;
        padding: 0;
        cursor: pointer;

        > button{
            // font-size: 0;
            // line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            &:before{
                position: absolute;
                top: 5px;
                left: 5px;
                width: 10px;
                height: 10px;
                content: " ";
                text-align: center;
                background-color: $color-gray-warm-3;
                // -webkit-font-smoothing: antialiased;
                color: #000;
                display: block;
                border-radius: 100%;
            }
       
        }
        &.slick-active{
            > button:before{
                background-color: $color-blue-light;
            }
        }
    }
}



.in-article{
    margin-bottom: 30px;
    
    .image-container{
        @media #{$medium-up} {
            width: 100%;
            margin: 0 auto;
        }
    }

    img{
        display: block;
        width: 100%;
    }

    .slider-image-caption .credit{
        font-weight: bold;
    }

    &:hover .slick-button{
        transition: opacity .6s;
        opacity: 1;
    }
    .slick-button{
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        margin: 0;
        background-position: 50% 50%; 
        background-size: 12px;
        opacity: 0;
        transition: opacity .6s;
        visibility: hidden;

        @media #{$large-up} {
            opacity: 0;
            background-size: 24px 40px; 
            visibility: visible;
            width: 80px;
            height: 80px;
        }

        &.slick-next{
            right: 20px;
            background-position: 17px 50%;
            background-image: url(../assets/images/carousel-right-white.svg);
            
            @media #{$large-up} {
                right: 40px;
                background-position: 30px 50%;
            }
        }

        &.slick-prev{
            left: 20px;
            background-image: url(../assets/images/carousel-left-white.svg);

            @media #{$large-up} {
                left: 40px;
            }
        }
    }

    .slider-image-caption{
        width: 100%;
    }

    ul.slick-dots{
        position: absolute;
        transform: translateX(0);
        top: auto;
        right: 0px;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0 0 10px;
        font-size: 0;
        
        @media #{$large-up} {
            padding: 0 0 30px;       
        }

        & > li {
            display: inline-block;
            margin: 0 5px 5px;
            width: 17px;
            height: 17px;
        }

        & > li > button{
            margin: 0;
            width: 17px;
            height: 17px;

            &:before{
                width: 17px;
                height: 17px;
                top:0;
                left:0;
            }
        }

        & > li.slick-active > button:before{
            background: #f7bc4e;
        }
    }
}
