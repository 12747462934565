.boxed-block {
	&--heading {
		@extend .h1;
		padding: $spacing-large 15px;

		&.top-spacing {
			margin-top: $spacing-large + $spacing-large;
			margin-bottom: $spacing-medium;
		}
	}

	&--more {
		display: block;
		width: fit-content;
		margin: $spacing-large 0;
		margin-left: auto;
	}

	&--title {
		h1{
			@extend .h1;
			max-width: 890px;
			margin-top: $spacing-large;
			margin-bottom: $spacing-xlarge;
			color: $color-blue-dark;
			font-weight: 400;
			font-size: 28px;
			@media #{$large-up} {
				font-size: $font-size-large;
				line-height: 1.58;
			  }
	
			strong {
				font-weight: 500;
			}
	
			@media #{$medium-up} {
				margin-top: $spacing-xlarge;
				margin-bottom: $spacing-xxlarge;
				font-size: 35px;
			}
		}
	}

	&--background {
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		width: 100vw;
		left: 50%;
		transform: translateX(-50%);
		//background-color: $color-gray-warm-3;

		&-container {
			position: relative;
			margin-top: $spacing-large + $spacing-large;
			padding: 0 15px;

			@media #{$medium-up} {
				padding: 16px 0 0 0;
			}

			.boxed-image {
				&.small,
				&.medium,
				&.large {
					.boxed-image-content {
						padding: 0;
					}

					.boxed-image-image {
						height: auto;

						.centred {
							top: 0;
							transform: none;
						}
					}

					.boxed-image-text {
						padding: $spacing-large 0;
					}
				}

				&:last-child {
					padding-bottom: 0;

					.boxed-image-text {
						padding-bottom: 0;
					}
				}

				&-title {
					margin: $spacing-medium 0;
					font-size: 18px;
					line-height: 27px;

					@media #{$medium-up} {
						font-size: 24px;
						line-height: 36px;
					}
				}

				p {
					margin: 0;
					color: $color-sea-blue-900;
				}

				@media #{$medium-up} {
					width: calc(100% / 2);

					&.small,
					&.medium,
					&.large {
						display: inline-block;
						width: calc(100% / 2);

						.boxed-image-image {
							height: auto;
							aspect-ratio: 16 / 9.6;

							.centred {
								position: relative;
								top: 50%;
								transform: translateY(-50%);
								text-align: center;
							}
						}
					}
				}

				@media #{$large-up} {
					width: calc(100% / 3);

					&.small,
					&.medium,
					&.large {
						width: calc(100% / 3);
					}
				}

				a {
					background-color: transparent;
					border-color: transparent;
				}
			}
		}
	}

	&.light-blue {
		.boxed-block--background{
			background-color: $color-sea-blue-50;
		}
	}
  
	&.blue {
		.boxed-block--background{
			background-color: $color-sea-blue-900;
		}

		* {
			color: $color-white;
			border-bottom-color: $color-white;
		}
	}
  
	&.yellow {
		.boxed-block--background{
			background-color: $color-orange-50;
		}
	}

	&.light-green {
		.boxed-block--background{
			background-color: $color-green-50;
		}
	}
}