/*********
## Core ##
*********/
@page {
  margin: 20mm 10mm;
  size: A4;
}

html,
body {
  font-size: 100%;
  // height: 100%;

  @media print {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

body {
  font-family: $body-font-family;
  font-weight: 400;
  line-height: 1.4;
  font-size: $font-size-base-mob;
  @media #{$large-up} {
    font-size: $font-size-base;
  }

  //EpiServer fix
  > form {
    margin: 0;
  }

  @media print {
    font-family: $print-font-family;

    * {
      float: none !important;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

main.menu-open {
  display: none;
}

/************
## Headers ##
************/

h4,
.h4 {
  @include h3();
}

.big-header {
  @include big-header();

  @media print {
    font-weight: 600;
    margin-bottom: 18px;

    .print-paragraph & {
      color: #5171aa;
      text-transform: uppercase;
      font-family: $print-font-family;
      font-size: 15.5px;
      line-height: 1.4em;
      margin: 15px 0 0;
      font-weight: 400;
    }

    .print-intro & {
      margin-top: 18px;
    }
  }
}

.uppercase-header {
  text-transform: uppercase;
}

.header-bold {
  font-family: $body-font-family;
  font-weight: 500;
  // text-transform: uppercase;
}

.header-underline {
  padding-bottom: $spacing-medium;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
}

.header-margin-large {
  margin-bottom: $spacing-large;
}

/**********
** TYPO **
**********/

.paragraph {
  @extend .body-text;

  &-ingress {
    font-family: $body-font-family;
    font-size: 20px;
    font-weight: normal;
    @media #{$large-up} {
      font-size: 22px;
    }
  }

  &-small {
    font-size: $font-size-mini;
    font-weight: normal;
    @media #{$large-up} {
      font-size: $font-size-small;
    }
  }

  &-smaller {
    @include paragraph-smaller();
  }

  &-light {
    font-size: 11px;
    color: $color-blue-dark;
    margin-bottom: 0;
  }

  &-image-caption {
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    @media only screen and (min-width: 40.0625em) {
      font-size: 16px;
      line-height: 1.5;
  }
  }

  &-styleguide {
    font-family: Arial;
    font-size: 15px;
    line-height: 20px;
  }
  &-no-spacing {
    margin: 0;
  }
}

strong,
.bold {
  font-weight: bold;
}

.skip-link {
  display: block;
  position: absolute;
  top: 0;
  left: -999em;
  z-index: 1000;
  width: 100%;
  margin: 0;
  padding: $spacing-medium;
  text-align: center;
  line-height: 1.4;
  color: #fff !important;
  background-color: $color-blue-dark;
  border: none;
  &:focus {
    right: 0;
    left: 0;
    outline: none;
  }
}

/**********
** LINKS **
**********/

.link-single {
  @extend .link;
  display: inline-block;
  font-weight: normal;
  margin-bottom: $spacing-large;

  ul + & {
    margin-top: $spacing-medium;
  }

  ul > li & {
    margin-bottom: 0;
  }

  // &.bigger{
  // 	font-size: 16px;
  // }
}

.link-single-arrow {
  display: block;
  // margin-bottom: $spacing-large;
  @extend .icon-before;
  @extend .icon-small;
  @extend .icon-arrow;
  text-decoration: none;
  // color: $color-black;
  &:hover {
    text-decoration: underline;
    color: $color-sea-green;
  }
}

.link-clear {
  color: $color-black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/**********
** IMAGES **
**********/

.image-container {
  > img {
    width: 100%;
    height: auto;
  }
}

/************
## Global ##
************/

.content {
  max-width: $max-content-width;
  margin: 0 auto;

  @media #{$large-up} {
    margin: 0 $spacing-large;
  }
  @media #{$xlarge-up} {
    margin: 0 auto;
  }

  @media print {
    font-size: $font-size-print;

    p {
      margin: 0 0 1em;
    }
  }

  > .row,
  > .section > .row {
    max-width: 100%;
  }
}

.main-content {
  position: relative;
  padding: 0 $spacing-small;

  @media #{$medium-up} {
    padding: 0 $spacing-large;
  }

  @media print {
    padding: 35mm 10px 0;
    margin: 0;
    max-width: none;
    overflow: hidden;

    .row {
      max-width: none;
    }

    .small-centered.columns,
    .row {
      padding: 0;
    }

    > .section {
      margin: 0;
    }

    .print-chapter-content,
    .print-document-content {
      padding: 0 10mm 0 17mm;
    }
  }
}

.section {
  margin-bottom: $spacing-large;

  &-big {
    margin-bottom: $spacing-large * 2;

    @media print {
      margin-bottom: $spacing-large;
    }
  }

  &-transport {
    @extend .section;

    @media #{$large-up} {
      margin-left: 100px;
    }
  }
}

.page-header-container {
  @extend .section-big;
}

.section-big {
  @media print {
    margin-bottom: 1em;

    *:last-child {
      margin-bottom: 0;
    }
  }
}

.section-element {
  @extend .spacing-xlarge-bottom;
  @include cf();
  &.border-bottom {
    border-bottom: 1px solid $color-gray-warm-3;
  }
}

.no-margin-top {
  margin-top: 0;
}

.no-padding {
  padding: 0;
}

.left {
  float: left;
}

.right {
  float: right;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.cf {
  @include cf();
}

hr {
  margin-bottom: $spacing-large;
  margin-top: $spacing-large;
  border: none;
  border-bottom: 1px solid $color-gray-warm-2;
}

sup,
sub {
  font-size: 0.6em;
  line-height: 0.1;
}

.block-container {
  @include cf();
  max-width: $max-content-width;
  margin: 0;
  padding: 0;
  margin-left: -$spacing-medium;
  margin-right: -$spacing-medium;

  > div {
    padding: $spacing-medium;

    // @media #{$large-up} {
    //   padding: 1.6%;
    //   //   padding: $spacing-large;
    // }

    // &.boxed-standard-link {
    //   margin-bottom: 40px;
    // }
  }
}

.content-container {
  padding: $spacing-small;

  @media #{$medium-up} {
    padding: $spacing-large;
  }
}

.right-margin {
  margin-right: $spacing-medium;
  display: inline-block;

  @media #{$medium-big-up} {
    margin-right: $spacing-xlarge;
  }
}
.bottom-margin {
  margin-bottom: $spacing-medium;

  @media #{$medium-big-up} {
    margin-bottom: $spacing-xlarge;
  }
}

.half-width {
  @media #{$large-up} {
    width: 50%;
  }
}
/**********
## Forms ##
**********/

.form-row {
  @include cf();
  margin-bottom: 25px;

  &.bottom-margin {
    @extend .bottom-margin;
  }
}

.form-group {
  label,
  input,
  .button {
    display: inline-block;
  }
}

label > input,
label > textarea,
label > .select-wrapper {
  margin-top: 6px;

  &.no-margin {
    margin-top: 0px;
  }
}

textarea {
  padding: $spacing-medium;
}

.main-label {
  margin-bottom: 8px;
  font-weight: normal;
  font-style: 16px;
  @media #{$large-up} {
    font-size: 18px;
  }
}
.checkbox-label {
  font-style: 16px;
  @media #{$large-up} {
    font-size: 18px;
  }

}

input:not([type]),
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
  height: 48px;
  padding: $spacing-medium;
}

/***********
## Tables ##
***********/

article table {
  margin-bottom: $spacing-large * 2;
  margin-top: $spacing-large * 2;
  border-collapse: collapse;
  // font-size: 16px;

  @media print {
    font-size: 13px;
    border: none;
    margin: 10px 0 30px;
    width: 100%;
  }

  // overwrite article p
  p {
    margin: 0 !important;
    line-height: 1.4 !important;

    @media print {
      font-size: 13px !important;
    }
  }

  th,
  thead td {
    font-weight: bold;
    i {
      font-style: normal;
    }
  }

  // fix for print
  thead {
    display: table-row-group;
  }

  // border-spacing: 1px;
  // border-collapse: separate;
  // tr{
  // 	background-color: $color-gray-warm-2;

  // }
  // td{
  // 	padding: $spacing-small;
  // 	    text-align: right;
  // }

  td,
  th {
    border: 1px solid $color-blue-dark;
    padding: $spacing-small $spacing-medium;
    text-align: left;
    padding: 12px 10px;
    // font-size: 16px;
    vertical-align: bottom;

    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }

    @media print {
      // font-size: 13px;
      vertical-align: top;
    }
  }

  ul:last-child,
  ol:last-child,
  ul.list-links:last-child {
    margin-bottom: 0;
  }
}

.table-help-text {
  @extend .link-single;
  margin-top: $spacing-large;
  margin-bottom: $spacing-large;
}

.table-wrapper {
  position: relative;
  // margin: 0.5em 0 1.5em;
  overflow: visible;
  z-index: 1;

  &.not-minified {
    margin-bottom: $spacing-large + $spacing-medium;

    table {
      // border: 1px solid $brand-primary;
      // border: 1px solid $gray-lighter;
      // padding: 10px;
      // background-color: #fff;
      display: table;
      margin-top: 0;
      margin-bottom: $spacing-large;
    }
  }

  &.minified {
    overflow-x: scroll;
    margin-bottom: $spacing-large + $spacing-medium;
    table {
      padding: 0;
      border: none;
      margin-top: 0;
      margin-bottom: $spacing-large;
    }
  }
}

// .table-simple{
// 	border-collapse: collapse;

// 	td, th{
// 		border: 1px solid $color-blue-dark;
// 		padding: $spacing-small $spacing-medium;

// 		&:first-child{
// 			border-left: none;
// 		}
// 		&:last-child{
// 			border-right: none;
// 		}
// 	}
// }

/************
## Article ##
************/

.article:not(body) {
  padding-bottom: $spacing-large;
  border-bottom: 1px solid $color-gray-warm-2;
  margin-bottom: $spacing-large;

  p:not(.paragraph-ingress) {
    @extend .paragraph;
  }

  h2,
  .h2 {
    + table {
      margin-top: $spacing-large;
    }
  }

  .h3,
  h3 {
    margin-bottom: $spacing-small;
    padding-top: $spacing-medium;
    &:first-of-type {
      padding-top: 0;
    }
    + table {
      margin-top: $spacing-large;
    }
  }
  .h4,
  h4 {
    margin-bottom: 3px;
    padding-top: $spacing-medium;
    &:first-of-type {
      padding-top: 0;
    }
    + table {
      margin-top: $spacing-large;
    }
  }

  &.no-border {
    //Used when feedback module is active.
    border-bottom: none;
  }

  .list-links li {
    margin: 0;

    > a:before {
      left: 5px;
    }
  }
}

/***************
## Styleguide ##
***************/

.styleguide-section-header {
  @extend .spacing-large-bottom;
  @extend .big-header;
}

.code-snippet {
  margin-bottom: $spacing-large;
}

code,
.code {
  background-color: $color-gray-warm-2;
  padding: $spacing-small;
}

/***********
## Search ##
***********/
.searchword {
  background: #ffe16e;
  font-weight: bold;
}

/**** To be removed when search field is updated according to mockup - REMOVE ****/
.quickSearchFieldContainer {
  .quickSearchButton {
    margin-top: 10px;
  }
}

/**************
## EPI HACKS ##
**************/
.accordion-navigation {
  .epi-editContainer .paragraph {
    padding-bottom: 15px;
  }
}

/***********
## PRINT ##
**********/
@media print {
  //Rules specific
  .rules {
    .rules-tabs-target.rules-tabs-nav {
      display: none;
    }

    .article-navigation {
      display: none;
    }

    .rules-tabs-triggers {
      display: none;
    }

    .rules-tabs-content {
      display: block;
    }
    .rules-content {
      .accordion .accordion-navigation > .content {
        display: block;
        border: 2px solid $color-gray-warm;
        font-size: 14px !important;
      }
    }
    table td,
    table th {
      font-size: 14px !important;
    }
  }

  ul li > p,
  .content ul li > p {
    margin: 0;
  }
}

/*use "todo" class to show blocks which haven't done*/
.todo {
  position: relative;
  overflow: hidden;
  min-height: 20px;

  &:before {
    content: " ";
    display: block;
    z-index: 998;
    background: rgba(255, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border: 1px solid red;
  }

  &:after {
    content: "TODO";
    display: block;
    z-index: 999;
    background: #fff;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 2px 4px;
    color: red;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    border: 1px solid red;
  }
}

.mobile-view-only {
  header,
  main {
    .content {
      max-width: 100%;
      margin: 0;
      font-size: 18px;
      padding: 0;
      @media #{$large-up} {
        margin: 0;
      }
      @media #{$xlarge-up} {
        margin: 0;
      }
      .row {
        max-width: 100%;
      }
    }
  }
}
.guidepage-only {
  label {
    &::before,
    &::after {
      box-sizing: content-box;
    }
  }
  a {
    &::after {
      box-sizing: content-box;
    }
  }
  .breadcrumb {
    display: none;
  }
  select {
    height: auto;
  }

  input:not([type]),
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="week"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="color"],
  textarea {
    height: 46px;
    width: auto;
    display: inline-block;
    outline: initial;

    &:hover,
    &:focus {
      border-color: #072938;
    }
  }

  .header {
    @media #{$large-up} {
      padding-bottom: $spacing-large * 2;
    }

    input[type="text"] {
      height: auto;
      min-height: 100%;
      width: 100%;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.veivisere,
.block {
  table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    text-align: left;

    > tr {
      vertical-align: middle;
    }

    th,
    td {
      border: 1px solid $color-gray-input-border;
      padding: 10px;
    }
  }
}

.block table {
  text-align: center;
  th {
    font-weight: inherit;
  }
}


main#main {
  position: relative;
  overflow-x: clip;
}