.big-news-area{
	background-color: $color-gray-warm-3;
    padding-top: 50px;
    padding-bottom: $spacing-large*2;
    margin-bottom: $spacing-large *2;
}

ul.big-news-container{
	margin: 0;
	padding: 0;
	@include cf();
	margin-left: -$spacing-medium;
	margin-right: -$spacing-medium;
	margin-top: -$spacing-medium;
}

li.big-news{
	list-style: none;
	padding: $spacing-medium;
	
	@media #{$medium-up} {
		width: 33.3333%;
		float: left;
	}

	a{	
		height: 100%;
		@include cf();
		display: block;
		text-decoration: none;
		background-color: #fff;
		&:hover,
		&:focus{
			transition: background-color 0.3s;
			background-color: darken(#fff, 2%);
		}
	}

}

.big-news-image{
	@include image-link-hover();
}

.big-news-text{
	padding: $spacing-large;
	color: $color-black;
	text-overflow: ellipsis;
	overflow: hidden;
	@extend .h2;
	@media #{$large-up} {
		padding: $spacing-large + $spacing-medium;
	}
}