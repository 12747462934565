.select {
  cursor: pointer;
  appearance: none;
  z-index: 1;
  width: 100%;
  margin-bottom: $spacing-small;
  border: 1px solid $color-gray-input-border;
  border-radius: 0;
  background: $color-white;
  padding: 8px 10px;
  padding-right: $spacing-xlarge;
  color: $color-text-gray;

  &:focus {
    outline: 0;
    border-color: $color-blue-dark;
    box-shadow: 0px 0px 2px 0px $color-blue-dark;
    background-color: white;
  }
  &.error {
    border-color: $color-error;
  }
}

.select-wrapper {
  position: relative;
  z-index: 0;

  &:before {
    position: absolute;
    display: block;
    content: "";
    @extend .icon-before;
    @extend .icon-small;
    pointer-events: none;
    top: 50%;
    right: 10px;
    height: 12px !important;
    width: 12px !important;
    margin-right: 0;
    transform: translateY(-50%) rotate(90deg);
    background-image: url(../assets/images/arrow-dark-black.svg);
    background-size: 10px 10px;
    background-position: right !important;
  }
}

select::-ms-expand {
  display: none;
}
