.list-hearings{
    .section{
        margin: 0;
    }

    .wrapper{
        // padding-top: 40px;
        margin-bottom: 120px;
        // border-top: 1px solid $color-warm-300;

        @media #{$small-only} {
            margin-bottom: 52px;
        }
    }

    &__title{
        margin-bottom: 60px;
        color: $color-sea-blue-900;
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;

        @media #{$small-only} {
            margin-bottom: 40px;
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__list{
        margin: 0;
    }

    &__card{
        margin-bottom: 40px;
        list-style: none;
        
        &:last-child{
            margin-bottom: 0;
        }

        @media #{$small-only} {
            margin-bottom: 28px;
        }

        .card-content{
            display: block;
            padding: 56px 80px;
            text-decoration: none;

            @media #{$small-only} {
                padding: 32px 24px;
            }
        }

        .card-info{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 48px;
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid;

            @media #{$small-only} {
                gap: 32px;
                margin-bottom: 18px;
                padding-bottom: 18px;
            }

            .info-item{
                p{
                    margin: 0;
                    color: $color-sea-blue-900;
                    font-size: 16px;
                    line-height: 22px;

                    &.info-label{
                        font-weight: 600;
                    }
                }
            }
        }

        .card-answers{
            min-width: 93px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            padding: 10px 4px;

            @media #{$small-only} {
                padding: 8px 4px;
            }
            
            p{
                margin: 0;
                font-size: 16px;
                line-height: 22px;
                color: $color-sea-blue-900;
                font-weight: 600;

                @media #{$small-only} {
                    font-size: 14px;
                }
            }
        }

        .card-title{
            color: $color-sea-blue-900;
            font-size: 24px;
            line-height: 36px;
            font-style: normal;
            font-weight: 600;

            @media #{$small-only} {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }

    &__show{
        display: flex;
        align-items: center;
        margin: 48px 0 0;
        padding: 13px 16px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;

        &:hover, &:focus{
            &::before{
                background-image: url('../assets/images/plus-white.svg');
            }
        }

        &::before{
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            background-image: url('../assets/images/plus.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-right: 13px;
        }
    }

    &--closed{
        .list-hearings__card{
            margin-bottom: 30px;

            @media #{$small-only} {
                margin-bottom: 28px;
            }

            .card-content{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 32px 24px;
                
                @media #{$large-up} {
                    padding: 40px 80px;
                }

                @media #{$small-only} {
                    flex-wrap: wrap;
                }

                .card-info{
                    margin-right: 40px;
                    margin-bottom: 0px;
                    padding-bottom: 0;
                    border: none;

                    @media #{$small-only} {
                        margin-right: 24px;
                    }
                }

                .card-answers{
                    margin-right: 74px;

                    @media #{$small-only} {
                        margin-right: 0;
                        margin-left: auto;
                    }
                }

                .card-title{
                    font-size: 20px;
                    line-height: 30px;

                    @media #{$small-only} {
                        width: 100%;
                        margin-top: 24px;
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
            }
        }
    }

    &--green{
        .list-hearings__card{
            .card-content{
                background-color: $color-green-70;

                .card-info{
                    border-bottom-color: #B4D19B;
                }
            }
        }
    }

    &--yellow{
        .list-hearings__card{
            .card-content{
                background-color: $color-orange-80;

                .card-info{
                    border-bottom-color: #DCC875;
                }

                .card-answers{
                    background-color: #F7E495;
                }
            }
        }
    }

    &--blue{
        .list-hearings__card{
            .card-content{
                background-color: $color-sea-blue-50;

                .card-info{
                    border-bottom-color: $color-sea-blue-300;
                }

                .card-answers{
                    background-color: $color-sea-blue-100;
                }
            }
        }
    }
}