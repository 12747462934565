.list-rules-history {
  @extend .list-big;
  padding-bottom: $spacing-large;
  // font-size: $font-size-rules;

  @media print {
    font-size: $font-size-print;
  }
}

.list-rules-history-link {
  @extend .link;
  @extend .link-single-arrow;
  // font-size: $font-size-rules;
  margin-top: $spacing-small;
}

// Old markup for EpiServer
.list-rules-history {
  > li {
    .bold,
    strong,
    b {
      font-weight: normal;
      display: block;
    }

    > a {
      @extend .list-rules-history-link;
    }
  }
}
