.styleguide-spacer {
  height: 100px;
}

.sticky-nav {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.styleguide-top {
  width: 100%;
  background-color: $color-gray-warm-3;
  @include cf();
  .big-header {
    float: left;
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
}

.styleguide-title {
  float: left;
  font-family: $body-font-family;
	font-weight: 200;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-black;
  font-size: 18px;
  //line-height: 1.5;
  line-height: $spacing-large * 2;
}

.styleguide-nav-trigger {
  float: right;
  padding: 0;
  padding-right: 35px;
  font-family: $body-font-family;
	font-weight: 200;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-black;
  font-size: 18px;
  line-height: 1.5;
  // padding: 10px 15px;
  display: block;
  position: relative;
  line-height: $spacing-large * 2;
  &:after {
    content: "";
    background: url(../assets/images/arrow-dark-black.svg) center right
      no-repeat;
    background-size: 8px;
    display: block;
    height: 10px;
    width: 10px;
    top: 13px;
    position: absolute;
    right: 10px;
    //right: 15px;
    transform: rotate(90deg);
  }
  &.active:after {
    top: 15px;

    transform: rotate(270deg);
  }
}

.styleguide-nav-container {
  display: none;
  margin-left: 0;
  margin-right: 0;
  width: 300px;
  position: absolute;
  right: 0;
  z-index: 99;
  top: 40px;
  // @media #{$large-up} {
  // 	top: 58px;
  // }
}

.styleguide-nav-list {
  background-color: $color-gray-warm-3;
  li {
    display: block;
    padding: 0 $spacing-large;
    list-style: none;
    a {
      // color: #fff;
      padding: 10px 0;
      display: block;
      border-bottom: 1px solid $color-gray-warm;
      font-size: 14px;
      font-family: $body-font-family;
      text-decoration: none;
      font-size: 14px;
      color: $color-black;
      text-transform: uppercase;
      position: relative;
      &:hover {
        color: $color-sea-green;
      }
      &.has-sub {
        padding-right: 50px;
        &:after {
          content: "";
          background: url(../assets/images/arrow-dark-black.svg) center right
            no-repeat;
          background-size: 8px;
          display: block;
          height: 10px;
          width: 10px;
          top: 15px;
          position: absolute;
          right: 15px;
          transform: rotate(90deg);
        }
        &.active:after {
          top: 17px;
          transform: rotate(270deg);
        }
      }
    }
  }
  > li:last-child a {
    border-bottom: none;
  }
}

.styleguide-nav-list-second {
  display: none;
  margin-bottom: 0;
  > li {
    padding-right: 0;
    > a {
      text-transform: none;
    }
  }
}
