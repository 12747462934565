.veiviser {
	&--container {
		margin-top: $spacing-large + $spacing-medium;

		.button-arrow-right::after,
			.button-arrow-left::after {
			background-image: url("../assets/images/arrow-black-redesign2020.svg");
			background-size: 20px;
		}

		.button-arrow-left::after {
			transform: rotate(180deg);
		}

		+ .accordion.accordion-secondary.alternate {
			margin: 0;

			.accordion-navigation .content {
				margin: $spacing-medium 0 $spacing-large;
				padding: $spacing-large + $spacing-small $spacing-xlarge;
			}
			.list-links {
				margin-top: $spacing-large;

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&--menu-button {
		position: relative;
		padding-left: 52px;
		background-color: white;

		&:after {
		  content: " ";
		  position: absolute;
		  display: block;
		  top: 0;
		  bottom: 0;
		  left: $spacing-small;
		  width: 45px;
		  background: transparent none no-repeat scroll 50% 50%;
		  background-image: url("../assets/images/icon-hamburger-black.svg");
		  background-size: 24px;
		}

		&.active {
			background-color: $color-lime-green-light;
		}
	}

}