.article-header {
  position: relative;
  border: none;
  border-bottom: 1px solid $color-black;
  padding: $spacing-medium 0;

  &--image {
    position: absolute;
    display: none;
    right: calc(100% + #{$spacing-medium});
    top: 18px;
    height: 60px;
    width: auto;
    min-width: 70px;
    max-width: 85px;
    object-fit: contain;
    object-position: top;

    @media #{$large-up} {
      display: block;
    }
  }

  .h1 {
    display: inline-block;
    overflow: visible;
    color: $color-blue-dark;
  }

  /***********
  * INNSIDEN *
  ***********/
  .innsiden & {
    border-color: $color-green;
  }

  /**************
  * SEARCH PAGE *
  ***************/
  .search & {
    @extend .h1;
    padding: $spacing-large 0;

    .query {
      &::before {
        content: " > ";
        text-transform: capitalize;
      }
    }
  }
}
