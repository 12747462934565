.close-reveal-modal-link{
	position: absolute;
    top: 20px;
    right: 20px;
	@include cf();
	@extend .icon-before;
    @extend .icon-close;
    &:before{
    	 float: right;
	    margin-left: 5px;
	    background-size: 15px;
	    content: "";
	    height: 22px;
	    width: 22px;
    }

    @media print{
    	display: none;
    }
}


.reveal-modal{
	padding: $spacing-large;
	overflow: auto;
	max-width: 960px;


	@media #{$medium-up} {
		padding: $spacing-large *3 $spacing-xlarge;
	}
	@media #{$large-up} {
		padding: $spacing-xlarge*2 $spacing-xlarge*2;
	}

	&-link{
		margin: $spacing-large 0 $spacing-xlarge;

		.boxed-referral-list &{
			margin-bottom: 0;
		}
	}
}

.mce-window .mce-textbox{
	min-width: 200px;

	&:focus{
    	border-color: #3498db;
	}
}

.modal-opened{
	@media print{
		.main-header,
		.hearings-warning,
		.content-container,
		.footer{
			display: none;
		}
		.reveal-modal.open{
			display: block;
			top: 0px !important;
			position: relative;

			*{
				page-break-before: avoid;
				page-break-after: avoid;
			}
		}
	}
}

.hearings-child-modal-link{
	display: inline-block;
	margin: 5px 0 20px;

	@extend .icon-before;
	@extend .icon-edit;

	&:before{
		height: 22px;
	    width: 22px;
	    background-size: 24px;
	}
}

.hearings-stored-input-note{
	border-top: 1px solid $color-gray-warm-2;
	border-bottom: 1px solid $color-gray-warm-2;
	@include cf();
	margin: $spacing-xlarge 0;
	padding: $spacing-medium 0;

	.hearings-subsection &,
	.boxed-referral-list-content &{
		margin: $spacing-medium 0 0;
	}

	.hearings-subsection .accordion.accordion-secondary .content &{
		margin-bottom: $spacing-medium;
	}
}

.hearings-stored-input-link{
	float: right;
	margin-left: 20px;
	max-width: 50%;
	font-size: 14px;
}

.hearings-input-link{
	margin: $spacing-medium 0 $spacing-large;

	.hearings-subsection &,
	.boxed-referral-list-content &{
		margin-bottom: 0;
	}
	.hearings-subsection .accordion.accordion-secondary .content &{
		margin-bottom: $spacing-medium;
	}

}