ul.info-list{
	@extend .list-clean;
	@include cf();
	// display: none;
	> li{

		@media #{$medium-big-up} {
			padding-right: $spacing-medium;
			padding-left: $spacing-medium;
			float: left;
		}
	}

	&.default{
		> li{
			@media #{$medium-big-up} {
				&.large-column{
					width: 50%;
				}
			}
		}
	}

	&.standards{
		> li{
			@media #{$medium-big-up} {
				&:nth-child(1){
					width: 50%;
				}
				&:nth-child(2){
					width: 25%;
				}
				&:nth-child(3){
					width: 15%;
				}
				&:nth-child(4){
					width: 10%;
				}
			}
		}
	}
	&.systems{
		> li{
			@media #{$medium-big-up} {
				&:nth-child(1){
					width: 60%;
				}
				&:nth-child(2){
					width: 28%;
				}
				&:nth-child(3){
					width: 12%;
				}
			}
		}
	}
	&.questions{
		> li{
			@media #{$medium-big-up} {
				&:nth-child(1){
					width: 20%;
				}
				&:nth-child(2){
					width: 50%;
				}
				&:nth-child(3){
					width: 20%;
				}
				&:nth-child(4){
					width: 10%;
				}
			}
		}
	}
}

ul.info-list-content{
	@extend .info-list;
	font-size: 16px;

	margin: 0;
	padding-top: $spacing-medium + $spacing-small;
	padding-bottom: $spacing-medium + $spacing-small;

	&.standards{
		@extend .info-list.standards;
	}
	&.systems{
		@extend .info-list.systems;
	}
	&.questions{
		@extend .info-list.questions;
	}

	> li .link-clear{
		@extend .icon-before;
		@extend .icon-small;
		@extend .icon-arrow;
		&:before{
			margin-bottom: 3px;
		}

		// @extend .icon-left-arrow;
		// @extend .icon-left-arrow-sea-green;
	}
}

.info-list-content-prop{
	@media #{$medium-big-up} {
		display: none;
	}
}

ul.info-list-header{
	margin-bottom: 0;
	display: none;
	@media #{$medium-big-up} {
		display: block;
	}
	> li{
		@include h2();
		padding-top: $spacing-medium;
		padding-bottom: $spacing-medium;
	}
	&.default{
		background-color: $color-blue-light;
	}
	&.standards{
		background-color: $color-blue-green;
	}
	&.systems{
		background-color: #f1fae3;
	}
	&.questions{
		background-color: #f1fae3;
	}
}


ul.info-big-list{
	@extend .list-clean;
	margin-bottom: $spacing-xlarge;

	> li{
		border-bottom: 1px solid $color-gray-warm-3;
		.highlight{
		    display: inline;
		    font-weight: bold;
		    background-color: $color-yellow;
		}
	}
}

// .link-out-icon{
// 	display: inline-block;
// 	&:after{
// 		content: url(../assets/images/link-external-sea-green.svg);
// 		width: 10px;
// 		margin-left: 3px;
// 		height: 10px;
// 		display: inline-block;
// 	}
// }

.info-list-hits{
	// margin-bottom: $spacing-small;
}

.info-list-form-row{
	margin: $spacing-large 0;
}
