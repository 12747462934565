.boxed-generic-boxes{
    margin-top: $spacing-xlarge;
    font-weight: 19px;

    > div{
        padding: 10px;
    }

    .small{
        height: 230px;
    }

    .medium{
        height: 230px;
        &.boxed-image{
            height: 460px;
        } 
    }

    .boxed-list{
        .list-links{
            max-height: 95px;
            overflow: hidden;
        }
    }
    .header-underline {
        margin-bottom: 12px;
    }

    .boxed-standard-link{
        .boxed-standard-link-text{
            width: 100%;
            height: 85px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 

            &.box-has-image{
                height: 60px;
                -webkit-line-clamp: 2;
            }
        }
    }
}