.table-of-content{
	margin: 0 0 40px;
	
	*{
		background: none !important;
	}

	.accordion-container{
		@media print{
			a,
			.list-link-title{
				font-size: $font-size-print;
			}
		}
	}

	.accordion .accordion-static-header{
		@media print{
			padding: 0 30px 0;

			.list-link-title{
				font-weight: 400;
				font-size: 14px;
				line-height: 1.4em;
			}
		}
	}

	@media print{
		.accordion-static-header.single-click-accordion + .accordion-navigation {
			margin-top: 20px;
		}

		.accordion-navigation + .accordion-static-header.single-click-accordion {
			margin-top: -20px
		}

		.accordion-static-header.single-click-accordion{
			border-top: none; 
		}


		
	}
	a,
	a.link{
		&,
		& *{
			pointer-events: none;
			text-decoration: none !important;
			color: inherit;
		}
	}
}