.boxed-cta-with-image {
  font-size: 19px;
  &-content {
    @extend .block-padding;
    height: 100%;
    text-decoration: none;
    margin-bottom: 0;
    background: $color-sea-blue-50;

    &:after {
      clear: both;
      content: " ";
      width: 100%;
      display: block;
    }
  }

  &:not(.small) &-content {
    @media #{$large-up} {
      position: relative;
      padding-bottom: 130px;
    }
  }

  &-title {
    @extend .block-header;
    font-style: 20px;
    // @extend .header-margin-large;
  }

  &-text {
    color: $color-black;
    margin: 0;
    font-size: 19px;
    // margin: 0 0 $spacing-large;
  }

  &-button-container {
    margin-top: $spacing-large;
  }

  &:not(.small) &-button-container {
    @media #{$large-up} {
      float: right;
      max-width: calc(50% - 40px);
      position: absolute;
      bottom: $spacing-large + $spacing-medium;
      right: $spacing-large + $spacing-medium;
    }
  }

  &-button {
    margin: 0;
    font-size: $button-font-size-base;
    background-color: #072938;
  }

  &:not(.small) &-button {
    @media #{$large-up} {
      float: right;
    }
  }

  &:not(.small) &-image-container {
    @media #{$large-up} {
      width: 200px;
      max-width: calc(50% - 40px);
      position: absolute;
      bottom: $spacing-large + $spacing-medium;
      left: $spacing-large + $spacing-medium;
    }
  }

  &-image {
    position: relative;
    max-width: 100%;
    max-height: 100px;
    width: auto;
    height: auto;
    display: block;
  }
}
