/************
## Helpers ##
************/

// Clearfix
// -------------------------

@mixin cf() {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin inputFocus() {
  outline: none;
  border-color: $color-blue-dark;
  -webkit-box-shadow: 0px 0px 2px 0px $color-blue-dark;
  box-shadow: 0px 0px 2px 0px $color-blue-dark;
}

@mixin hide() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin image-link-hover() {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s;
  }
  a:hover &,
  a:focus & {
    &:after {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}

// Typo

@mixin h1() {
  font-size: 40px;
  line-height: 1.2;
  @media #{$large-up} {
    font-size: 50px;
  }

  @media print {
    font-size: 37px;
    overflow: visible !important;
  }
}

@mixin h2() {
  font-family: 'PPMori', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  font-style: normal;

  @media print {
    font-size: 15px;
  }
}

@mixin h3() {
  font-weight: normal;
  font-style: italic;
  font-size: 18px;
  font-family: 'PPMori', sans-serif;

  @media print {
    font-size: 15px;
  }
}

@mixin big-header() {
  @extend .h2;
  margin-top: $spacing-large;
  margin-bottom: $spacing-large;

  @media #{$large-up} {
    margin-top: 0;
    margin-bottom: $spacing-large + $spacing-medium;
  }

  @media print {
    font-weight: 600;
    font-size: 15.5px;
    margin-bottom: 20px;
    font-family: $print-font-family;
  }
}

@mixin block-header() {
  font-family: $body-font-family;
  font-weight: 500;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  @media #{$large-up} {
    font-size: 20px;
  }

  > a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.h3-alternate {
    text-transform: uppercase;
  }
}

@mixin paragraph() {
  @extend .body-text;
}

@mixin paragraph-smaller() {
  font-size: 16px;
}

@mixin article-lists() {
  ul,
  ol {
    margin-left: 20px;
    list-style-position: outside;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
    ol {
      list-style: lower-alpha;
    }
  }
  ol.list-letters {
    list-style: lower-alpha;
  }
}

@mixin boxed-content-wysiwyg() {
  p {
    @include paragraph();
  }
  @include article-lists();

  > *:last-child {
    margin-bottom: 0;
  }
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transform($transform...) {
  -ms-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*Flexbox*/

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}
