.rules-content {
  @media #{$large-up} {
    min-height: 500px;
  }

  > .big-header,
  > .h3 {
    @extend .header-margin-large;
  }

  h2:not([class]), h3:not([class]), .h3 {
    color: black;
  }

  p,
  .paragraph {
    margin-bottom: $spacing-large;
  }

  b {
    font-weight: normal;
  }

  @media print {
    .paragraph,
    ol,
    ul {
      font-size: 14px !important;
    }
  }

  // .accordion.accordion-secondary {
  //   margin-left: 0;
  //   p,
  //   .paragraph,
  //   a,
  //   ol,
  //   li,
  //   table {
  //     font-size: $font-size-rules;
  //     @media print {
  //       font-size: 14px;
  //     }
  //   }
  // }

  // .list-w-background-content{
  // 	ul{
  // 		> li{
  // 		    background-position: left 6px;
  // 		    p,
  // 		    .paragraph,
  // 		    a{
  // 		    	font-size: 13px;
  // 		    }
  // 		}
  // 	}
  // }
}

.rules-top-bar {
  @include cf();
  display: block;

  @media #{$medium-big-up} {
    margin-bottom: $spacing-large - $spacing-small;
    display: inline-block;
  }
  > * {
    display: block;
    @media #{$medium-big-up} {
      float: left;
      margin-right: $spacing-medium + $spacing-small;
    }

    .link-single {
      margin-bottom: 0;
      font-size: 15px;
    }
  }

  @media print {
    display: none;
  }
}

.rules-top-banner {
  background-color: $color-orange-50;
  text-align: center;
  padding: 10px 10px;
  margin: 30px 0;
  width: 100%;
  * {
    padding: 0;
    margin: 0;
  }
  &.rules-top-banner-sticky {
    display: none;
  }
  &.hidden {
    display: none;
  }
}

.expand-all-boxes {
  .expand-all-boxes-close {
    display: none;
  }
  .expand-all-boxes-show {
    display: block;
  }
  &.active {
    .expand-all-boxes-close {
      display: block;
    }
    .expand-all-boxes-show {
      display: none;
    }
  }
}

.rules-hidden-text-helper {
  font-weight: bold;
  font-size: 13px;
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap; /* Thanks to Herb Caudill comment */
}

ul.rules-tabs-triggers {
  display: block;
  margin: 0;
  // margin-top: -20px;
  margin-bottom: $spacing-large * 2;
  @include cf();
  border-top: 1px solid $color-gray-warm-3;

  @media #{$medium-big-up} {
    display: none;
  }
  @media print {
    display: none;
  }

  li {
    list-style: none;
    width: 50%;
    float: left;

    a {
      display: block;
      padding: $spacing-large $spacing-medium;
      font-size: 15px;
      text-align: center;
      text-decoration: none;
      font-family: $body-font-family;
      font-weight: 500;
      background-color: $color-gray-warm-3;
      border-bottom: 1px solid $color-gray-warm;
      border-right: 1px solid $color-gray-warm;
      border-left: 1px solid $color-gray-warm;
      &.active {
        @extend .h3;
        background-color: #fff;
        color: $color-black;
      }
    }

    &:first-child {
      a.active {
        border-top: 1px solid $color-gray-warm;
        border-right: none;
        border-bottom: none;
      }
    }

    &:last-child {
      a.active {
        border-top: 1px solid $color-gray-warm;
        border-left: none;
        border-bottom: none;
      }
    }
  }
}

.rules-tabs-target {
  display: none;
  &.active {
    display: block;

    .button-scroll-to-top {
      display: none;
      position: fixed;
      bottom: 0px;
      margin: 0px;
      z-index: 10;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

      @media #{$large-up} {
        display: block;
        position: relative;
        box-shadow: none;
        margin-top: $spacing-large;
      }
    }
  }
  @media #{$medium-big-up} {
    display: block;
  }
  @media print {
    display: block;
  }
}

.button-scroll-to-top {
  @media print {
    display: none !important;
  }
}

.article-navigation {
  display: block;
  @media #{$medium-big-up} {
    display: none;
  }
  margin-bottom: $spacing-large;
}

.rules-history-title {
  @include block-header();
  @extend .icon-before;
  @extend .icon-medium;
  @extend .icon-time;
  @extend .header-underline;
  margin-bottom: $spacing-large;

  @media print {
    font-weight: 600;
    font-size: 15.5px;
    margin-bottom: 18px;
    margin-top: 18px;
    font-family: $print-font-family;

    &:before {
      display: none !important;
    }
  }
}

//Midlertidig
.rules-tabs-content {
  span.history,
  span.print,
  span.expandAllBoxes {
    display: inline-block;
    vertical-align: middle;

    > a {
      height: 23px;
      font-size: 13px;
    }
  }

  span.expandAllBoxes {
    position: relative;
    margin-left: 5px;
  }

  span.print,
  span.expandAllBoxes {
    @media print {
      display: none;
    }
  }

  .link-with-icon.print,
  .expandAllBoxes {
    margin-bottom: $spacing-large;
  }

  span.left,
  span.right {
    display: inline-block;
  }

  span.left {
    text-align: left;
    width: 50%;
  }
  span.right {
    text-align: right;
    padding-right: 9px;
    @media print {
      display: none;
    }
  }
}
