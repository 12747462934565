.boxed-cta {
  &-content {
    @extend .block-padding;
    height: 100%;
    margin-bottom: 0;
    text-decoration: none;
    background-color: $color-blue-light-2;
  }

  &-title {
    @extend .block-header;
    @extend .header-underline;
    @extend .header-margin-large;
  }

  &-text {
    color: $color-black;
    font-size: 19px;
  }

  &-button-container {
    margin-top: $spacing-large;

    .button {
      margin: auto;
      font-size: $button-font-size-base;
      line-height: 1.3;
    }
  }
}
