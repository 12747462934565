.error-block{

	&-heading{
		border-left: 12px solid $color-error;
		background: white;
		box-shadow: 0 2px 3px rgba(0,0,0,0.18);
		bottom: 0 0 16px;
		padding: 30px 33px 46px 21px;
		font-family:  'PPMori', sans-serif;
		font-size: 20px;
		line-height: 1.3;

		@media #{$medium-up} {
			font-size: 24px;
		}
	}
	&-text{
		padding: 0 33px 36px;
		margin: 36px 0 20px;
		width: 100%;
	}
}