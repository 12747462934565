.hearings-answer-status{
	margin-bottom: 20px;

	&-buttons{
		margin-bottom: 20px;

		.show-answer-status-form &{
			display: none;
		}
	}

	&-form{
		display: none;

		.show-answer-status-form &{
			display: block;
		}
	}
}