.boxed-facebook {
  margin-bottom: 20px;

  [data-equalizer-mq] & {
    margin-bottom: 0px;

    @media #{$medium-up} {
      width: 50%;
      float: left;
    }
    @media #{$large-up} {
      width: 25%;
    }

    &.medium {
      @media #{$medium-up} {
        width: 50%;
      }
    }

    &.large {
      @media #{$medium-up} {
        width: 100%;
      }
    }
  }

  &-link {
    height: 100%;
    text-decoration: none;
    display: block;
    width: 100%;
    @include paragraph();
    @include boxed-content-wysiwyg();
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    @extend .block-padding;
    margin-bottom: 0;
    background-color: #214f91;
    position: relative;

    &[href=""] {
      pointer-events: none;
    }

    @media #{$medium-up} {
      padding: $spacing-large $spacing-large + $spacing-medium;
    }

    &:after {
      transition: background-color 0.2s;
      position: absolute;
      width: 100%;
      height: 100%;
      content: " ";
      display: block;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
    }

    &:hover {
      &:after {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-header {
    @extend .block-header;
    text-align: center;
    color: #fff;
  }

  &-image {
    display: block;
    margin: 0 auto 30px;
    max-width: 160px;
    width: 90%;
    z-index: 2;
  }
}
