.news-feed{
	width: 100%;
	padding: 0 10px;
	overflow: hidden;
	
	@media #{$medium-up} {
		width: 50%;
		float: left;
	}
	&-container{
		margin-top: 0!important;
	}

	&-content{
		border: 1px solid $color-gray-warm-2;
	}

	&-title{
		background-color: $color-gray-warm-3;
		margin-bottom: 0 !important;
		padding: 10px 20px;
		text-transform: uppercase;
		font-weight: normal;

		@media #{$medium-up} {
			padding: 10px 30px;
		}
	}

	&-items{
		list-style: none;
		padding: 0;
		margin: 0;
		max-height: 0px;
		@include transition( max-height 0.3s ease-in-out);

		.no-js &{
			max-height: none;
		}
	}

	&-item{
		padding: 20px;
		border-bottom: 1px dashed $color-gray-warm-2;
		cursor: pointer;

		@media #{$medium-up} {
			padding: 30px;
		}

		&:last-child{
			border-bottom: none;
		}

		&-title{
			margin-bottom: 5px;
		}

		&-link{
			font-size: 18px;
			text-decoration: none;
			font-weight: bold;

			&:hover{
				text-decoration: underline;
			}
		}
		
		&:hover &-link{
			text-decoration: underline;
		}

		&-intro{
			clear: both;
			margin-bottom: 5px;
		}

		&-date{
			@extend .meta;
			clear: both;
		}
	}

	
}