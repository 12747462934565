@-webkit-keyframes dotdotdot{0%{content:" ";}25%{content:".";}50%{content:"..";}75%{content:"...";}100%{content:"...";}}
@keyframes dotdotdot{0%{content:" ";}25%{content:".";}50%{content:"..";}75%{content:"...";}100%{content:"...";}}

.loading-panel{
	background: $color-lime-green-light;
    padding: 10px 24px;
    margin-top: 20px;
    font-size: 18px;
	font-weight: bold;
	
	&:after{
		content: "...";
		-webkit-animation: dotdotdot 2s linear infinite;
		animation: dotdotdot 2s linear infinite;
	}
}