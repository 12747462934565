ul.news-list{
	border-top: 1px solid $color-gray-warm;
	margin-top: $spacing-small;
	> li{
		.news-list-date{
			font-family: $body-font-family;
		}
		.news-list-text{
			
		}
		border-bottom: 1px solid $color-gray-warm;
		padding: $spacing-large 0;
	}
}