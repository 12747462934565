.search-global-results-container{
	@include cf();
	font-family:  'PPMori', sans-serif;
	font-weight: 300;
	margin-bottom: $spacing-large;
	
	// .form-row{
		
	// 	float: right;
	// 	width: 300px;

	// }

	.form-group{
		position: relative;
		margin-top: $spacing-large;
		margin-bottom: $spacing-large;
	}
}

label.search-global-results{
	@include hide();
}

/*.search-global-results-form{
	position: relative;
	margin-top: $spacing-large;
	margin-bottom: $spacing-large;
}*/

.search-global-results{
	// position: relative;
	// &:after{
	// 	right: 10px;
	// 	top: 10px;
	// 	position: absolute;
		
		// height: 20px;
		// width: 20px;

	// }
	// background: url(../assets/images/search-warm-grey.svg) right 10px center no-repeat;
	// background-size: 20px 20px;
	padding-right: 40px;
	// background-color: $color-gray-warm-3;
	// border-color: $color-gray-warm-3;
}

/*#search-global-results-input{
	// background-color: $color-gray-warm-3;	
	// border-color: $color-gray-warm-3;
	// &:focus{
	// 	border-color: $color-sea-green-dark;
	// }
}*/

input[type="submit"].search-global-results-submit, //Added cause of be and exsisting global search in prod
input[type="button"].search-global-results-submit{
	// cursor: pointer;
	// position: absolute;
	// background: transparent;
 //    border: none;
 //    text-indent: -99999999px;
 //    width: 50px;
 //    padding: 0;
 //    top: 0;
 //    right: 0;
 //    bottom: 0;
 //    margin: 0;
	// text-transform: uppercase;
	letter-spacing: 1px;
	position: absolute;
    right: 0;
    top: 0;
    margin: 0;
	height: 56px;
}
