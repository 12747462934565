.hearings-warning{
	@include cf();
	background: $color-gray-warm-3;
	padding: $spacing-medium 0;
	color: $color-error;
	text-align: center;
	font-size: 20px;
	font-weight : normal;

	@media #{$large-up} {
		padding: $spacing-large 0;
	}

	a{
		color: $color-error;
	}
	p{
		margin: 0;
	}
}