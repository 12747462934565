.renovation-article {
	&-list {
		display: flex;
		flex-wrap: wrap;
		margin: $spacing-large calc(#{$spacing-medium} * -1);

		@media #{$medium-up} {
			margin: $spacing-large calc(#{$spacing-large} * -1);
		}

		&--item {
			width: 100%;
			max-width: 100%;
			padding: 0 $spacing-medium $spacing-large + $spacing-medium;

			a {
				width: 100%;
				height: 100%;

				img {
					width: 100%;
					margin-bottom: $spacing-medium;
				}

				h3 {
					margin: 0;
					font-size: $font-size-base-mob;
					font-style: normal;
					font-weight: 400;
					@media #{$large-up} {
					  font-size: $font-size-base;
					}
				}
			}

			@media (min-width: $renovation--bp-xs) {
				width: 50%;
			}

			@media #{$medium-up} {
				width: 50%;
				padding-left: $spacing-large;
				padding-right: $spacing-large;
			}

			@media #{$large-up} {
				width: calc(100% / 3);
			}
		}
	}
}