.hearing{
    padding-top: 70px;

    @media #{$small-only} {
        padding-top: 30px;
    }

    &__title{
        max-width: 874px;
        margin-bottom: 138px;
        font-size: 32px;
        line-height: 44px;
        font-weight: 600;
        color: $color-blue-dark;

        @media #{$small-only} {
            margin-bottom: 48px;
            font-size: 28px;
            line-height: 42px;
        }
    }

    &__list{
        position: relative;
        list-style: none;
        margin: 0;

        @media #{$small-only} {
            padding-top: 55px;
        }
    }

    &__arrow{
        width: 2px;
        position: absolute;
        height: 100%;
        top: 0;
        left: calc(8% - 1px);
        background-color: $color-warm-300;
        display: block;
        z-index: -1;

        @media #{$small-only} {
            top: 0;
            left: 24px;
        }

        &::before{
            content: "";
            display: block;
            position: absolute;
            left: -7px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: $color-warm-300 transparent transparent transparent;
        }

        &::after{
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: -4px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: $color-warm-300;
        }
    }

    &__timeline{
        position: relative;
        width: 16%;
        padding: 20px 0;
        background-color: $color-white;

        @media #{$small-only} {
            width: auto;
            padding: 12px 10px;
        }

        &::before{
            content: "";
            display: block;
            position: absolute;
            top: -8px;
            left: calc(50% - 6px);
            width: 12px;
            height: 10px;
            border-style: solid;
            border-width: 10px 6px 0 6px;
            border-color: $color-warm-300 transparent transparent transparent;

            @media #{$small-only} {
                left: 19px;
            }
        }

        p, h2{
            margin: 0;
            color: $color-blue-dark;
            font-size: 16px;
            line-height: 22px;
            text-align: center;

            @media #{$small-only} {
                text-align: left;
            }

            &.timeline-title{
                font-weight: 600;
            }
        }
    }

    &__item{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 32px;
        margin-bottom: 72px;

        @media #{$small-only} {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 116px;

            &:last-child{
                margin-bottom: 40px;
            }
        }

        &:first-of-type{
            .hearing__timeline{
                &::before{
                    display: none;
                }
            }
        }

        &::after{
            @media #{$small-only} {
                content: "";
                display: block;
                position: absolute;
                bottom: -12px;
                width: 100%;
                height: 12px;
                background-color: $color-white;
            }
        }
        
        .item-content{
            display: block;
            width: calc(84% - 32px);
            padding: 56px 80px 60px;
            text-decoration: none;

            @media #{$small-only} {
                padding: 32px 24px 32px 24px;
                width: 100%;
            }

            *{
                color: $color-blue-dark;
            }

            p{
                margin-top: 0;
                color: $color-blue-dark;
                font-size: 20px;
                line-height: 32px;

                @media #{$small-only} {
                    font-size: 16px;
                    line-height: 26px;
                }

                &:last-child{
                    margin-bottom: 0;
                }
            }

            .item-bottom{
                margin-top: 28px;
                padding-top: 32px;
                border-top: 1px solid;

                @media #{$small-only} {
                    padding-top: 28px;
                }

                p{
                    font-weight: 600;
                }
            }

            table{
                width: 100%;
                margin-bottom: 24px;
                border-spacing: 0px;
                border-collapse: collapse;

                &:last-child{
                    margin-bottom: 0;
                }

                th, td{
                    padding: 4px;
                    border: 1px solid $color-blue-dark;
                }

                * {
                    font-size: 20px;
                    line-height: 32px;

                    @media #{$small-only} {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }

            ul, ol{
                li{
                    font-size: 20px;
                    line-height: 32px;

                    @media #{$small-only} {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }

        &--external{
            .item-content{
                .item-bottom{
                    p{
                        a{
                            display: flex;
                            align-items: center;
                            color: $color-blue-dark;
                            text-decoration: none;
    
                            &::after{
                                content: "";
                                display: block;
                                background-image: url("../assets/images/arrow-up-right.svg");
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: auto;
                                min-width: 32px;
                                width: 32px;
                                height: 32px;
                            }

                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        &--accordion{
            .item-content{
                .item-bottom{
                    p{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 32px;

                        .text-close{
                            display: none;
                        }

                        &::after{
                            content: "";
                            display: block;
                            background-image: url("../assets/images/chevron-down.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: auto;
                            min-width: 32px;
                            width: 32px;
                            height: 32px;
                            transition: transform 0.4s;
                        }
                    }
                }

                &[aria-expanded="true"]{
                    padding-bottom: 54px;

                    @media #{$small-only} {
                        padding-bottom: 40px;
                    }

                    .item-bottom{
                        p{
                            .text-close{
                                display: block;
                            }
            
                            .text-open{
                                display: none;
                            }

                            &::after{
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }

            .hidden-content{
                width: calc(84% - 32px);
                margin-left: auto;
                padding: 0px 80px 56px;

                @media #{$small-only} {
                    width: 100%;
                    padding: 0 24px 32px 24px;
                }
            }
        }

        &--green{
            .item-content{
                background-color: $color-green-70;

                .item-bottom{
                    border-top-color: #B4D19B;
                }
            }

            .hidden-content{
                background-color: $color-green-70;
            }
        }

        &--warm{
            .item-content{
                background-color: $color-warm-25;

                .item-bottom{
                    border-top-color: $color-warm-200;
                }
            }

            .hidden-content{
                background-color: $color-warm-25;
            }
        }

        &--yellow{
            .item-content{
                background-color: $color-orange-80;

                .item-bottom{
                    border-top-color: #DCC875;
                }
            }

            .hidden-content{
                background-color: $color-orange-80;
            }
        }

        &--blue{
            .item-content{
                background-color: $color-sea-blue-50;

                .item-bottom{
                    border-top-color: $color-sea-blue-300;
                }
            }

            .hidden-content{
                background-color: $color-sea-blue-50;
            }
        }
    }
    
    &__answers{
        margin-bottom: 54px;

        @media #{$small-only} {
            margin-bottom: 40px;
        }
    
        &:last-child{
            margin-bottom: 0;
        }
    
        * {
            color: $color-blue-dark;
        }
        .answers-header{
            display: flex;
            flex-direction: row;
            margin-bottom: 24px;
            font-size: 20px;
            line-height: 32px;

            @media #{$small-only} {
                margin-bottom: 18px;
                font-size: 16px;
                line-height: 22px; 
            }
    
            h3{
                font-size: inherit;
                line-height: inherit;
                font-weight: 600;
                font-style: normal;
            }
            
            span{
                display: flex;
                flex-direction: row;
                font-size: inherit;
                line-height: inherit;
    
                &::before{
                    content: "–";
                    display: block;
                    margin-right: 6px;
                    margin-left: 6px;
                }
            }
        }
    
        .list-title{
            margin-top: 0;
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 22px;
            font-weight: 600;
            font-style: normal;
        }
    
        .answers-list{
            margin: 0 0 24px;
            list-style: none;

            @media #{$small-only} {
                margin-bottom: 12px;
            }
    
            &:last-child{
                margin-bottom: 0;
            }
    
            li{
                display: flex;
                flex-direction: row;
                align-items: baseline;
                column-gap: 24px;
                margin-bottom: 8px;

                time{
                    font-size: 14px;
                    line-height: 22px;
                }
                
                p, p *{
                    margin: 0;
                    font-size: 14px;
                    line-height: 22px;

                    @media #{$small-only} {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }


                @media #{$small-only} {
                    column-gap: 12px;
                    margin-bottom: 16px;
                }
    
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
