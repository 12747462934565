%file-delete-button{
	@include cf();
	@extend .icon-before;
	@extend .icon-close;
	margin: 0;
	background: none;
	padding: 0;
	border: none;
	content: "";
	width: 18px;
	position: absolute;
	top: 0px;
	left: 0px;
	text-indent: -100px;
	overflow: hidden;


	&:hover{
		background: none;
	}

	&:before{
		float: left;
		margin: 1px 0 0 !important;
		background-size: 15px;
		content: "";
		height: 22px;
		width: 22px;
	}
}

.ajax-file-upload{
	padding: 10px 0 0;
	position: relative;

	p{
		margin-top: 0;
	}
}

.file-name,
.file-data input{
	display: none;
}
.file-info .error-message{
	@extend .input-error;
}

.file-data{
	@include cf();
	clear: both;
}

.file-info{
	padding-left: 30px;
	margin: 10px 0;
	position: relative;

	&:last-child{
		margin-bottom: 0;
	}

	button{
		@extend %file-delete-button;
	}

	.file-name{
		overflow-wrap: break-word;
		-ms-word-wrap: break-word;
		display: block;
		clear: both;
		width: 100%;
	}
	.error-message{
		clear: both;
		float-left: none;
	}
}

.progress-bar{
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.25);
	top: 0px;
	left: 0px;
}

.uploaded-files{
	list-style: none;
	margin: 0;
	@include cf();
	clear: both;

	li{
		@include cf();
		padding-left: 30px;
		margin: 10px 0;
		position: relative;
	}
	.uploaded-files-link{
		overflow-wrap: break-word;
		-ms-word-wrap: break-word;
		float: left;
		max-width: 100%;
	}

	.uploaded-files-delete{
		@extend %file-delete-button;

		@media print{
			display: none;
		}
	}
}