.boxed-calendar {
  &-content {
    height: 100%;
    margin-bottom: 0;
    background: $color-white;
    padding-bottom: 10px;

    .boxed-block--heading{
      color: $color-text-gray;
    }
  }

  &-events {
    margin: 0;
    padding: 0;
  }
}
