.boxed-teaser {
  [data-equalizer-mq] & {
    margin-bottom: 0px;

    @media #{$medium-up} {
      width: 50%;
      float: left;
    }
    @media #{$large-up} {
      width: 25%;
    }
  }

  &-link {
    min-height: 100%;
    display: block;
    text-decoration: none;
    background-color: $color-gray-warm-3;
    position: relative;

    &[href=""] {
      pointer-events: none;
    }

    &:hover,
    &:focus {
      color: $color-black;
      // transition: background-color 0.3s;
      // background-color: darken($color-gray-warm-3, 2%);
    }

    &.light-blue {
      background-color: $color-sea-blue-50;
    }

    &.blue {
      background-color: $color-sea-blue-900;

      h1,
      h2,
      h3,
      h4,
      h5,
      p,
      div,
      span {
        color: $color-white;
        border-bottom-color: $color-white;
      }
    }

    &.yellow {
      background-color: $color-orange-50;
    }

    &.light-green {
      background-color: $color-green-50;
    }
  }

  &-image-container {
    @include image-link-hover();
  }

  &-image {
    width: 100%;
    display: block;
  }

  &-intro {
    @extend .block-padding;
    @include boxed-content-wysiwyg();
    @include paragraph();
    margin-bottom: 0;
    color: $color-black;
  }

  &.small {
    .large-4 .medium-6 & {
      width: 100%;
    }
  }
}
