.renovation-advice {
  &-list {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-bottom: $spacing-large;
    display: flex;
    flex-wrap: wrap;

    &--item {
      display: block;
      width: 50%;
      max-width: 100%;
      padding: $spacing-large $spacing-medium;

      > a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;

        > span {
          margin: auto;
          text-align: inherit;
        }

        .icon {
          width: 40px;
          height: 40px;

          img {
            display: block;
            width: inherit;
            max-width: 100%;
            height: inherit;
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      @media (min-width: $renovation--bp-xs) {
        padding: $spacing-large $spacing-large + $spacing-large;
        padding-left: 0;

        .no-grid & {
          width: auto;
        }

        > a {
          flex-direction: row;
          justify-content: flex-start;
          text-align: left;

          > span {
            margin: auto 0;
          }

          .icon {
            margin-right: $spacing-medium;
          }
        }
      }

      @media #{$medium-up} {
        width: calc(100% / 3);

        .no-grid & {
          width: auto;
        }

        .icon {
          margin-right: $spacing-large;
        }
      }
    }
  }
}