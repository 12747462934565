.article-ingress {
  @include clearfix();
  padding: $spacing-large 0;

  .intro-image {
    float: left;
    max-width: 140px;
    margin: 0 30px 0 0;
  }

  .paragraph-ingress {
    margin: 0;
    max-width: 900px;
    overflow: hidden;
    font-weight: 400;
  }

  .article-image-container {
    margin-bottom: 0;
    @include clearfix();
  }
}

.article-ingress-small {
  border-bottom: none;
  padding-bottom: 0;

  p {
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
