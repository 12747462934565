.boxed-referral-list {
  width: 100%;

  &.small {
    @media #{$medium-up} {
      float: left;
      width: 25%;
    }
  }
  &.medium {
    @media #{$medium-up} {
      float: left;
      width: 50%;
    }
  }
}

.boxed-referral-list-content {
  height: 100%;
  @extend .block-padding;
  background-color: $color-blue-light-2;

  @media #{$medium-up} {
    padding: $spacing-large + $spacing-medium;
  }

  .rules & {
    > ul {
      margin-bottom: 0;

      @media print {
        font-size: $font-size-print;
      }
    }

    a,
    p,
    ul,
    ol,
    span,
    .paragraph {
      font-size: $font-size-rules;
    }
  }

  @media print {
    font-size: $font-size-print;
  }
}

.boxed-referral-list-title {
  @include block-header();
  margin-bottom: $spacing-medium;
  color: $color-blue-dark;

  @media print {
    font-size: 14px;
  }
}
