.element-loaded-notifier{
	@keyframes elementLoadedNotifier {  
		from { opacity: 0.99; }
		to { opacity: 1; }  
	}

	@-moz-keyframes elementLoadedNotifier {  
		from { opacity: 0.99; }
		to { opacity: 1; }
	}

	@-webkit-keyframes elementLoadedNotifier {  
		from { opacity: 0.99; }
		to { opacity: 1; }
	}

	@-ms-keyframes elementLoadedNotifier {  
		from { opacity: 0.99; }
		to { opacity: 1; }
	}

	@-o-keyframes elementLoadedNotifier {  
		from { opacity: 0.99; }
		to { opacity: 1; }
	}

	&{
		animation-duration: 0.001s;
		animation-name: elementLoadedNotifier;
	}
}