.icon-before {
  &:before {
    display: inline-block;
    margin-right: $spacing-small;
    content: "";
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.icon-small {
  &:before {
    height: 20px;
    width: 15px;
    background-size: 10px 10px;
  }
}
.icon-medium {
  &:before {
    height: 22px;
    width: 22px;
    background-size: 20px;
  }
}

.icon-large {
  &:before {
    height: 34px;
    width: 34px;
    background-size: 34px;
  }
}

.icon-warning-before-child-item > *:first-child {
  &:before {
    background-image: url(../assets/images/icon-exclamation-mark.svg);

    height: 34px;
    width: 34px;
    background-size: 34px;

    display: inline-block;
    margin-right: $spacing-small;
    content: "";
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

// .icon-after{
// 	&:after{
// 		display: inline-block;
// 		margin-left: $spacing-small;
// 		content: "";
// 	}
// 	&.icon-small{
// 		&:after{
// 			height: 18px;
//     		width: 18px;
// 		}
// 	}
// 	&.icon-medium{
// 		&:after{
// 			height: 18px;
//     		width: 18px;
// 		}
// 	}
// }

// .icon-small,
// .icon-medium{

// }

.icon-info {
  &:before {
    background-image: url(../assets/images/icon-information.svg);
  }
}
.icon-print {
  &:before {
    background-image: url(../assets/images/print-black.svg);
  }
}
.icon-arrow {
  &:before {
    background-image: url(../assets/images/arrow-dark-black.svg);
    background-size: 10px 19px;
    // margin-bottom: 3px;
  }
}
.icon-time {
  &:before {
    background-image: url(../assets/images/history-black.svg);
  }
}

.icon-close {
  &:before {
    background-image: url(../assets/images/close.svg);
  }
}

.icon-edit {
  &:before {
    background-image: url(../assets/images/icon_edit.svg);
  }
}

.icon-warning {
  &:before {
    background-image: url(../assets/images/icon-exclamation-mark.svg);
  }
}

// .icon-left-arrow{
// 	padding-left: 15px;
//     background-size: 10px 10px;
//     list-style: none;
//     background-position: left center;
// 	background-repeat: no-repeat;

// 	&.icon-left-arrow-sea-green{
// 		background-image: url(../assets/images/arrow-sea-green.svg);
// 	}
// }

// .logo-wrapper{
// 	margin-bottom: $spacing-large;
// }
