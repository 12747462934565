.form-input-file-simple {
  font-style: 16px;
  .js &-container {
    position: relative;
    display: block;
    width: 100%;
    clear: both;
    cursor: pointer;
  }

  &-caption {
    @extend label;
    margin-bottom: 6px;
  }

  .js & {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: red;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .js &-label-text {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #e5e3e1;
    color: $color-blue-dark;
    padding: 15px 20px;
    font-family:  'PPMori', sans-serif;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    position: relative;
    z-index: 0;
    @media #{$large-up} {
      font-size: 18px;
    }

    &.has-focus {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;
      color: white;
    }
  }

  &-container.has-focus &-label-text,
  &-container:hover &-label-text {
    .js & {
      background-color: $color-blue-dark;
      border-color: $color-blue-dark;
      color: white;
    }
  }

  &-icon {
    height: 20px;
    width: 15px;
    background: url(../assets/images/arrow-dark-black.svg) no-repeat 0 center;
    background-size: 10px 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

.field-validation-error {
  @extend .input-error;
}
