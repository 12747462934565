.accordion.accordion-boxed {
  border: none;
  .accordion-navigation {
    border-top: none;
    background-color: transparent;
    > a {
      padding: 20px 30px;
      &:after {
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
      }
    }
    &.active {
      > a:after {
        transform: translateY(-50%) rotate(270deg);
      }
    }
  }

  .accordion-title {
    @include block-header();

    @media print {
      page-break-inside: avoid;
    }
  }

  &.faq {
    background-color: $color-lime-green-light;
  }

  &.facts {
    background-color: $color-gray-warm-3;

    .block-header {
      @media #{$medium-up} {
        border-bottom: 1px solid $color-gray-warm;
        padding-bottom: 15px;
      }
    }

    .accordion-navigation {
      @media #{$medium-up} {
        > a {
          cursor: default;
          &:after {
            display: none;
          }
        }
      }
    }

    .content {
      padding-top: 0;

      @media #{$medium-up} {
        &,
        &.active {
          display: block !important;
          height: auto !important;
          margin: 0 !important;
          padding: 10px 30px !important;
        }
      }

      .facts-text {
        font-size: 16px;
      }
      p {
        font-size: inherit;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  @media print {
    background: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .accordion-navigation {
      .content,
      > a {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}
