.cookie-notification {
  position: relative;
  background: $color-blue-dark;
  padding: $spacing-large;
  margin: 0 auto;
  text-align: center;

  @media #{$medium-up} {
    padding: $spacing-large + $spacing-medium 100px;
  }

  .close {
    @extend .text-link;
    display: block;
    margin-top: $spacing-medium;
    color: white;

    @media #{$medium-up} {
      position: absolute;
      top: $spacing-large;
      right: $spacing-large;
      margin-top: 0;
    }
  }

  > p {
    margin: 0 auto;
    max-width: 500px;
    color: white;
  }
}
