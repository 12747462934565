.boxed-standard-link {
  > a {
    @extend .block-padding;
    height: 100%;
    display: block;
    text-decoration: none;
    background-color: $color-gray-warm-3;

    &[href=""] {
      pointer-events: none;
    }
    h2 {
      font-weight: 500;
    }
    .header-underline {
      margin-bottom: 12px;
    }

    &:hover,
    &:focus {
      h2 {
        text-decoration: underline;
        text-decoration-skip-ink: none;
        text-decoration-thickness: 1px;
      }
    }

    &.light-blue {
      background-color: $color-sea-blue-50;
    }

    &.blue {
      background-color: $color-sea-blue-900;

      h1,
      h2,
      h3,
      h4,
      h5,
      p,
      div,
      span {
        color: $color-white;
        border-bottom-color: $color-white;
      }
    }

    &.yellow {
      background-color: $color-orange-50;
    }

    &.light-green {
      background-color: $color-green-50;
    }
  }

  &-title {
    margin-bottom: $spacing-large;

    .block-header{
      line-height: 1.5;
    }
  }

  &-icon {
    &:before {
      content: "";

      display: block;
      width: 30px;
      height: 30px;

      display: inline-block;
      margin-right: $spacing-medium;
      margin-top: auto;
    }

    &.dok10:before {
      background: url(../assets/images/test1.svg) no-repeat;
    }

    &.tek10:before {
      background: url(../assets/images/test2.svg) no-repeat;
    }

    &.sak10:before {
      background: url(../assets/images/test3.svg) no-repeat;
    }
  }

  &-text {
    @extend .body-text;
    font-size: 19px;
  }
}
