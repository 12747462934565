.form-input-file {
    .js &{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    &-button {
        @extend .button;
        @extend .button-secondary;
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
    }

    .no-js &-button {
        display: none;
    }

    &:focus + label,
    &.has-focus + label {
         @extend .button:focus;
    }

    &-label{
        min-height: 25px;
        margin-bottom: 5px;
    }
}