.colored-header {
  padding: $spacing-medium $spacing-medium + $spacing-small;
  margin-bottom: $spacing-medium;

  &.dark-blue {
    background-color: $color-blue-dark;
    color: #fff;
  }

  &.light-blue {
    background-color: lighten($color-blue-light, 10%);
  }

  > h2 {
    text-transform: uppercase;
    color: inherit;
  }
}
