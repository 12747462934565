.renovation-interactive {
	position: relative;
	max-width: $renovation-width--top-image;
	margin: 0 auto $spacing-large;
	align-items: center;

	&--overlay {
		position: absolute;
		display: none;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #000;
		opacity: 0.3;
	}

	.interactive {
		position: absolute;
		z-index: 1001;
		padding: 0;
		margin: 0;
		visibility: hidden;

		@media #{$medium-up} {
			visibility: visible;
		}

		&-list {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&-dot,
		&-card {
			position: absolute;
			top: 50%;
			left: 50%;
		}

		&-dot {
			width: 15px;
			height: 15px;
			border-radius: 50%;
			background-color: white;
			transform: translate(-50%, -50%) scale(1);
			box-shadow: 0 0 0 0 #fff;
			animation: pulse 6s infinite;
			animation-delay: -4s;
		}

		&-card {
			visibility: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;
			z-index: 1002;
			width: 250px;
			overflow: hidden;
			height: auto;
			transition: transform .2s ease-out;
			transform: translate(-50%, -50%) scale(0);
			box-shadow: 0 40px 70px -30px rgba(0,0, 0, 0.5);
			border-radius: 20px;
			padding-top: $spacing-medium;
			padding-bottom: $spacing-medium;
			background: #fff;
			text-align: center;

			&-image {
				display: none;
				position: relative;
				height: 150px;
				width: 100%;
				overflow: hidden;

				img {
					position: absolute;
					min-width: 100%;
					min-height: 100%;
					object-fit: cover;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				@media #{$large-up} {
					display: block;
				}
			}

			h4,
			p,
			a {
				display: block;
				margin: 0 auto;
				padding: $spacing-small $spacing-large;
			}

			h4 {
				font-size: $font-size-base-mob;
				font-weight: 400;
				font-style: normal;
				@media #{$large-up} {
				  font-size: $font-size-base;
				}
			}

			p {
				padding: 0 $spacing-large;
				font-size: $font-size-mini;
				font-weight: lighter;
			}

			a {
				font-size: $font-size-small;
			}

			@media #{$large-up} {
				padding-top: 0;
				width: 350px;
			}

		}

		&:focus .interactive-dot {
			background-color: $color-blue-dark;
			border: 1.5px solid $color-gray-warm-2;
		}

		&:hover,
		&.active {
			.interactive-card {
				visibility: visible;
				transform: translate(-50%, -50%) scale(1);
			}

			~ .interactive {
				opacity: 0;
			}
		}
	}

	&.dot-active {
		.renovation-interactive--overlay {
			display: block;
		}

		.interactive-dot {
			opacity: 0;
		}
	}
}

@keyframes pulse {
    0% {
		transform: translate(-50%, -50%) scale(.95);
		box-shadow: 0 0 0 0 hsla(0,0%,100%,0)
    }

    80% {
		transform: translate(-50%, -50%) scale(.95);
		box-shadow: 0 0 0 0 hsla(0,0%,100%,.7)
    }

    to {
		transform: translate(-50%, -50%) scale(1);
		box-shadow: 0 0 0 10px hsla(0,0%,100%,0)
    }
}
