.boxed-richtext {
  width: 100%;
  font-size: 19px;
  .boxed-richtext-content {
    height: 100%;
    text-decoration: none;
    @extend .block-padding;
    margin-bottom: 0;
    background-color: $color-gray-warm-3;
  }

  &.light-blue {
    .boxed-richtext-content{
      background-color: $color-sea-blue-50;
    }
  }

  &.blue {
    .boxed-richtext-content{
      background-color: $color-sea-blue-900;
    }

      *,
      h1,
      h2,
      h3,
      h4,
      h5,
      p,
      div,
      span {
        color: $color-white !important;
      }
  }

  &.yellow {
    .boxed-richtext-content{
      background-color: $color-orange-50;
    }
  }

  &.light-green {
    .boxed-richtext-content{
      background-color: $color-green-50;
    }
  }
}

.boxed-richtext-title {
  @extend .block-header;
  @extend .header-margin-large;
  font-style: 20px;
}
