/***********
## Colors ##
***********/

// Primary colors (redesign 2023)
$color-sea-blue-900: #003045;
$color-sea-blue-300: #89BAD6;
$color-sky-blue-700: #005CAD;
$color-orange-700: #E07000;
$color-orange-200: #FFBE00;
$color-green-600: #1A8714;

// Secondary colors (redesign 2023)
$color-sea-blue-100: #C7E0F2;
$color-sea-blue-50: #EBF4FA;
$color-orange-100: #FCEFB6;
$color-orange-80: #FDF2C5;
$color-orange-50: #FFF8DB;
$color-green-100: #CBEBB0;
$color-green-70: #DBF1C8;
$color-green-50: #F1FAE3;
$color-warm-300: #B0A6A4;
$color-warm-200: #D7CCCA;
$color-warm-50: #F5F0F0;
$color-warm-25: #F7F5F5;

// Functional colors (redesign 2023)
$color-red-700: #DB0000;
$color-red-100: #FFC5C2;
$color-red-50: #FCECEB;
$color-text-gray: #202020;
$color-hyperlink: #005275;
$color-visited-link: #551ABB;

// -- end redesign 2023 --

// Primary
$color-white: #fff;
$color-blue-light: #8aaec3;
$color-blue-dark: $color-sea-blue-900; // OLD #072938;

// Secondary
$color-black: #000;
$color-sea-green: #00757f;
$color-sea-green-dark: #00676f;
$color-gray-warm: #d7d4d1;
$color-gray-warm-2: #e5e3e1;
$color-gray-warm-3: $color-warm-50; // OLD #f2f1f0
$color-gray-warm-4: #faf7f7;
$color-blue-light-2: $color-sea-blue-50; // OLD  #ecf2f6


// Support
$color-orange: #c87821;
$color-green: #809e3d;
$color-blue-green: #54acb8;
$color-lime-green: #c9d12b;
$color-warning: #9d312f;
$color-blue-hover: #e0e9ef;
$color-gray-main-background: #edebe9;
$color-gray-input-border: $color-warm-300; // OLD #afaba8
$color-error: $color-red-700; // OLD #9d2024

// Support colors Innsiden, New colors redesign2020 (boxed-image colors)
$color-green-redesign2020: #c4d89d;
$color-green-light-redesign2020: #eeefc3;
$color-blue-light-redesign2020: #afc6d4;

// Additional
$color-orange-light: #faefdf;
$color-lime-green-light: $color-green-50; // OLD #f0f2cb
$color-yellow: #ffdf74; // Only for use in Statens pris for byggkvalitet, exclude from Styleguide
$color-yellow-light: #fff0c1;
$color-yellow-dark: #ffbf40;

/**********
## Fonts ##
**********/

$font-size-rules: 16px;
$font-size-print: 14px;
$font-size-mini: 14px;
// $font-size-small: 18px;
$font-size-small: 16px;
  $font-size-small-mob: 14px;
$font-size-base: 20px;
  $font-size-base-mob: 16px;
$font-size-large: 24px;
$font-size-large-mob: 18px;
$font-size-xlarge: $font-size-large;

$body-font-family: 'PPMori', sans-serif;

$button-font-family: 'PPMori', sans-serif;
$button-font-size-base: $font-size-base;
$button-font-size-small: 16px;

$header-font-family: 'PPMori', sans-serif;

$print-font-family: 'PPMori', "Times New Roman", serif;
$print-header-font-family: 'PPMori', "Times New Roman", sans-serif;

/************
## Spacing ##
************/

$spacing-small: 5px;
$spacing-medium: 10px;
$spacing-large: 20px;
$spacing-xlarge: 40px;
$spacing-xxlarge: 80px;

$global-padding: $spacing-large;

$max-content-width: 1350px;

/************
## Spacing ##
************/

.spacing-xxlarge-bottom {
  margin-bottom: $spacing-xxlarge;
}
.spacing-xlarge-bottom {
  margin-bottom: $spacing-xlarge;
}

.spacing-large-bottom {
  margin-bottom: $spacing-large;
}

.spacing-medium-bottom {
  margin-bottom: $spacing-medium;
}

.spacing-large-top {
  margin-top: $spacing-large;
}

.spacing-xlarge-top {
  margin-top: $spacing-xlarge;
}

.spacing-none-bottom {
  margin-bottom: 0;
}

/**************
## Aniamtion ##
**************/
@-webkit-keyframes rightToLeft {
  from {
    right: 0;
  }
  to {
    left: 0;
  }
}
