.help-wrapper {
  &.dictionary {
    display: inline-block;
    width: 15px;
    position: relative;
    bottom: 9px;
    right: 5px;
    margin-right: 5px;
  }
  .checkbox-label {
    margin: 0 !important;
  }

  .help-heading {
    label,
    h2,
    h4 {
      display: inline-block;
    }
  }
}

.help-toggler {
  @include hide();

  &:checked ~ {
    .help-text,
    .help-trigger:after {
      display: block;
    }
    .help-text {
      max-width: 400px;
      width: 200px;
    }
  }

  &:focus + .help-trigger {
    background-color: $color-blue-dark;
    color: white;
  }
}

.help-trigger {
  $size: 17px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  height: $size;
  width: $size;
  left: $spacing-small;
  border: 1px solid black;
  border-radius: 50%;
  background: $color-white;
  color: $color-blue-dark;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: $size;

  &:hover {
    background-color: $color-blue-dark;
    color: white;
  }

  &:after {
    $size: 20px;
    border-left: ($size * 0.7) solid transparent;
    border-right: ($size * 0.7) solid transparent;
    border-bottom: $size solid $color-blue-dark;
    bottom: -($size * 1.2);
    content: " ";
    display: none;
    height: 0;
    left: 50%;
    margin-left: -($size * 0.7);
    pointer-events: none;
    position: absolute;
    width: 0;
  }
}

.help-trigger-text {
  @include hide();
}

.help-text {
  z-index: 10;
  display: none;
  position: absolute;
  margin: 10px 0;
  padding: 10px 16px;
  background: $color-blue-dark;
  font-size: $font-size-mini;
  font-weight: normal;
  color: white;

  ul {
    margin-bottom: 0;
  }
}

//White help trigger for components with coloured background
.accordion-boxed.faq,
.boxed-referral-list-content,
.accordion.accordion-secondary .accordion-navigation > .content,
.boxed-faq-content,
.boxed-cta-content,
.boxed-list-content,
.boxed-checklist-content,
.boxed-contact,
.boxed-standard-link {
  .help-trigger {
    background: $color-white;

    &:after {
      $size: 20px;
      border-bottom: $size solid $color-white;
    }
  }

  .help-text {
    background: $color-white;
  }
}

.boxed-referral-list-content {
  &,
  * {
    @media print {
      background: none !important;
    }
  }

  @media print {
    padding: 0 !important;
  }
}
