.boxed-textbox {
    width: 100%;
    background-color: $color-gray-warm-3;
    @extend .block-padding;
    
    @media #{$medium-up} {
        padding: $spacing-large + $spacing-medium;
    }

    // &.medium {
    //     @media #{$medium-up} {
    //         width: 50%;
    //         float: left;
    //     }
    // }

    &.light-blue {
        background-color: $color-sea-blue-50;
	}
  
	&.blue {
        background-color: $color-sea-blue-900;

        *,
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        div,
        span {
          color: $color-white !important;
        }
	}
  
	&.yellow {
        background-color: $color-orange-50;
	}

	&.light-green {
        background-color: $color-green-50;
	}

    .article & {
        &.large {
            margin-bottom: $spacing-large + $spacing-large;
        }
    }
    p {
        font-size: 19px;
    }
    
    &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        @extend .block-header;
        @extend .header-margin-large;
        padding-bottom: $spacing-medium;
        border-bottom: 2px solid $color-black;

        @media #{$medium-up} {
            padding-bottom: $spacing-large;
        }
    }

    &-text {
        height: 100%;
        text-decoration: none;
    }

    &-link {
        padding-right: 40px;
        text-align: right;
    }
}