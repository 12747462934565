.checkbox {
  @include hide();

  &:focus + .checkbox-label::before {
    border-color: black;
  }

  &:checked + .checkbox-label,
  &:checked ~ .checkbox-label {
    &:before {
      background: #fff url("../assets/images/check-blue-dark.svg") no-repeat
        scroll 50% 50%;
      background-size: contain;
    }
  }

  &.input-validation-error + .checkbox-label,
  &.input-validation-error ~ .checkbox-label {
    margin-bottom: $spacing-small;

    &:before {
      border-color: $color-error !important;
    }
  }
}

.checkbox-label {
  cursor: pointer;
  display: inline-block;
  padding-left: 30px;
  position: relative;
  margin-right: $spacing-large;

  &:before {
    border: 1px solid $color-warm-300;
    border-radius: 2px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    // top: 0;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
  }
}
