.hearings-send-answer{
	@include cf();

	&-form{
		padding: 30px;
		background: $color-orange-light;

		&-title{
			@include block-header();
			margin-bottom: 20px;
		}
	}
}