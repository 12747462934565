.search-global-container {
  @include cf();
  font-family: $body-font-family;
  margin-bottom: $spacing-large;
}

label.search-global {
  @include hide();
}

.search-global-form {
  position: relative;
  max-width: 950px;
  margin: 0 auto;
}

.search-global {
  padding-right: 40px;
}

input[type="submit"].search-global-submit, //Added cause of be and exsisting global search in prod
input[type="button"].search-global-submit {
  letter-spacing: 1px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  height: 56px;
}
