.temaveileder {
    .content-container {
        padding: 0;
      
        @media #{$medium-up} {
            padding: 0;
        }
    }

    &--intro {
        margin-bottom: $spacing-large;
        padding: $spacing-large;
        background-color: $color-blue-light-2;

        @media #{$medium-up} {
            margin-bottom: $spacing-xlarge;
            padding: $spacing-large $spacing-xlarge $spacing-xlarge;
        }
    }

    &--top {
        margin-bottom: $spacing-large;
        padding-top: $spacing-large;

        @media #{$medium-up} {
            margin-bottom: $spacing-xlarge;
            padding-top: $spacing-xlarge;
        }

        .h1 {
            margin-top: 0;
            padding-bottom: 18px;
            border-bottom: 2px solid $color-blue-dark;

            @media #{$large-up} {
                font-size: 35px;
            }
        }

        .search-form {
            max-width: 516px;
            margin-left: 0;
        }

        &-image {
            background-size: 100% auto;
            background-position: center top;
            background-repeat: no-repeat;
        }

        &:not(.temaveileder--top-image) {
            background-color: $color-blue-dark;
        }
    }

    &--toc {
        margin-bottom: $spacing-xlarge;
        padding-top: $spacing-medium;
        padding-bottom: $spacing-medium;
        background-color: $color-gray-warm-3;

        &.light-green {
            background-color: $color-lime-green-light;
        }
        &.light-orange {
            background-color: $color-orange-light;
        }
        &.light-yellow {
            background-color: $color-yellow-light;
        }
        &.light-blue {
            background-color: $color-blue-light-2;
        }

        .accordion-block {
            background-color: inherit;

            &__item {
                margin-bottom: 0;
                background-color: inherit;

                &-navigation {
                    font-weight: bold;
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: $font-size-xlarge;

            li {
                margin-bottom: $spacing-small;

                &.current {
                    > a {
                        font-weight: bold;
                    }
                }
            }

            &.subchapters {
                margin-bottom: 10px;
                padding-left: 24px;

                li {
                    line-height: 1;

                    a {
                        font-size: 16px;
                    }
                }
            }
        }

        &-toggle {
            width: fit-content;
            margin-top: $spacing-large;
            margin-bottom: $spacing-small;
            background-color: $color-gray-warm-3;

            &.mobile {
                @media #{$medium-up} {
                    display: none;
                }
            }
            &.desktop {
                display: none;
                @media #{$medium-up} {
                    display: block;
                }
            }

            a {
                position: relative;
                display: inline-block;
                padding: 15px 60px 15px 30px;
                font-weight: bold;
                color: $color-blue-dark;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: #000;
                }

                &:after {
                    content: "";
                    background: url(../assets/images/arrow-accordion.svg) 50% 50% no-repeat;
                    background-size: 52px 26px;
                    display: block;
                    height: 26px;
                    width: 16px;
                    overflow: hidden;
                    top: 50%;
                    position: absolute;
                    right: 35px;
                    transform: translateY(-50%) rotate(90deg);
                    -webkit-transition: -webkit-transform 0.3s ease-in-out;
                    -ms-transition: -ms-transform 0.3s ease-in-out;
                    transition: transform 0.3s ease-in-out;
                }
            }
        }
    }

    .article {
        figure {
            margin-right: 0;
            margin-left: 0;

            figcaption {
                font-size: 16px;
            }
        }

        table {
            border: none;
            border-collapse: collapse;
            border-bottom: 16px solid $color-gray-warm-4;

            caption {
                padding: 9px 20px 7px;
                font-weight: bold;
                text-align: left;
                background-color: $color-blue-light;
            }

            thead {
                background-color: $color-gray-warm-4;

                th,
                td {
                    border: none;
                }

                td {
                    padding-top: 20px;
                    font-size: 12px;
                    font-weight: normal;
                    text-transform: uppercase;
                }

                tr + tr td {
                    padding-top: 0;
                }
            }

            tbody {
                td {
                    vertical-align: top;
                    border: none;
                    border-bottom: 8px solid $color-gray-warm-4;
                }
            }

            thead,
            tbody {
                th,
                td {
                    &:first-child {
                        border-left: 10px solid $color-gray-warm-4;
                    }

                    &:last-child {
                        border-right: 10px solid $color-gray-warm-4;
                    }
                }
            }
        }
    }
}