.print-page-breaker{
	
	clear: both;
	overflow: hidden;
	width: 100%;
	page-break-before: always;

	@media print {
		page-break-before: always;
	}
}
