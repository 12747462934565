.boxed-faq {
  &.medium {
    @media #{$medium-up} {
      width: 50%;
      float: left;
    }
  }
  &.large {
    width: 100%;
  }
}

.boxed-faq-title {
  @include block-header();
  @extend .header-underline;
  @extend .header-margin-large;
}

.boxed-faq-content {
  @extend .block-padding;
  background-color: $color-warm-50;
  height: 100%;

  .link-single {
    margin-bottom: 0;
  }

  &.light-blue {
    background-color: $color-sea-blue-50;
  }

  &.blue {
    background-color: $color-sea-blue-900;

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    div,
    span {
      color: $color-white;
      border-bottom-color: $color-white;
    }
  }

  &.yellow {
    background-color: $color-orange-50;
  }

  &.light-green {
    background-color: $color-green-50;
  }
}

.faq-question {
  font-weight: bold;
}

.faq-answer,
.faq-question {
  > p {
    margin: 0;
  }
}

.faq-list-content {
  .boxed-faq.large & {
    @media #{$medium-up} {
      column-count: 2;
      column-gap: $spacing-large * 2;
    }
  }
}
.faq-list {
  margin: 0;
  padding: 0;
  > li {
    list-style: none;
    margin-bottom: $spacing-large;
  }
}
