.side-menu {
  padding: 0;
  margin: 0;

  &-toggle {
    width: 100%;
    margin: 2px auto;
    background: white;
    color: $color-blue-dark;
    border: 1px solid $color-blue-dark;
    font-family: inherit;
    font-size: inherit;
    padding: 0.5em;
    cursor: pointer;
    display: block;

    &:hover,
    &:focus {
      color: white;
      background: $color-blue-dark;
    }

    @media #{$medium-big-up} {
      display: none;
    }
  }

  &-toggle-close-text,
  &--expanded &-toggle-open-text {
    display: none;
  }

  &-toggle-open-text,
  &--expanded &-toggle-close-text {
    display: inline;
  }

  &-links {
    max-height: 0px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;

    @media #{$medium-big-up} {
      max-height: none;
    }
  }

  &--expanded &-links {
    max-height: 1000px;
    @include transition(max-height 0.3s ease-in-out);
  }

  &-link {
    @include flexbox;
    @include align-items(center);
    @include justify-content(left);
    @include transition(background 0.1s ease-in-out);
    color: black !important;
    background: white;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background: $color-gray-warm-3;
    }

    &--active {
      background: $color-gray-warm-3;
    }

    &--done {
      &:after {
        @include flex-shrink(0);
        @include transform(rotate(-46deg));
        box-sizing: content-box;
        content: " ";
        width: 11px;
        height: 6px;
        margin: 13px;
        border-left: 4px solid $color-blue-dark;
        border-bottom: 4px solid $color-blue-dark;
      }
    }

    &-number {
      @include flexbox;
      @include align-self(stretch);
      @include align-items(center);
      @include justify-content(center);
      @include transition(background 0.1s ease-in-out);
      color: inherit;
      min-width: 2.4em;
      overflow: hidden;
      max-width: 100%;
    }

    &--active &-number {
      color: white;
      background: $color-blue-dark;
    }

    &-text {
      @include flex-grow(1);
      font-weight: 300;
      padding: 0 20px;
      line-height: 1.3;
      font-size: 16px;
      display: block;
      margin: 1em 0;
    }

    &--active &-text {
      font-weight: bold;
    }

    &-change-response {
      display: none;
      @extend a;
      text-decoration: underline;
    }

    &--done &-change-response {
      display: block;
    }

    &--active &-change-response {
      display: none;
    }
  }
}
