.boxed-fact-list-container {
  @include cf();
  @extend .block-padding;
  margin-bottom: $spacing-large;
  background-color: $color-gray-warm-3;
}

.boxed-list-50 {
  width: 100%;
  padding-right: $spacing-small;
  padding-left: $spacing-small;
  padding-bottom: $spacing-medium;
  @media #{$medium-up} {
    float: left;
    width: 50%;
  }
}

.boxed-fact-list-header {
  @extend .header-underline;
  @extend .header-margin-large;
}

.boxed-fact-list-content {
  @include boxed-content-wysiwyg();
  padding-left: 40px;
  border-top: none;

  p, p *{
    @media #{$medium-up} {
      font-size: 19px;
      line-height: 30px;
    }
  }

  ul {
    margin: 0;
    margin-left: -10px;

    > li {
      padding-left: 10px;
    }

    &.list-links {
      margin-left: -30px;

      > li {
        padding: 0;
      }
    }

    &.list-clean li {
      padding: 0;
    }
  }
}
