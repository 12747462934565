.hearings-facts {
  @include clearfix();
  @extend .section-big;

  &-top {
    @extend .block-padding;
    background-color: $color-blue-dark;
  }

  &-text,
  &-deadline,
  &-title,
  &-title > div {
    @extend .block-header;
    margin-bottom: $spacing-medium;
    color: white;
  }

  &-document-link {
    @extend .link;
  }

  &-content {
    background-color: $color-blue-light-2;

    > a {
      margin: $spacing-large $spacing-large + $spacing-medium;
    }

    .accordion {
      border: none;
    }
  }

  &-accordion {
    border: none;

    .accordion-navigation {
      border-bottom: 1px solid black;

      > a {
        background: none;
        min-height: 48px;
        padding-left: $spacing-large + $spacing-medium;
        padding-right: 60px;

        &:hover {
          background: none;
        }

        &:after {
          top: 15px;
          right: $spacing-large + $spacing-medium;
          height: 20px;
          width: 20px;
          background-image: url("../assets/images/arrow-right-black.svg");
          background-position: center;
          background-size: 11px;
          transform: rotate(90deg);
        }

        > div {
          @extend .block-header;
          color: black;
        }
      }
      &.active {
        .content {
          background: none;
        }

        > a {
          border-bottom: 1px solid $color-gray-warm;

          &::after {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
