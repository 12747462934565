.accordion-block {
  border: none;
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  overflow: hidden;

  &__item {
    border-top: none;
    clear: both;
    margin-bottom: 10px;
  }

  &__item {
    background-color: $color-gray-warm-3;
  }

  &.yellow &__item {
    background-color: $color-orange-50;
  }

  &.light-green &__item {
    background-color: $color-green-50;
  }

  &.green &__item {
    background-color: $color-green;
  }

  &.blue &__item {
    background-color: $color-sea-blue-900;
    color: #fff;

    * {
      color: inherit;
    }
  }

  &.light-blue &__item {
    background-color: $color-sea-blue-50;
  }

  &__item {
    .temaveileder--toc & {
      margin-bottom: 0;
    }

    .temaveileder--top & {
      margin-bottom: 5px;
      background-color: $color-white;
    }
  }

  &__item-navigation {
    padding: 15px 60px 15px 30px;
    display: block;
    position: relative;
    color: $color-blue-dark;
    text-decoration: none;

    > div {
      // @extend .block-header;
      color: $color-text-gray;
      &.block-header {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        @media #{$large-up} {
          font-size: $font-size-base;
        }
      }
    }

    &:hover,
    &:focus {
      color: #000;
    }

    &:after {
      content: "";
      background: url(../assets/images/arrow-accordion.svg) 50% 50% no-repeat;
      background-size: 52px 26px;
      display: block;
      height: 26px;
      width: 16px;
      overflow: hidden;
      top: 50%;
      position: absolute;
      right: 35px;
      transform: translateY(-50%) rotate(90deg);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      -ms-transition: -ms-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
    }
  }

  &.blue &__item-navigation:after {
    background-image: url(../assets/images/arrow-accordion-white.svg);
  }

  &__item_active &__item-navigation {
    &:after {
      transform: translateY(-50%) rotate(270deg);
    }
  }

  &__item-content {
    padding: 10px 30px;
    display: none;
  }
}
