.sticky-top-navigation{
    @include cf();
    background-color: white;
    width: 100%;
    padding-bottom: $spacing-medium;

    &.sticky{
        background-color: white;
        position: fixed;
        top: 0;
        padding-top: $spacing-medium;
        margin-left: -1.25rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
		z-index: 10;
        box-shadow: 0 8px 6px -6px rgba(0,0,0,0.3);
        
        button{
            margin: 0;
        }

        @media #{$large-up} {
            margin-right: 0px;
            padding-top: $spacing-medium;
        }

        .rules-top-banner{
            &.rules-top-banner-sticky{
                display: inline-block;
                margin: 0 0 10px;
                padding: 10px;
                * {
                    font-size: 18px;
                    margin:0;
                }
            }
            &.hidden{
                display: none;
            }
        }
    }

    .prev-button-container{
        float:left;
    }

    .next-button-container{
        float: right;
    }
    
    @media print{
        display: none;
    }

}