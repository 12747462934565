.search {
  .article-header {
    padding-top: 0;
  }

  .article-ingress {
    padding-bottom: 20px;
    padding-top: 0;
  }

  &-hits {
    margin: 0;
    margin-top: 5px;
  }
}
