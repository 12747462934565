.hearings-font-size{
	display: inline;

	&-link{
		font-size: 14px;
		font-weight: normal;
		color: $color-black;
		text-decoration: none;
		position: relative;

		&:after {
			$size: 20px;
			border-left: ($size*.7) solid transparent;
			border-right: ($size*.7) solid transparent;			
			border-bottom: $size solid $color-blue-light-2;
			bottom: -($size*0.8);
			content: ' ';
			display: none;
			height: 0;
			left: 50%;
			margin-left: -($size*.7);
			pointer-events: none;
			position: absolute;
			width: 0;
		}

		&:hover,
		&:focus{
			color: $color-black;
			text-decoration: underline;

			~ .hearings-font-size-panel{

				display: block;
			}

			&:after {
				display: block;
			}

		}
	}

	&-panel{
		position: absolute;
		top: 41px;
		right: 0px;
		z-index: 100;
		background: $color-blue-light-2;
		max-width: 430px;
		min-height: 124px;
		min-width: 200px;
		padding: 15px 20px;
		font-weight: 400;
		font-size: 18px;
		line-height: 26px;
		text-align: left;
		display: none;

		p{
			margin: 0;
		}
	}
}