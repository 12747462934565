ul.pagination {
  @include cf();
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  > li {
    margin-right: $spacing-medium;
    &:last-child {
      margin-right: 0;
    }
    float: left;
    list-style: none;

    > a {
      @extend .text-link-inline;

      &.active {
        font-style: italic;
        text-decoration: none;
      }
    }
  }
}
.pagination-box {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;

  @media (max-width: 400px) {
    display: grid!important;
    grid-template-columns: repeat(7, 1fr)!important;
    gap: 10px!important;
  }

  &__prev,
  &__next,
  &__item {
    &:empty {
      display: none;
    }

    a {
      color: $color-blue-dark;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      min-width: 42px;
      text-align: center;
      padding: 5px 12px 3px;
      border: 1px solid $color-blue-dark;
      text-decoration: none;
      display: flex;
      justify-content: center;

      @media (max-width: 400px) {
        min-width: 100%!important;
        height: auto!important;
        aspect-ratio: 1!important;
        padding: 0!important;
      }

      &:hover {
        color: white;
        background-color: $color-blue-dark;
      }
      &.active {
        color: white;
        background-color: $color-blue-dark;
        &:hover {
          color: $color-blue-dark;
          background-color: #fff;
        }
      }
    }
    &--neutral {
      a {
        border-color: transparent!important;

        @media (max-width: 400px) {
          min-width: 30px!important;
          padding: 0!important;
        }
      }
    }
  }
  &__prev {
    @media #{$small-only}{
      a:before {
        content: "<";
      }
      span {
        opacity: 0;
        position: fixed;
        left: -100px;
      }
    }
  }
  &__next {
    @media #{$small-only} {
      a:before {
        content: ">";
      }
      span {
        opacity: 0;
        position: fixed;
        left: -100px;
      }

    }
  }

  > li {
    &:last-child {
      margin-right: 0;
    }
    float: left;
    list-style: none;

    > a {
      color: $color-blue-dark;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      min-width: 42px;
      text-align: center;
      padding: 5px 12px 3px;
      border: 1px solid $color-blue-dark;

      text-decoration: none;
      &:hover {
        color: white;
        background-color: $color-blue-dark;
      }
      &.active {
        color: white;
        background-color: $color-blue-dark;
        &:hover {
          color: $color-blue-dark;
          background-color: #fff;
        }
      }
    }
  }
}