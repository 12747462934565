.frontpage-hero {
	&__intro {
		margin-bottom: 40px;
		padding: 20px 15px 0 15px;

		@media #{$medium-up} {
			margin-bottom: 60px;
			padding: 0;
		}

		.title {
			display: block;
			width: 50rem;
			max-width: 100%;

			h1,
			h2,
			h3,
			p {
				font-size: 28px;
				line-height: 42px;
				color: $color-sea-blue-900;
				font-weight: 400;

				@media #{$medium-up} {
					font-size: 32px;
					line-height: 44px;
				}

				strong,
				b {
					font-weight: 600;
				}
			}
		}
	}

	&__wrapper {
		background-color: $color-sea-blue-50;
		padding: 20px 40px;
		margin-left: -40px;
		width: calc(100% + 80px);

		.search-component {
			@media only screen and (max-width: 52.5624em) {
				margin-left: -20px;
				width: calc(100% + 40px);
				max-width: calc(100% + 40px);
			}

			@media only screen and (max-width: 40.0624em) {
				margin-left: 0;
				width: 100%;
				max-width: 100%;
			}
		}
	}

	&__links {
		margin-top: 40px;
		margin-bottom: 60px;

		@media #{$medium-up} {
			margin-top: 50px;
		}

		ul {
			display: grid;
			margin: 0;
			padding: 0 15px;
			gap: 20px;

			@media #{$medium-up} {
				grid-template-columns: 1fr 1fr;
				padding: 0;
			}

			li {
				display: block;
				margin: 0;
				padding: 0;

				&::marker {
					content: none;
				}

				a {
					display: flex;
					align-items: flex-start;
					gap: 12px;
					text-decoration: none;
					width: fit-content;

					@media #{$medium-up} {
						gap: 16px;
					}

					&:hover,
					&:focus-visible {
						text-decoration: underline;
					}

					img,
					svg {
						display: block;
						width: 32px;
						min-width: 32px;
						height: 32px;
						object-fit: contain;
						transform: translateY(-5px);

						@media #{$medium-up} {
							width: 40px;
							min-width: 40px;
							height: 40px;
						}
					}

					span {
						font-size: 18px;
						line-height: 27px;
						font-weight: 600;
						hyphens: auto;
						color: $color-sea-blue-900;

						@media #{$medium-up} {
							font-size: 24px;
							line-height: 36px;
						}
					}
				}
			}
		}
	}
}