// @import "normalize";

// Foundation
@import "foundation/settings";
@import "foundation";

// Slick slider
@import "vendor/slick";

// Fonts
@import "fonts";

// Variables
@import "vars";

// Functions
// @import "functions";

// ! typography must be imported before mixins
@import "redesign2020/typography";

// Mixins
@import "mixins";

// Atoms, molecules, organisms and templates
@import "components";

// Other site styles
@import "site";
@import "icons";
@import "accordion";

// redesign 2020
@import "redesign2020/grid";
@import "redesign2020/boxed-block";
@import "redesign2020/boxed-top-task";
@import "redesign2020/header";
@import "redesign2020/link-list";
@import "redesign2020/search";
@import "redesign2020/subscription";
@import "redesign2020/temaveileder";
@import "redesign2020/transport";
@import "redesign2020/veiviser";
@import "redesign2020/key-figures";
@import "redesign2020/front-page-intro";
@import "redesign2020/search-component";
@import "redesign2020/frontpage-hero";
@import "redesign2020/news-frontpage";

// renovation
@import "renovation/renovation";

// xp-components (new components created in Enonic)
@import "xp-components/list-hearings";
@import "xp-components/hearings";

iframe [data-contentgroup] {
  transition: none;
}
