$header-btn-height: 53px;

.header {
	@extend .content-container;
	position: relative;
	background-color: $color-blue-light-2;

	&--white {
		background-color: transparent;
	}

	&.open {
		background-color: $color-blue-light-2;

		.header-content {
			display: none;
		}

		.header-fs-menu {
			display: flex;
		}
	}

	&-content {
		@extend .content, .main-content;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;

		@media #{$medium-up} {
			padding-top: 10px;
		}

		.header & {
			padding-top: 20px;
			padding-bottom: $spacing-small;

			@media #{$medium-up} {
				padding-top: 10px;
			}
		}

		.logo-container {
			width: 85px;
		}

		.search-global-container {
			margin: $spacing-large + $spacing-medium 0;
			padding: 0;

			input.search-form-input {
				margin: 0;
			}

			.search-form-submit-button {
				font-size: $button-font-size-base;
			}
		}

		@media #{$medium-up} {
			.header & {
				padding-top: $spacing-medium;
				padding-bottom: $spacing-medium;
			}

			.logo-container {
				width: 100px;
				margin-bottom: 0;
			}

			.search-global-container {
				flex: 1 1 auto;
				height: $header-btn-height;
				margin: auto;
				padding: 0 $spacing-medium;

				.form-group {
					height: 100%;
				}
			}
		}
  	}

	&-right {
		display: flex;
		align-items: center;
		gap: 40px;

		.header-search-toggle,
		.header-menu-toggle {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 17px;
			padding: 0;
			margin: 0;
			font-size: 18px;
			line-height: 22px;
			font-weight: 500;
			background-color: transparent;
			color: $color-sea-blue-900;

			.icon-search {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 22px;
				min-width: 22px;
				height: 22px;
				background: url(../assets/images/search.svg) center center / contain no-repeat;
			}

			.icon-menu {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: column;
				width: 22px;
				min-width: 22px;
				height: 16px;

				span {
					display: block;
					width: 100%;
					height: 2px;
					background-color: $color-sea-blue-900;
					transition: .3s ease-in-out;
				}
			}
		}

		.header-search-toggle {
			display: none;
			
			@media #{$medium-up} {
				display: flex;
			}
		}

		.header-menu-toggle {
			&:hover,
			&:focus-visible {
				.icon-menu {
					span {
						&:first-child {
							transform: translateY(14px);
						}

						&:last-child {
							transform: translateY(-14px);
						}
					}
				}
			}
		}
	}

	&-menu {
		z-index: 1000;
		display: none;
		width: 100%;
		overflow: hidden;
		transition: all 0.2s ease-in-out;
		background-color: $color-blue-light-2;

		&.open,
		.open & {
			display: block;
		}

		&-content {
			display: grid;
			padding: 60px 20px 100px 20px;

			@media #{$medium-up} {
				grid-template-columns: 1fr 1fr;
				gap: 20px;
				padding-top: 75px;
			}

			@media #{$large-up} {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&-list {
			padding: 0;
			margin: 0;

			&.left {
				> li {
					&:not(:last-child) {
						margin-bottom: 22px;

						@media #{$medium-up} {
							margin-bottom: 47px;
						}
					}
				}	
			}

			&.right {
				> li {
					margin-top: 22px;

					@media #{$medium-up} {
						margin-top: 0;
					}

					&:not(:last-child) {
						@media #{$medium-up} {
							margin-bottom: 35px;
						}
					}
				}	
			}

			> li {
				position: relative;

				&::marker {
					content: none;
				}

				.icon-and-title {
					display: flex;
					align-items: center;
					gap: 15px;

					@media #{$medium-up} {
						margin-bottom: 16px;
					}

					img,
					svg {
						display: block;
						width: 32px;
						min-width: 32px;
						height: 32px;
						object-fit: contain;

						@media #{$medium-up} {
							width: 40px;
							min-width: 40px;
							height: 40px;
						}
					}
	
					.h1-link {
						font-size: 18px;
						line-height: 27px;
						font-weight: 600;
						text-decoration: none;

						@media #{$medium-up} {
							font-size: 24px;
							line-height: 36px;
						}

						&:hover,
						&:focus-visible {
							text-decoration: underline;
						}
					}
				}

				> ul {
					display: none;
					margin: 0;
					padding: 0 0 0 30px;

					@media #{$medium-up} {
						display: block;
					}

					li {
						display: block;

						&::marker {
							content: none;
						}

						a {
							position: relative;
							display: block;
							width: fit-content;
							text-decoration: none;
							padding-left: 30px;

							&:hover,
							&:focus-visible {
								text-decoration: underline;
							}

							&:before {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 20px;
								min-width: 20px;
								height: 20px;
								background: transparent url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.6856 9.99942L6.43408 4.75666L7.94603 3.24219L14.7145 9.99942L7.94603 16.7567L6.43408 15.2422L11.6856 9.99942Z" fill="%23003045"/></svg>') center center / contain no-repeat;

								@media #{$large-up} {
									top: 4px;
								}
							}
						}
					}
				}
			}
		}
	}

	&-fs-menu {
		display: none;
		@extend .content, .main-content;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		.fs-menu-top {
			display: flex;
			align-items: stretch;
			padding-top: $spacing-small;
			padding-bottom: $spacing-small;
			width: 100%;
			gap: 20px;
			flex-direction: column-reverse;

			@media #{$medium-big-up} {
				align-items: flex-start;
				flex-direction: row;
				justify-content: space-between;
			}

			.search-component {
				margin-top: 20px;

				@media #{$medium-up} {
					margin-top: 29px;
				}
			}

			.close-menu {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 17px;
				padding: 0;
				margin: 55px 15px 0 0;
				font-size: 18px;
				line-height: 22px;
				font-weight: 500;
				background-color: transparent;
				color: $color-sea-blue-900;
				align-self: flex-end;

				@media #{$medium-up} {
					margin-right: 0;
				}

				@media #{$medium-big-up} {
					align-self: auto;
				}

				&:hover,
				&:focus-visible {
					.icon-menu {
						transform: rotate(180deg);
					}
				}

				.icon-menu {
					position: relative;
					display: block;
					width: 22px;
					min-width: 22px;
					height: 16px;
					transition: .3s ease-in-out;
					margin-top: -2px;

					span {
						position: absolute;
						top: 50%;
						left: 50%;
						display: block;
						width: 100%;
						height: 2px;
						background-color: $color-sea-blue-900;

						&:first-child {
							transform: translate(-50%, -50%) rotate(45deg);
						}

						&:last-child {
							transform: translate(-50%, -50%) rotate(-45deg);
						}
					}
				}
			}
		}

		.fs-menu-content {
			.header-menu-content {
				.header-menu-list {
					list-style: none;
				}
			}
		}
	}
}

// mobile fix
.mobile-view-only {
	header {
	  	.header-content {
			margin: 0 auto;
			padding: 20px 20px 5px 20px;
			max-width: 1350px;
			font-size: 16px;

			@media #{$medium-up} {
				font-size: 20px;
				padding: 10px 20px;
			}
	  	}

		.header-fs-menu {
			margin: 0 auto;
			padding: 0 5px;
			max-width: 1350px;
			font-size: 16px;

			@media #{$medium-up} {
				padding: 0 20px;
				font-size: 20px;
			}
		}
	}
}