

.print-back-page{
	margin: 0;
	border: initial;
	border-radius: initial;
	width: initial;
	min-height: initial;
	box-shadow: initial;
	background: initial;
	height: 240mm;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	-webkit-column-break-inside: avoid;
          page-break-inside: avoid;
			   break-inside: avoid;
			   
	&__text-content{
		width: 100%;
		padding:  0 15mm;
		margin-bottom: 60px;
	}

	&__site-title{
		border-top: 1px solid #96adc3;
		line-height: 1.2em;
		font-size: 18px;
		font-weight: 400;
		padding: 10px 0 0;
		
	}

	&__site-domain{
		line-height: 1.2em;
		font-size: 16px;
		font-weight: 600;
	}


	&__image{
		display: block;
		width: 100%;
	}
	
}