.details {
  &-summary {
    color: $color-blue-dark;
    text-decoration: underline;
    display: inline-block;

    &:hover,
    &:focus {
      color: $color-blue-dark;
      text-decoration: underline;
    }

    &:focus {
      outline-color: #54acb8;
    }

    &:before {
      content: " ";
      border-style: solid;
      border-width: 6px 0 6px 10px;
      border-color: transparent transparent transparent $color-blue-dark;
      display: inline-block;
      margin: 0 9px 0 0;
    }
  }

  &--expanded &-summary::before {
    transform: rotate(90deg);
  }

  &-content {
    display: none;

    h3,
    h4 {
      font-size: 1em;
      margin-bottom: 0;
      margin-top: 1em;
      font-style: normal;
      font-weight: bold;
    }

    p {
      margin: 0 0 1em;
    }
  }

  &--expanded &-content {
    display: block;
  }
}
