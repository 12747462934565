.boxed-checklist{
	&.large{
		width: 100%;
	}
}

.boxed-checklist-title{
	@include block-header();
	@extend .header-underline;
	@extend .header-margin-large;
}
.boxed-checklist-content{
	background-color: $color-gray-warm-3;
	padding: $spacing-large;
	@media #{$medium-up} {
		padding: $spacing-large+$spacing-medium;
	}
	
	.columns{
		@include boxed-content-wysiwyg();
	}
}
