

.print-front-page{
	margin: 0;
	border: initial;
	border-radius: initial;
	width: initial;
	min-height: initial;
	box-shadow: initial;
	background: initial;
	height: 200mm;  
	position: relative;
	padding-top: 40mm; 

	&__text-content{
		width: 100%;
		padding:  0 30mm;
	}

	&__document-name{
		margin: 0 0 20px;
		padding: 20px 0;
		font-family: $print-header-font-family;
		font-weight: 300;
		font-size: 35px;
		line-height: 1.1em;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		
	}

	&__chapter-name{
		font-size: 18px;
		font-family: $print-header-font-family;
		font-weight: 400;
		line-height: 1.35em;
		color: $color-sea-green;
		border-bottom: 1px solid #96adc3;
		padding-bottom: 30px;
	}

	&__date{
		font-family: $print-header-font-family;
		line-height: 1.2em;
		font-size: 14px;
		font-weight: 400;
		padding: 5px 0 0;
	}


	&__image{
		position: absolute;
		display: block;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	
}