.print-preview-overlay{
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(#fff, 0.96);
    text-align: center;
    transition: opacity 1s ease;
    opacity: 1;

    .print-preview-is-loaded &{
        //opacity: 0;
    }
    &-text{
        @include vertical-align(absolute);
        left: 0;
        width: 100%;
        padding: 30px;
        text-align: center;
        font-weight: bold;
    }

    @media print{
        display: none;
    }
}