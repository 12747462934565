.announcement-container {
  background-color: $color-error;
  text-align: center;
  padding: $spacing-large + $spacing-medium;

  a {
    color: $color-white;
  }

  p {
    margin: 2px 0;
    color: $color-white;

    &.big {
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
