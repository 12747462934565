.search-form {
  position: relative;
  max-width: 950px;
  margin: 0 auto;

  &-label {
    @include hide();
  }

  &-input {
    padding-right: 40px;
  }

  &_local &-input {
    padding-right: 70px;
    border: none;
    height: 40px;
    line-height: 40px;
    font-size: $font-size-rules;
    color: #4a4a4a;
  }

  &-submit-button.button,
  &-submit-button {
    letter-spacing: 1px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    height: 56px;
    max-height: 100%;
  }

  &_local &-submit-button {
    letter-spacing: 1px;
    position: absolute;
    border: none;
    right: 0;
    top: 0;
    margin: 0;
    background: url(../assets/images/search-black.svg) 75% center no-repeat;
    background-size: 22px 22px;
    text-align: center;
    padding-right: 0;
    padding-left: 0;
    width: 70px;
    height: 40px;
    color: transparent;
    overflow: hidden;

    &:hover {
      background: $color-blue-dark;
      text-indent: 0px;

      span {
        color: white;
        display: block;
      }
    }
  }

  &_global {
    position: relative;
    width: 100%;
    height: 100%;
    margin-right: 0;


    @media #{$medium-up} {
      max-width: 516px;
    }

    // &::before {
    //   content: " ";
    //   position: absolute;
    //   left: calc(#{$spacing-medium} + #{$spacing-small});
    //   top: 50%;
    //   transform: translateY(-50%);
    //   background-image: url("../assets/images/search-black-redesign2020.svg");
    //   background-position: center;
    //   background-size: contain;
    //   background-repeat: no-repeat;
    //   height: 20px;
    //   width: 19px;

    //   @media #{$large-up} {
    //     height: 29px;
    //     width: 29px;
    //   }
    // }

    .search-form-input {
      height: auto;
      padding: $spacing-medium calc(#{$spacing-medium} + #{$spacing-small});
      // padding-left: 50px;
      background-color: $color-white;
      height: 100%;

      .open & {
        background-color: $color-white;
      }
    }

    .search-form-submit-button {
      height: 100%;
      font-size: 22px;
      font-weight: 400;
      border: 1px solid $color-blue-dark;
      padding: $spacing-medium calc(#{$spacing-medium} * 3);
    }
  }
}
