div,
body,
.body-text {
  line-height: 1.6;
  font-family: $body-font-family;
  color: $color-text-gray;
  overflow-wrap: break-word;
  font-size: $font-size-base-mob;
  @media #{$large-up} {
    font-size: $font-size-base;
  }
}

p,
.text {
  @extend .body-text;
  font-weight: normal;
  font-style: normal;

  &-link {
    @extend .text;
    font-weight: 400;
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }

    &-inline {
      font-size: $font-size-large-mob;
      line-height: 1.56;
      // text-decoration: underline;
      @media #{$large-up} {
        font-size: $font-size-large;
        line-height: 1.58;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &-breadcrumb {
    font-family: $body-font-family;
    font-size: $font-size-mini;
    line-height: 2.15;
    font-weight: bold;
    font-style: normal;
  }

  &-descriptor {
    font-family: $body-font-family;
    font-size: $font-size-mini;
    line-height: 2.15;
    font-weight: 400;
    font-style: normal;
  }

  &-image-description {
    font-family: $body-font-family;
    font-size: $font-size-mini;
    line-height: 1.4;
    font-weight: 400;
    font-style: italic;
  }
}

a,
.link {
  @extend .body-text;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  // ie9 fix
  img {
    border: none;
    outline: none;
  }

  &-out:after {
    content: "";
    width: 19px;
    height: 19px;
    display: inline-block;
    background: url("../assets/images/link-external-redesign2020.svg") no-repeat
      center center;
    background-size: 18px;
    margin-left: 5px;
    margin-bottom: -1px;

    @media print {
      background: none;
      width: auto;
      display: inline;
      height: auto;
    }
  }
}

h1,
.h1,
h2,
.h2,
.block-header {
  font-family: $header-font-family;
  line-height: 1.42;
  font-style: normal;
}

h1,
h2,
h3 {
  margin: 0;
}

article.article {
  h2:not([class]),
  h3:not([class]),
  h4:not([class]) {
    margin: $spacing-large + $spacing-medium 0 $spacing-large;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5 {
  color: $color-blue-dark;
}

h1,
.h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.5;
  @media #{$large-up} {
    font-size: 32px;
    line-height: 1.38;
  }
}

.h1-link {
  @extend .h1;
}

h2,
.h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  @media #{$large-up} {
    font-size: 24px;
  }

  &-small {
    font-size: 20px;
    font-weight: 500;

    @media #{$large-up} {
      font-size: 22px;
    }
  }
}

.h2-link {
  @extend .h2;
}

h3,
.h3 {
  @extend .body-text;
  font-size: 16px;
  font-weight: 600;

  @media #{$large-up} {
    font-size: 20px;
    line-height: 1.5;
  }
}

.h1-link,
.h2-link,
.h3-link {
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    text-decoration: none;

    // color: $color-blue-dark;
  }
}

.block {
  &-padding {
    padding: $spacing-large;

    @media #{$medium-up} {
      padding: $spacing-large $spacing-medium + $spacing-large;
    }
  }

  &-header {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.7;
    color: $color-blue-dark;

    .boxed-top-task & {
      font-size: 20px;
      font-weight: normal;
      color: black;
      line-height: 1.6;

      @media #{$large-up} {
        font-size: 32px;
      }
    }
  }
}

.article{
  >div{
    >p a, >a, >.link, >ul li a, >ol li a  {
      color: $color-hyperlink;

      &:visited{
        color: $color-visited-link;
      }
    }
  }
}