ul.list-links {
  margin: 0;
  padding: 0;
  margin-bottom: $spacing-large;

  > li {
    list-style: none;
    background-repeat: no-repeat;

    -webkit-print-color-adjust: exact;
    margin-bottom: $spacing-small;

    &:last-child {
      margin-bottom: 0;
    }

    > a {
      @extend .icon-before;
      @extend .icon-small;
      @extend .icon-arrow;
      position: relative;
      display: inline-block;
      padding-left: 30px;
      text-decoration: none;
      font-size: 19px;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &.active {
        font-weight: bold;
        font-style: italic;
        color: #000;
      }
    }

    .article:not(body) & {
      margin-bottom: 0;

      a {
        text-decoration: underline;
        
        &:hover {
          text-decoration: none;
        }
      }
    }


    @media print {
      background-image: none;
      content: ">";
    }
  }
}
