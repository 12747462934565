.key-figures {
    line-height: 26px;

    &__upper {
        padding: 20px;
        font-weight: bold;
    }

    &__lower {
        padding: 10px 20px 20px;
    }

    &__line1,
    &__line2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__line1 {
        padding-bottom: 20px;
        font-size: 30px;
        line-height: 30px;
        border-bottom: 2px solid #000;

        @media #{$large-up} {
            font-size: 48px;
            line-height: 48px;
        }
    }
    &__line2 {
        padding-top: 20px;
    }

    &--side-by-side {
        @media #{$large-up} {
            display: flex;
            gap: 52px;
        }

        .key-figures {
            @media #{$large-up} {
                flex-basis: calc(50% - 26px);
            }

            &__line1 {
                min-height: 86px;
                font-size: 20px;
                line-height: 26px;
            }
    
            &__line2 {
                font-size: 30px;
                line-height: 30px;

                @media #{$large-up} {
                    font-size: 48px;
                    line-height: 48px;
                }
            }
        }
    }

    &--yellow {
        background-color: $color-yellow-light;
    }

    &--blue {
        background-color: $color-blue-light-2;
    }

    &--red {
        background-color: $color-orange-light;
    }

    &--green {
        background-color: $color-lime-green-light;
    }

    &__icon-arrow-up,
    &__icon-arrow-down {
        display: inline-block;
        height: 25px;
        width: 25px;

        @media #{$large-up} {
            height: 35px;
            width: 35px;
        }

        &::after {
            content: "";
            background: url(../assets/images/arrow-keyfigures.svg) center center no-repeat;
            background-size: 100%;
            display: block;
            height: 25px;
            width: 25px;
 
            @media #{$large-up} {
                height: 35px;
                width: 35px;
            }
        }
    }
    &__icon-arrow-down {
        transform: rotate(90deg);
    }

    &--side-by-side {
        &__icon-arrow-up,
        &__icon-arrow-down {
            top: 66%;
        }
    }
}