.news {
  padding: $spacing-large 0;

  &-container {
    @include cf();
    margin: 0;
    padding: $spacing-large 0;
    border-top: 1px solid $color-gray-warm;
    border-bottom: 1px solid $color-gray-warm;
    list-style: none;
  }

  &-title {
    @extend .text-link;
    color: $color-black;
  }

  &-image,
  &-text {
    float: left;
  }

  &-image {
    @include image-link-hover();
    width: 40%;

    img {
      min-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &-text {
    width: 60%;
    padding: $spacing-medium $spacing-large;
    color: $color-black;

    .meta {
      font-size: $font-size-small;
      margin-bottom: $spacing-small;
    }

    &:first-child {
      width: 100%;
    }
  }
}
