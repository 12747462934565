.hearings-pager{
	margin-bottom: $spacing-large;
	ul{
		display: inline;
		margin: 0;

		.article &{
			margin: 0;
		}
	}

	li{
		list-style: none;
		display: inline;
	}

	a,
	span{
		margin: 0 $spacing-medium 0 0;
		font-size: 16px;
	}
}