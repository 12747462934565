footer {
	z-index: 10;
	font-family: $body-font-family;
	line-height: 1.6;
	font-size: $font-size-base-mob;

	@media #{$large-up} {
		font-size: $font-size-base;
	}

	p,
	.paragraph {
		margin: 0;
		margin-bottom: $spacing-large;
		font-size: inherit;
	}

	.logo-desktop {
		float: right;
		width: auto;
		height: 132px;
	}

	.logo-mobile {
		width: auto;
		height: 132px;

		@media #{$medium-up} {
			float: right;
		}

		@media #{$large-up} {
			display: none;
		}
	}
}

.footer {
	&-header {
		margin-top: $spacing-large + $spacing-medium;
		margin-bottom: $spacing-large;
		color: inherit;
		font-weight: 500;
		line-height: 1.35;
		font-size: $font-size-small;

		@media #{$medium-up} {
			margin-top: 0;
			font-size: 22px;
		}
	}

	&-content {
		background-color: $color-blue-dark;
		padding-top: $spacing-large * 2;
		padding-bottom: $spacing-large;
		color: white;

		.row {
			> div:not(:first-child) {
				margin-top: 20px;
				
				@media #{$medium-up} {
					margin-top: 0;
				}
			}
		}

		div,
		span,
		p,
		a {
			margin-bottom: $spacing-medium;
			line-height: 1.6;
			font-size: $font-size-base - 3px;
			color: inherit;

			@media #{$medium-up} {
				margin-bottom: $spacing-large;
				font-size: 20px;
				line-height: 32px;
			}
		}

		div {
			p:not(:first-child):has(strong) {
				margin-top: $spacing-medium;

				@media #{$medium-up} {
					margin-top: $spacing-large;
				}
			}
		}

		p {
			margin-bottom: 0;

			strong,
			b {
				display: block;
				margin-bottom: $spacing-medium;

				@media #{$medium-up} {
					margin-bottom: $spacing-large;
				}
			}
		}

		a {
			position: relative;
			margin-bottom: 0;
			text-decoration: none;
			padding-left: 30px;
			display: block;
			width: fit-content;

			&:before {
				content: '';
				position: absolute;
				top: 3px;
				left: 0;
				display: block;
				width: 20px;
				min-width: 20px;
				height: 20px;
				transform: translateY(-1px);
				background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.6856 9.99942L6.43408 4.75666L7.94603 3.24219L14.7145 9.99942L7.94603 16.7567L6.43408 15.2422L11.6856 9.99942Z" fill="%23fff"/></svg>');
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				background-color: transparent;

				@media #{$medium-up} {
					top: 5px;
				}
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin-bottom: $spacing-medium;

				a {
					font-size: $font-size-large - 3px;
				}

				@media #{$medium-up} {
					margin-bottom: $spacing-large + $spacing-small;

					a {
						font-size: $font-size-base-mob;

						@media #{$large-up} {
							font-size: $font-size-base;
						}
					}
				}
			}
		}
	}
}

.footer-print {
	display: none;
}

@media print {
	.footer {
		display: none;
	}
}