.boxed-responsible{
	@include cf();
	width: 25%;

	&.large{
		padding: $spacing-medium + $spacing-large;
		width: 50%;
	}

	&.small{
		width: 100%;
		@media #{$medium-up} {
			width: 50%;
			float: left;
		}
		@media #{$large-up} {
			width: 25%;
			float: left;
		}	
	}

	a{
		height: 100%;
		display: block;
		color: $color-black;
		text-decoration: none;
		background-color: $color-gray-warm-3;

		&:hover,
		&:focus{
			color: $color-black;
			transition: background-color 0.3s;
			background-color: darken($color-gray-warm-3, 2%);
		}
	}
}
.boxed-responsible-content{
	background-color: $color-blue-light-2;
	height: 100%;
	text-decoration: none;
	padding: $spacing-large;
	font-size: 19px;
	@media #{$medium-up} {
		padding: $spacing-large+$spacing-medium;
	}
}

.boxed-responsible-header{
	margin-bottom: 10px;
}
.boxed-responsible-image{
	max-width: 95px;
    height: auto;
}

.boxed-responsible-text{
	margin-top: $spacing-medium;
	p{
		margin: 0;
	}
	.link {
		font-size: 19px;
	}
}

.boxed-image-image{
	@include image-link-hover();
}