.hearings-contact-persons {
  @include clearfix();
  @extend .section-big;
  background-color: $color-gray-warm-3;
  padding: $spacing-medium + $spacing-large;

  &-title {
    @extend .block-header;
    @extend .header-margin-large;
    padding-bottom: $spacing-medium;
    border-bottom: 1px solid $color-gray-warm;
  }

  &-list,
  .article &-list {
    list-style: none;
    margin: 0;

    > li {
      @include cf();
      margin: 0 0 $spacing-large;
      padding: 0 0 $spacing-large;
      border-bottom: 1px solid $color-gray-warm;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &-image {
    display: block;
    clear: both;
    max-height: 105px;
    max-width: 110px;
    margin-bottom: $spacing-medium;
  }

  &-name {
    font-weight: 600;
    font-style: normal;
    font-size: $font-size-base-mob;
    @media #{$large-up} {
      font-size: $font-size-base;
    }
  }

  &-position,
  &-department,
  &-phone,
  &-mail {
    @include paragraph();
    margin-bottom: 0;
    font-size: $font-size-base-mob;
    @media #{$large-up} {
      font-size: $font-size-base;
    }
  }
}
