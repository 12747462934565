ul,
.list,
ol {
  margin: 0;
  margin-left: 20px;
  padding: 0;
  margin-bottom: $spacing-large;
  // margin-left: $spacing-large;
  // font-size: 18px;

  > li {
    line-height: 1.6;

    @media print {
      line-height: 1.4;
    }
  }
}

.article:not(body) ul.list,
.article:not(body) ul,
.article:not(body) ol,
.article:not(body) ol,
.accordion ul.list,
.accordion ol.list {
  list-style-position: outside;
}

// list-styles
ul {
  list-style: disc;
}

ol {
  list-style: decimal;
  ol {
    list-style: lower-alpha;
    margin-bottom: 0px;
  }
}
ol.list-letters {
  list-style: lower-alpha;
}

// .article ul.list,
// .article ul,
.accordion ul.list {
  li {
    list-style-type: disc;
  }
}

ul.list-clean,
ul.list-links {
  margin-left: 0;
  list-style-type: none !important;
  list-style: none !important;

}

// article list (redesign 2023)
.article{
  ul {
    &:not([class]){
      list-style: none;
    
      li{
        &::before{
          content: "\2022"; 
          color: $color-sea-blue-900;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
}