.contact-person-list-image-container,
.contact-person-list-text{
	display: inline-block;
	vertical-align: middle;
}

.contact-person-list-image-container{
	width: 35%;
}

.contact-person-list-text{
	width: 60%;
}