.sidebar-navigaton {
	width: 100%;
	margin-bottom: $spacing-xlarge;

	&--top {
		@extend .sidebar-non-accordion;
	}

	&--heading {
		@extend .block-header, .icon-before, .icon-large, .icon-info;
		margin: 0;
	}

	&--content {
		@extend .accordion, .accordion-primary, .sidebar-accordion-navigation;
	}

	&--item {
		@extend .accordion-navigation;
	}
}