.boxed-list {
  width: 100%;

  &-title {
    @extend .block-header;
    @extend .header-underline;
    @extend .header-margin-large;
  }

  &-content {
    @include boxed-content-wysiwyg();
    @extend .block-padding;
    background-color: $color-gray-warm-3;
    height: 100%;

    ul.list-links {
      margin: 0;
    }
  }

  &-image-container {
    background-color: $color-blue-light;
    background-position: center;
    background-size: cover;
  }

  // Large with image
  &.large {
    @media #{$medium-up} {
      display: table;

      .boxed-list-content.with-image {
        display: table-cell;
        width: 50%;
      }

      .boxed-list-image-container {
        display: table-cell;
      }
    }
  }

  &.light-blue {
    .boxed-list-content{
      background-color: $color-sea-blue-50;
    }
  }

  &.blue {
    .boxed-list-content{
      background-color: $color-sea-blue-900;

      ul.list-links{
        li{
          a{
            &::before{
              background-image: url(../assets/images/arrow-accordion-white.svg);
            }
          }
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    div,
    span {
      color: $color-white;
      border-bottom-color: $color-white;
    }
  }

  &.yellow {
    .boxed-list-content{
      background-color: $color-orange-50;
    }
  }

  &.light-green {
    .boxed-list-content{
      background-color: $color-green-50;
    }
  }
}
