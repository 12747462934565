.signature{
	width: 100%;
	max-width: 300px;
	margin: 80px auto 30px 0px;
	border-top: 0.5px solid #000;
	display: none;

	@media print{
		display: block;
	}
}