.desktop-main-nav-container{
    background: #fff;
    margin-bottom: $spacing-large*2;
    position: relative;
}

ul.desktop-main-nav-triggers{
	@include cf();
	margin: 0;
	margin-left: 70px;
	text-align: center;
	padding: $spacing-large*1.5 $spacing-large $spacing-large*1.5 0;

	> li{
		// float: left;
		list-style: none;
		// margin-right: $spacing-small;
		display: inline-block;
		&:last-child{
			float: right;
		}
		> a{
			// @extend .header-bold;
			font-family: $body-font-family;
			font-weight: 200;
			text-transform: uppercase;
			text-decoration: none;
			color: $color-blue-dark;
			font-size: 18px;
			@media screen and (min-width: 1150px){
				font-size: 20px;
			}
			line-height: 1.5;
			padding: $spacing-medium $spacing-large - $spacing-small;
    		display: block;
			position: relative;
			
			&.active,
			&.user-position{
				color: $color-sea-green-dark;
			}
			// &.main-nav-arrow{
			// 	padding: $spacing-medium $spacing-large;
			// 	// &:before{
			// 	// 	content: "";
			// 	// 	background: url(../assets/images/arrow-blue-green.svg) 50% 50% no-repeat;
			// 	//     background-size: contain;
			// 	//     display: block;
			// 	//     height: 14px;
			// 	//     width: 14px;
			// 	//     position: absolute;
			// 	//     right: 15px;
			// 	//     top: 11px;
			// 	//     transform: rotate(90deg);
			// 	//     -ms-transform: rotate(90deg);
   //  // 				filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);

			// 	// }
			// 	// //&.user-position:after,
			// 	// &:hover:after{
			// 	// 	width: calc(100% - 45px);
			// 	// }
			// 	&.active{
			// 		color: $color-blue-dark;
			// 		// background-color: $color-blue-dark;
			// 		&:before{
			// 			// transform: rotate(270deg);
			// 		}
			// 	}
			// }
			&:not(.search):after{
				content: "";
				position: absolute;
				height: 2px;
				background-color: $color-blue-dark;
				display: block;
				bottom: 8px;
				width: 0;
				transition: all .3s;
			}
			&.active:after,
			&.user-position:after{
				width: calc(100% - 30px);
				background-color: $color-sea-green-dark;
				transition: background-color .3s;
			}
			//&.user-position:after,
			&:hover:after,
			&:focus:after,
			&.user-position:after{
				width: calc(100% - 30px);
			}
			&.search{
				display: block;
				width: 40px;
				height: 40px;
				
				background: url(../assets/images/search-black.svg) center center no-repeat;
	
				background-size: 20px 20px;
				@media screen and (min-width: 1150px){
					background-size: 22px 22px;
				}
				&.active,
				&:hover{
					background-image: url(../assets/images/search-sea-green.svg);				
					
				}
			}
		}
	}
}

ul.desktop-main-nav-targets{
	// position: absolute;
	// left: 0;
	background-color: #fff;
	z-index: 1;
    width: 100%;
    margin: 0;
    // box-shadow: 0px 7px 10px -8px rgba(0,0,0,0.75);
	
	> li{

		// padding: $spacing-medium + $spacing-small;
		padding: $spacing-large $spacing-large*3+10px $spacing-large*2+20px;
		display: none;
		list-style: none;
		position: relative;
		&.active{
			// display: block;
			
		}
		a:not(.desktop-man-nav-close){
			text-decoration: none;
			color: $color-black;
			line-height: 1.5;
			font-size: 16px;
			&:hover,
			&:focus,
			&.user-position{
				color: $color-sea-green-dark;
				border-bottom: 1px solid $color-sea-green-dark;
				padding-bottom: 1px;
			}
		}
		ul{
			margin: 0;
			> li{
				margin-bottom: $spacing-small*2;
				list-style: none;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}

}

.desktop-man-nav-close{
	position: absolute;
    padding: $spacing-medium;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    @include cf();
    @extend .icon-before;
    @extend .icon-close;
    text-decoration: none;
    
    &:hover,
    &:focus{
    	border-bottom: none !important;
    	padding: $spacing-medium;
    	text-decoration: underline;
    }
    &:before{
    	float: right;
    	margin-left: $spacing-small;
    	background-size: 15px;
    	content: "";
    	height: 22px;
		width: 22px;
    }

}

// .desktop-main-nav-targets-topic-link{
// 	margin-bottom: $spacing-medium;
//     padding-bottom: $spacing-medium;
//     border-bottom: 1px solid $color-blue-green;
//     // margin-top: $spacing-medium;
//     display: block;
// }

@media print {
	.desktop-main-nav-container{
		display: none;
	}
}

.innsiden{
	ul.desktop-main-nav-triggers{
		margin-left: 240px;
	}
}

/*************
** Mobile view only **
**************/
.mobile-view-only{
	.desktop-main-nav-container{
		display: none !important;
	}
}