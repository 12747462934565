.print-panel {
  position: relative;

  &-toggle {
    position: relative;
    padding-right: 35px;
    display: inline-block;
    z-index: 9;

    &:after {
      content: "";
      background: url(../assets/images/arrow-dark-black.svg) center right
        no-repeat;
      background-size: 8px;
      display: block;
      height: 10px;
      width: 10px;
      top: 50%;
      position: absolute;
      right: 15px;
      transform: translateY(-50%) rotate(90deg);
      transition: all 0.3s ease-in-out;
    }

    &_active:after {
      transform: translateY(-50%) rotate(-90deg);
    }
  }

  &-menu {
    background: #fff;
    display: none;
    overflow: hidden;
    z-index: 9;

    padding: 20px;
    background: $color-blue-light-2;
    width: 100%;

    @media #{$medium-big-up} {
      position: absolute;
      width: 350px;
      top: 100%;
      left: 0;
      padding: 20px;
    }
  }

  &-items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-item {
    display: block;
    clear: both;
  }

  &-item-link {
    display: block;
    clear: both;
  }
}
.print-preview-loading {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(#fff, 0.9);
  text-align: center;
  transition: opacity 1s ease;
  opacity: 1;

  .print-preview-is-loaded & {
    display: none;
  }

  @media print {
    display: none;
  }
}
