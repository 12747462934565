.search-results-list {
  list-style: none;
  margin-left: 0;
  margin-bottom: 80px;
  @media #{$small-only} {
    margin-bottom: 54px;
  }
  .meta {
  }
  > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    text-decoration-line: none;
  }
  em {
    background-color: $color-orange-100;
    font-style: normal;
    padding-left: 3px;
    padding-right: 3px;
  }
  .search-result-rules-outdated {
    background-color: orange;
    padding: 10px;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
  }
}

.search-result {
	color: #323232;
	margin-bottom: 64px;
	position: relative;

	@media #{$small-only} {
		margin-bottom: 44px;
	}

	>*:last-child {
		margin-bottom: 0 !important;
	}

	p {
		margin: 0;
	}

	.body-text {
		color: inherit;
	}

	&__category {
		margin-block-end: 22px;
		display: flex;
		align-items: center;
		padding: 10px;
		width: 100%;
		background-color: #f4f4f4;
		min-height: 64px;

		&--blue {
			background-color: $color-sea-blue-50;
		}

		&--green {
			background-color: $color-green-50;
		}

		svg {
			width: 40px;
			min-width: 40px;
			height: 40px;
			display: block;
		}

		span {
			font-size: 16px;
			line-height: 22px;
			padding-left: 11px;

			@media #{$small-only} {
				font-size: 14px;
			}

			strong,
			b {
				display: block;
				font-size: inherit;
				line-height: inherit;
			}
		}
	}

	&__link {
		display: block;
		margin-block-end: 13px;
		width: fit-content;
		font-size: 24px;
		line-height: 36px;

		em {
			background-color: $color-orange-100;
			display: inline-block;
			vertical-align: middle;
		}
	}

	&__info,
	.meta {
		color: #323232;
		margin-block-end: 22px;
		font-size: 16px;
		line-height: 22px;
	}

	&__link {
		color: #323232;
		display: block;
		margin-bottom: 20px;
	}
}