.accordion-container {
  overflow: hidden;
  @media #{$large-up} {
    min-height: 500px;
  }

  &.sticky {
    @media #{$medium-up} {
      position: fixed;
      top: 0;
      z-index: 1;
    }
  }

  .scrollable {
    overflow-y: auto;
  }

  a,
  p,
  span {
    font-size: 16px;
  }
}

.sidebar-search-container {
  background: $color-gray-warm-3;
  padding: $spacing-large;
  margin: 0;
  margin-bottom: $spacing-large;
}

.sidebar-non-accordion {
  background: $color-gray-warm-3;
  padding: $spacing-medium + $spacing-small $global-padding;
  padding-right: $spacing-medium + $spacing-small;

  &.border-bottom {
    border-bottom: 1px solid $color-gray-warm;
    padding-bottom: $spacing-medium;
    padding-top: $spacing-medium;
  }
  h2 {
    font-weight: 300;
    font-size: 20px;
  }

  a {
    text-decoration: none;
    font-weight: 400;

    &.active {
      font-weight: normal;
      color: $color-black;
    }
  }
}

@media print {
  .rules-tabs-nav {
    display: none;

    &.active {
      display: none;
    }
  }
}

.sidebar-search-form {
  position: relative;
  margin: 0;
  .sidebar-search-input {
    padding-right: 80px;
  }
  .sidebar-search-button {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
  }
}
