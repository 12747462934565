.calendar-row{
    @include cf();
        
    .calendar-row-year-container{
        margin-bottom: $spacing-xxlarge;
    }

    .calendar-row-content{
        @include cf();
        padding-top: $spacing-large;
        padding-bottom: $spacing-large;
        border-bottom: 1px solid $color-gray-warm; 
    }

    .calendar-row-date{
        color: $color-black;
        text-align: center;

        p{
             margin: 0px;
        }

        .desktop{
            display: none;
        }
        .mobile{
            display: block;
            font-size: 16px;
        }

        @media #{$medium-up} {
            .desktop{
                display: block;   
            }

            .mobile{
                display: none;
            }
        }
    }

    .calendar-row-month{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .calendar-row-text{
        text-align: left;

        p{
            color: $color-black;
            margin: 0px;
            font-size: 19PX;
        }
    }
}