.notice-icon{
	width: 1.2em;
	height: 1.2em;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.25em;

	&-circle{
		fill: #000;
	}

	&-error &-circle{
		fill: $color-error;
	}
}