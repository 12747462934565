ul.list-clean {
  margin: 0;
  padding: 0;
  margin-bottom: $spacing-medium;

  > li {
    list-style: none;
  }
}

.sidebar-accordion-navigation {
  ul.list-clean {
    margin-left: $spacing-medium;
    li {
      margin-bottom: 10px;

      @media print {
        margin-bottom: 5px;
      }

      a.link {
        display: block;

        &.active {
          cursor: default;
          text-decoration: none;

          .list-link-title,
          .list-link-content {
            text-decoration: none;
          }
        }
        .list-link-title {
          text-decoration: underline;
        }

        .list-link-content {
          display: block;
          text-decoration: underline;
        }

        &:hover {
          .list-link-title,
          .list-link-content {
            text-decoration: none;
          }
        }
      }
    }
  }
}
