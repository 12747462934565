.news-frontpage {
	margin-block-end: 35px;

	@media #{$medium-up} {
		margin-block-end: 36px;
	}

	&__wrapper {
		display: grid;
		gap: 45px;
		background-color: $color-green-50;
		padding: 25px 15px;

		@media #{$medium-up} {
			padding: 50px 40px;
			margin-left: -40px;
			width: calc(100% + 80px);
			gap: 60px;
		}

		@media #{$large-up} {
			gap: 83px;
		}
	}

	&__top {
		@media #{$large-up} {
			display: flex;
		}

		&:not(:has(.news-frontpage__top-right)) .news-frontpage__top-left {
			width: 100%;
			padding-right: 0;
		}

		&-left,
		&-right {
			.title-top {
				display: block;
				margin-bottom: 24px;

				@media #{$medium-up} {
					margin-bottom: 48px;
				}
				
				a {
					font-size: 28px;
					line-height: 42px;
					font-weight: 600;
					text-decoration: none;
					color: $color-sea-blue-900;

					@media #{$medium-up} {
						font-size: 32px;
						line-height: 44px;
					}

					&:hover,
					&:focus-visible {
						text-decoration: underline;
					}

					span {
						display: inline-block;
						vertical-align: middle;
						width: 20px;
						min-width: 20px;
						height: 20px;
						margin-left: 8px;
						transform: translateY(-2px);
						background: transparent url(../assets/images/angle-right.svg) center center / contain no-repeat;
					}
				}
			}
		}

		&-left {
			width: 100%;
			margin-bottom: 50px;

			@media #{$large-up} {
				width: calc(100% / 3 * 2);
				padding-right: 42px;
				margin-bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.list-news {
				display: grid;
				gap: 24px;
				margin: 0;
				padding: 0;
				list-style: none;

				@media #{$medium-up} {
					grid-template-columns: 1fr 1fr;
					gap: 32px 20px;
				}

				@media #{$large-up} {
					gap: 32px 42px;
				}

				li {
					display: block;
					margin: 0;
					padding: 0;

					&::marker {
						content: none;
					}

					a {
						text-decoration: none;

						&:hover,
						&:focus-visible {
							text-decoration: underline;
						}

						.title {
							display: block;
							color: $color-sea-blue-900;
							font-weight: 600;
							font-size: 18px;
							line-height: 27px;
							margin-bottom: 1px;
							text-wrap-style: pretty;

							@media #{$medium-up} {
								font-size: 24px;
								line-height: 36px;
								margin-bottom: 8px;
							}

							span {
								display: inline-block;
								vertical-align: middle;
								width: 20px;
								min-width: 20px;
								height: 20px;
								margin-left: 1px;
								transform: translateY(-1px);
								background: transparent url(../assets/images/angle-right.svg) center center / contain no-repeat;
							}
						}

						.desc {
							color: $color-sea-blue-900;
						}
					}
				}
			}

			&-newsletter {
				display: none;
		
				@media #{$large-up} {
					display: block;
					margin-top: 83px;
				}
		
				.newsletter-wrapper {
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					gap: 42px;
					padding: 27px;
					margin-left: -20px;
					width: calc(100% + 20px);
					box-shadow: inset 0 0 0 3px #5BCC35;
		
					&__left {
						width: 17rem;
		
						@media (min-width: 1200px) {
							width: 23rem;
						}
		
						.title {
							display: block;
							color: $color-sea-blue-900;
							margin: 0 0 8px 0;
							font-size: 24px;
							line-height: 36px;
							font-weight: 600;
							margin-bottom: 8px;
						}
		
						.desc {
							margin: 0;
							color: $color-sea-blue-900;
						}
					}
		
					&__right {
						a {
							display: block;
							width: fit-content;
							background-color: $color-sea-blue-900;
							color: $color-white;
							text-decoration: none;
							padding: 12px 20px;
							transition: .2s ease-in-out;
		
							&:hover,
							&:focus-visible {
								background-color: $color-sky-blue-700;
							}
		
							img,
							svg {
								display: inline-block;
								vertical-align: middle;
								margin-left: 8px;
							}
						}
					}
				}
			}
		}

		&-right {
			width: 100%;

			@media #{$large-up} {
				width: calc(100% / 3);
			}

			.list-calendar {
				margin: 0;
				padding: 0;
				display: grid;
				gap: 16px;
				list-style: none;

				li {
					padding: 0;
					margin: 0;

					&::marker {
						content: none;
					}

					a {
						display: grid;
						grid-template-columns: 90px 1fr;
						align-items: center;
						gap: 15px;
						text-decoration: none;

						&:hover,
						&:focus-visible {
							.title {
								text-decoration: underline;
							}
						}

						.date {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							align-self: flex-start;
							background-color: $color-green-100;
							padding: 10px 0;
							text-align: center;
							font-size: 20px;
							line-height: 28px;
							font-weight: 600;
							color: $color-sea-blue-900;
						}

						.title {
							font-size: 20px;
							line-height: 32px;
							font-weight: 600;
							color: $color-sea-blue-900;
							padding-right: 10px;
							hyphens: auto;

							span {
								display: inline-block;
								vertical-align: middle;
								width: 20px;
								min-width: 20px;
								height: 20px;
								margin-left: 1px;
								transform: translateY(-1px);
								background: transparent url(../assets/images/angle-right.svg) center center / contain no-repeat;
							}
						}
					}
				}
			}
		}
	}

	&__bottom {
		width: 100%;

		@media #{$large-up} {
			display: none;
		}

		.newsletter-wrapper {
			display: grid;
			gap: 28px;
			width: 100%;
			box-shadow: inset 0 0 0 3px #5BCC35;
			padding: 16px 13px;

			@media #{$medium-big-up} {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				gap: 42px;
				padding: 27px;
			}

			@media #{$large-up} {
				margin-left: -20px;
			}

			&__left {
				@media #{$large-up} {
					width: 17rem;
				}

				@media (min-width: 1200px) {
					width: 23rem;
				}

				.title {
					display: block;
					font-size: 18px;
					line-height: 27px;
					font-weight: 600;
					color: $color-sea-blue-900;
					margin: 0 0 17px 0;

					@media #{$medium-up} {
						font-size: 24px;
						line-height: 36px;
						font-weight: 600;
						margin-bottom: 8px;
					}
				}

				.desc {
					margin: 0;
					color: $color-sea-blue-900;
				}
			}

			&__right {
				a {
					display: block;
					width: fit-content;
					background-color: $color-sea-blue-900;
					color: $color-white;
					text-decoration: none;
					padding: 12px 20px;
					transition: .2s ease-in-out;

					&:hover,
					&:focus-visible {
						background-color: $color-sky-blue-700;
					}

					img,
					svg {
						display: inline-block;
						vertical-align: middle;
						margin-left: 8px;
					}
				}
			}
		}
	}
}