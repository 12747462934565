.hearings-input-list{
	margin: $spacing-small 0 $spacing-large;

	table{
		width: 100%;
		margin: 0px;
	}

	th,
	td{
		padding: $spacing-medium 0 $spacing-medium $spacing-medium;
		border: none;
		font-style: normal;
		vertical-align: top;
	}

	th{
		background: #ecf2f6;
	
		&:last-child{
			text-align: right;
			padding-right: $spacing-medium;

			@media #{$medium-big-up} {
				text-align: left;
			}
		}
	}

	td{
		border-bottom: 1px solid #d7d4d1;

		&:first-child{
			width: 80px;
			white-space: nowrap;
		}

		&:last-child{
			text-align: right;
			padding-right: $spacing-medium;
			
			@media #{$medium-big-up} {
				width: 120px;
				white-space: nowrap;
				text-align: left;
			}
		}
	}

	&-wrapper{
		width: 100%;
		overflow-y: auto; 
		margin: 0 0 $spacing-large;
	}

	.hearings-input-list-modal-link{
		display: inline-block;
		text-decoration: underline;
		margin: 0 0 0 $spacing-medium;
		background: none;
		padding: 0;
		color: $color-sea-green;
		font-size: 16px;
		font-weight: 300;


		@media #{$medium-big-up} {
			margin: 0 0 0 $spacing-large;
		}

		&:hover{
			text-decoration: none;
		}
	}
}