.transport {
  &-content-area {
    width: 100%;
    column-count: 1;
    column-span: none;
    column-fill: balance;
    column-gap: $spacing-xlarge + $spacing-medium;

    @media #{$medium-up} {
      column-count: 2;
    }

    @media #{$large-up} {
      column-count: 3;
    }
  }

  &-block {
    break-inside: avoid;
    page-break-inside: avoid;
    overflow-wrap: break-word;

    .h2 {
      margin: 0;
    }
  }
}
