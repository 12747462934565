.search-local-container {
  @include cf();
  font-family: 'PPMori', sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 7px 20px 24px;
  background: $color-gray-warm-3;
  border-bottom: 1px solid $color-gray-warm;

  @include placeholder {
    font-size: $font-size-rules;
  }
}
