.small {
  width: 100%;

  @media #{$medium-up} {
    float: left;
    width: 50%;
  }

  @media #{$large-up} {
    float: left;
    width: 25%;
  }
}

.medium {
  width: 100%;

  @media #{$medium-up} {
    float: left;
    width: 50%;
  }
}

.large {
  width: 100%;
}
