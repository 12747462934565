.boxed-image-link{
	@include cf();
	
	width: 100%;
	text-align: center;

	@media #{$medium-up} {
		width: 50%;
		float: left;
		text-align: left;
	}

	&.large{
		width: 100%;
	}

	&.medium{
		@media #{$medium-up} {
			width: 50%;
			float: left;

			.boxed-image-link-content{
				padding: 30px;
				max-width: 100%;
				height: auto;
			}

			.block-header{
				font-family: $body-font-family;
				font-weight: 200;
				font-size: 24px;
				font-weight: normal;
			}
		}
	}

	&.small{
		width: 100%;

		@media #{$medium-up} {
			width: 50%;
			float: left;
		}	
		@media #{$large-up} {
			width: 25%;
			float: left;
		}
	}

	&-link{
		position: relative;
		display: inline-block;

		&[href=''] {
			pointer-events: none;
		}
		
		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.0);
			transition: background-color .2s;
		}
		&:hover,
		&:focus{
			&:before{
				background-color: rgba(0, 0, 0, 0.1);   
				transition: background-color .2s;   
			}
			
		}
	}
}
.boxed-image-link-image{
	display: block;
	max-width: 100%;
	height: auto !important;
	margin: 0;
}