.boxed-warning {
  @extend .block-padding;
  background-color: $color-orange-50;
  font-size: 19px;

  &.small {
    @media #{$medium-up} {
      width: 50%;
      float: left;
    }
    @media #{$large-up} {
      width: 25%;
      float: left;
    }
  }
  .block-header{
    @extend .body-text;
  }
}

.boxed-warning-title {
  margin-bottom: $spacing-medium;
  margin-top: $spacing-medium;
}

.boxed-warning-icon {
  &:before {
    content: "";

    display: block;
    width: 30px;
    height: 30px;

    display: inline-block;
    margin-right: $spacing-medium;
    vertical-align: bottom;
  }
}

.boxed-warning-text {
  font-size: 19px;
  color: $color-black;
  margin-bottom: 0;
}
