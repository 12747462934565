.renovation {
	max-width: $renovation-width--content-startpage;

	&-container {
		margin-bottom: $spacing-xlarge;

		ol:not([class]),
		ul:not([class]) {
			margin-left: 0;
			padding-left: $spacing-xlarge;
		}

		figure {
			margin: $spacing-large 0 $spacing-medium;
		}

		figcaption {
			margin-top: $spacing-small;
			margin-bottom: $spacing-medium;
			font-size: $font-size-small;
			font-weight: lighter;
			font-style: italic;
		}
	}

	&-top {
		margin: -$spacing-small;
		margin-bottom: 0;

		@media #{$medium-up} {
			margin: -$spacing-large;
			margin-bottom: 0;
		}

		img {
			width: 100%;
		}

		em {
			padding-top: $spacing-medium;
			padding-left: $spacing-large + $spacing-medium;
			font-size: $font-size-mini;
			font-weight: lighter;
		}

		&-image {
			position: relative;
			height: 250px;
			overflow: hidden;

			> img {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				min-height: 100%;
				min-width: 100%;
				object-fit: cover;
			}

			@media #{$medium-up} {
				height: 400px;
			}

			@media #{$large-up} {
				height: 750px;
			}
		}
	}

	h2,
	h3,
	h4 {
		margin: $spacing-large + $spacing-medium 0 $spacing-large;
	}

	h1,
	.h1 {
	font-size: 26px;

	@media #{$large-up} {
		font-size: 35px;
	}
	}

	h2,
	.h2 {
		font-size: 22px;

		@media #{$large-up} {
			font-size: 26px;
		}
	}
}