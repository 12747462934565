.hearings-subsection{
	display: table-row;

	*{
		line-height: 1.6;
	}

	.section-big{
		@include cf();
	}

	.accordion-secondary{
		margin-top: 20px;
	}


	&-column{
		display: table-cell;
		vertical-align: top;
		
		&:first-child{
			white-space: nowrap;

			.hearings-subsection-list-marker{
				float: none;
				min-width: 25px;
				margin-right: 5px;
			}
		}

		div>*:last-child:not(.hearings-child-modal-link){
			margin-bottom: 0 !important;

			li:last-child{
				margin-bottom: 0 !important;

				>*:last-child{
					margin-bottom: 0 !important;
				}
			}
		}
	}

	&-list-marker{
		float: left;
		margin-right: 0.5em;

	}
	ul,ol{
		clear: both;
	}
}


.hearings-unit-body{
	margin-bottom: 0 !important;
	>*:last-child{
		margin-bottom: 0 !important;
		>*:last-child{
			margin-bottom: 0 !important;

			>*:last-child{
				margin-bottom: 0 !important;

				>*:last-child{
					margin-bottom: 0 !important;
				}	
			}	
		}
	}
}
