/**************
## Accordion ##
**************/
.accordion-container {
  margin-left: -$spacing-large;
  margin-right: -$spacing-large;
  position: relative;
  @media #{$medium-up} {
    margin-left: 0;
    margin-right: 0;
  }
}

.accordion {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $color-gray-warm-2;

  @media print {
    border-bottom: none;
  }

  &.accordion-secondary {
    border-bottom: none;
    margin-bottom: $spacing-medium;
  }
}

.accordion-static-header {
  background: $color-gray-warm-3;
  padding: $spacing-medium + $spacing-small $global-padding;
  list-style: none;
  border-top: 3px solid $color-white;

  &.no-border {
    border-top: none;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;

    @media #{$large-up} {
      font-size: 18px;
    }
  }

  @media print {
    &,
    p {
      font-size: 20px;
    }
  }
}

.accordion-navigation {
  display: block;
  margin-bottom: 0 !important;
  border-top: 1px solid $color-warm-300;

  > .paragraph {
    margin: $spacing-small + $spacing-medium;
  }

  .accordion-secondary & {
    border-top: none;
  }

  .accordion-primary & {
    @media print {
      border-top: none;
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 20px;
        width: calc(100% - 40px);
        content: " ";
        border-top: 1px solid $color-gray-warm-2;
        display: block;
      }
    }
  }

  .list-clean {
    font-weight: 400;
  }
}

.accordion-navigation {
  > a {
    display: block;
    position: relative;
    color: $color-text-gray;
    text-decoration: none;
    padding: $spacing-medium $global-padding;
    padding-right: 50px;
    font-weight: 400;

    @media print {
      border: none !important;

      .table-of-content & {
        font-size: 14px;
        font-weight: bold;
      }
    }

    &:hover {
      .accordion-secondary & {
        color: $color-blue-dark;

        @media #{$small-only} {
          color: $color-black;
        }
      }
      .accordion-primary & {
        background-color: $color-warm-25
      }
    }

    .accordion-primary & {
      background-color: $color-gray-warm-3;
    }
    .accordion-secondary & {
      position: relative;
      width: max-content;
      border-bottom: 2px solid $color-blue-dark;
      padding: 0 30px 0 $spacing-medium;
      white-space: nowrap;
      margin-left: auto;

      @media print {
        margin-left: 0;
      }
    }
    .accordion-secondary.alternate & {
      border-bottom: 2px solid $color-lime-green-light;
    }

    .accordion-tertiary & {
      color: $color-black;
      padding: $spacing-medium 0;
      background-color: transparent;
      @include h2();
      font-size: 20px;
    }

    &:after {
      content: "";
      background: url(../assets/images/arrow-dark-black.svg) center right
        no-repeat;
      background-size: 8px;
      display: block;
      height: 10px;
      width: 10px;
      top: 50%;
      position: absolute;
      right: 15px;
      transform: translateY(-50%) rotate(90deg);
      transition: all 0.2s;
      .accordion-secondary &,
      .accordion-tertiary & {
        right: 3px;
      }
      .accordion-primary & {
        top: 18px;
        transform: translateY(0) rotate(90deg);
      }
      .accordion-secondary & {
        right: 6px;
      }

      .table-of-content & {
        display: none;
      }

      @media print {
        display: none;
      }
    }
  }

  &.single-click-accordion {
    > a:after {
      transform: none;
    }
  }
  &.active {
    > a {
      .accordion-primary & {
        font-weight: 700;
        background-color: transparent;
        background-color: $color-warm-25;
        border-bottom: 1px solid $color-warm-25;

        &:before {
          content: "";
          position: absolute;
          left: $global-padding;
          bottom: 0;
          height: 1px;
          width: 88%;
        }
      }
      .accordion-secondary & {
        border-bottom: none;
      }
    }
    > a:after {
      transform: translateY(0) rotate(270deg);

      .accordion-secondary & {
        transform: translateY(-50%) rotate(270deg);
      }
    }

    > .accordion-navigation-arrow {
      transform: rotate(270deg);
    }
  }
}

.table-of-content .accordion .accordion-navigation > .content,
.accordion .accordion-navigation > .content.active {
  display: block;
}
.accordion .accordion-navigation > .content {
  display: none;
  padding: 10px 30px;
  margin: 0;

  @media print {
    display: block;
    padding-top: 0;
    clear: both;
  }
}

.accordion.accordion-primary .accordion-navigation > .content {
  background-color: $color-warm-25;
}

.accordion.accordion-secondary .accordion-navigation > .content {
  padding: $spacing-large;
  padding-bottom: $spacing-medium;
  background-color: $color-blue-light-2;

  @media print {
    background: none;
    clear: both;
    border: 1px solid $color-blue-light-2;
  }
}

.accordion.accordion-secondary.alternate .accordion-navigation > .content {
  background-color: $color-lime-green-light;
}

.accordion.accordion-tertiary .accordion-navigation > .content {
  margin-left: $spacing-medium;
  padding: 0;
  padding-top: $spacing-medium;
}

.link-accordion {
  @include h2();
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

// Trigger animation right to left
.accordion-secondary .accordion-navigation {
  > a {
    .accordion-title {
      font-family: $header-font-family;
      font-weight: 400;
      color: $color-blue-dark;
      line-height: 35px;
      transition: all 0.2s;

      @media print {
        text-align: left;
      }
    }
  }

  &.active {
    > a {
      .accordion-title {
        font-style: normal;
        // font-weight: 500;
      }
    }
  }
}
